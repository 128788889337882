import * as React from 'react';
import * as dayjs from 'dayjs';
import * as isTomorrow from 'dayjs/plugin/isTomorrow';
import * as isToday from 'dayjs/plugin/isToday';
import { convertUnixToDate } from '@lean-body/src/util';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { css, StyleSheet } from 'aphrodite';
import { LiveLessonAttendanceButton, LiveLessonCardHeader, LiveLessonThumbnail } from '@lean-body/components/molecules';
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
export var LiveLessonCard = function (_a) {
    var liveLesson = _a.liveLesson, tracker = _a.tracker, classObject = _a.classObject, onClickAttendance = _a.onClickAttendance;
    var _b = React.useState(liveLesson.isSubscribed), isAttendance = _b[0], setIsAttendance = _b[1];
    var isSM = useSmMediaQuery();
    var liveLessonLinkPath = function (live) {
        if (live.isWatchable) {
            return routes.LIVE_LESSON.compile({ id: live.id.toString() });
        }
    };
    var onClick = function (id) {
        onClickAttendance(id, isAttendance);
        setIsAttendance(!isAttendance);
    };
    var onClickThumbnail = function (id) {
        tracker.trackClickEvent({
            category: 'Clicked live lesson thumbnail',
            label: "LiveLessonID: ".concat(id),
        });
    };
    var holdingDay = function (startDate) {
        if (dayjs(startDate).isToday()) {
            return '今日';
        }
        if (dayjs(startDate).isTomorrow()) {
            return '明日';
        }
        return dayjs(startDate).format('M月D日 dddd');
    };
    var holdingDate = function () {
        var startDate = convertUnixToDate(liveLesson.startsAt);
        var endDate = convertUnixToDate(liveLesson.lessonEndAt);
        var day = holdingDay(startDate);
        return "".concat(day, " ").concat(dayjs(startDate).format('H:mm'), "~").concat(dayjs(endDate).format('H:mm'));
    };
    return (React.createElement(React.Fragment, null, liveLesson && (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(LiveLessonCardHeader, { label: liveLesson.label, holdingTime: holdingDate(), classObject: styles.cardHeader }),
        React.createElement(Link, { to: liveLessonLinkPath(liveLesson), className: css(styles.container), onClick: function () { return onClickThumbnail(liveLesson.id); } },
            React.createElement(LiveLessonThumbnail, { src: isSM ? liveLesson.thumbnailSpURL : liveLesson.thumbnailPcURL, isWatchable: liveLesson.isWatchable })),
        !liveLesson.isArchived && (React.createElement(LiveLessonAttendanceButton, { isAttendance: isAttendance, onClick: function () { return onClick(liveLesson.id); }, classObject: styles.liveLessonAttendanceButton }))))));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    cardHeader: {
        marginBottom: 12,
    },
    liveLessonAttendanceButton: {
        position: 'absolute',
        bottom: 10,
        right: 10,
    },
});
