import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { CircleBackslashIcon } from '../atoms/icons/circle_backslash_icon';
import { ImageWithFallback } from '../atoms/image_with_fallback';
export var MealMenuInfo = function (_a) {
    var menu = _a.menu, quantity = _a.quantity, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.imageContainer) },
            React.createElement(ImageWithFallback, { src: menu.imageUrl, classObject: styles.image, lazy: true, fallbackElement: React.createElement(CircleBackslashIcon, { classObject: [styles.image, styles.mealMenuImageNone] }) })),
        React.createElement("div", { className: css(styles.detailsContainer) },
            React.createElement("div", { className: css(styles.storeName) }, menu.storeName),
            React.createElement("div", { className: css(styles.name) }, menu.menuName),
            quantity ? (React.createElement("div", { className: css(styles.calorieAndQuantity) },
                React.createElement("span", { className: css(styles.calorie, styles.calorieWithQuantity) },
                    menu.caloriesForQuantity(quantity),
                    "kcal"),
                React.createElement("div", { className: css(styles.divider) }, "|"),
                React.createElement("span", { className: css(styles.quantity) },
                    quantity,
                    "%"))) : (React.createElement("div", { className: css(styles.calorie) },
                menu.calorie,
                "kcal")))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    imageContainer: {
        width: 60,
        height: 60,
        borderRadius: 8,
        border: "1px solid ".concat(Colors.BorderLightGray),
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: 5,
    },
    mealMenuImageNone: {
        backgroundColor: Colors.CyanLightest,
        padding: 14,
    },
    detailsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    storeName: {
        fontWeight: 400,
        color: Colors.GrayDarkest,
    },
    name: {
        fontWeight: 700,
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
    },
    calorie: {
        fontWeight: 500,
        color: Colors.GrayDarkest,
    },
    calorieWithQuantity: {
        color: Colors.PrimaryDarkB,
    },
    divider: {
        margin: '0 6px',
        color: Colors.BorderLightGray,
        fontWeight: 500,
    },
    calorieAndQuantity: {
        display: 'flex',
    },
    quantity: {
        color: Colors.GrayDarkest,
    },
});
