var _a, _b;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { MediaBreakPointUp, Colors } from '../styles';
import { CautionIcon } from '../atoms';
export var UnsubscribeWarning = function (props) {
    var className = props.className, payment = props.payment;
    var containerClass = [className || '', css(styles.container)].join(' ');
    return (React.createElement("div", { className: containerClass },
        React.createElement(CautionIcon, { classObject: styles.icon }),
        React.createElement("div", { className: css(styles.description) },
            "\u4ECA\u9000\u4F1A\u3092\u3055\u308C\u3066\u3082\u3001",
            payment.curPeriodEndDayjs.format('YYYY年MM月DD日'),
            "\u307E\u3067\u306FLEAN BODY\u306E\u5168\u3066\u306E\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002",
            React.createElement("br", null),
            payment.isFreeTrialUser() ? '請求開始日' : '更新日',
            "\u4EE5\u964D\u306F\u3001\u30ED\u30B0\u30A4\u30F3\u304C\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u518D\u958B\u3057\u305F\u3044\u5834\u5408\u306F\u3001\u540C\u3058\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3067\u518D\u5EA6\u30D7\u30E9\u30F3\u3092\u767B\u9332\u3059\u308B\u3068\u30EC\u30C3\u30B9\u30F3\u6642\u9593\u3084\u8A18\u9332\u306A\u3069\u3092\u7D99\u7D9A\u5229\u7528\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"),
        React.createElement("div", { className: css(styles.chatbotLocation) }, "\u9000\u4F1A\u624B\u7D9A\u304D\u306F\u300C\u9000\u4F1A\u306E\u304A\u624B\u7D9A\u304D\u306F\u3053\u3061\u3089\u304B\u3089\u300D\u306E\u30DC\u30BF\u30F3\u304B\u3089\u884C\u3048\u307E\u3059\u3002")));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.XL] = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        _a),
    icon: (_b = {
            width: 37,
            height: 35,
            margin: '0 auto 20px',
            display: 'block'
        },
        _b[MediaBreakPointUp.XL] = {
            marginBottom: 20,
        },
        _b),
    description: {
        whiteSpace: 'pre-line',
        color: Colors.Black,
        fontSize: 14,
        lineHeight: '19px',
        letterSpacing: '0.04em',
    },
    chatbotLocation: {
        color: Colors.Black,
        fontSize: 14,
        lineHeight: '19px',
        letterSpacing: '0.04em',
        marginTop: 20,
        width: '100%',
    },
});
