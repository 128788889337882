var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
import { SettingItemKeys } from './setting_menu';
export var PlanChangeSubsection = function (_a) {
    var classObject = _a.classObject, me = _a.me, changePlan = _a.changePlan, apiClient = _a.apiClient, accountService = _a.accountService, tracker = _a.tracker, alertPresenter = _a.alertPresenter, isUnsubscribeCpPlan = _a.isUnsubscribeCpPlan;
    var payment = me.payment;
    var currentPlan = payment.currentPlan;
    var _b = React.useState(false), submitting = _b[0], setSubmitting = _b[1];
    var navigate = useNavigate();
    var planPrefix = changePlan.isCampaignPlan()
        ? '特別'
        : changePlan.isAnnualPlan()
            ? '年間'
            : changePlan.isStandardPlan()
                ? '月額'
                : null;
    var toPerUnit = function (p) {
        if (p.isStandardPlan())
            return ' / 月';
        if (p.isAnnualPlan())
            return ' / 年';
        return null;
    };
    var onSubmit = function () {
        setSubmitting(true);
        // 退会オファーのCPプランに変更する場合、別のエンドポイントを叩く
        var planChangeRequest = isUnsubscribeCpPlan
            ? apiClient.changePlanForUnsubscribeCp()
            : apiClient.changePlan({ planId: changePlan.stripePlanId });
        planChangeRequest
            .then(function () {
            accountService.reloadMe();
            tracker.trackClickPlanChange(changePlan, me);
            alertPresenter.showMessage('プラン変更に成功しました');
            navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
        })
            .catch(function (e) {
            alertPresenter.showMessage('プラン変更に失敗しました');
            throw e;
        })
            .finally(function () {
            setSubmitting(false);
        });
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.head) }, "\u30D7\u30E9\u30F3\u5207\u308A\u66FF\u3048"),
        React.createElement("div", { className: css(styles.title) },
            planPrefix,
            "\u30D7\u30E9\u30F3\u3078\u306E\u5207\u308A\u66FF\u3048"),
        React.createElement("div", { className: css(styles.explanation) },
            "\u6B21\u56DE\u306E\u30D7\u30E9\u30F3\u66F4\u65B0\u65E5\uFF08",
            payment.curPeriodEndDateInJapaneseFormat(),
            "\uFF09\u304B\u3089",
            planPrefix,
            "\u30D7\u30E9\u30F3\u306B\u5207\u308A\u66FF\u3048\u308B\u3053\u3068\u304C\u53EF\u80FD\u3067\u3059\u3002"),
        React.createElement("div", { className: css(styles.planLabel) }, "\u73FE\u5728\u306E\u30D7\u30E9\u30F3"),
        React.createElement("div", { className: css(styles.planAmount) },
            currentPlan.amount.toLocaleString(),
            "\u5186(\u7A0E\u8FBC)",
            toPerUnit(currentPlan)),
        React.createElement("div", { className: css(styles.planLabel) }, "\u3054\u63D0\u6848\u306E\u30D7\u30E9\u30F3\uFF1A"),
        React.createElement("div", { className: css(styles.planAmount) },
            changePlan.amount.toLocaleString(),
            "\u5186(\u7A0E\u8FBC)",
            toPerUnit(changePlan),
            changePlan.isCampaignPlan() && (React.createElement("div", { className: css(styles.perMonth) },
                changePlan.amountPerMonth.toLocaleString(),
                "\u5186 / \u6708\u76F8\u5F53"))),
        me.payment.isMegalosUser && (React.createElement("div", { className: css(styles.noticeText) },
            "\u203B\u3053\u3061\u3089\u306F\u901A\u5E38\u30D7\u30E9\u30F3",
            changePlan.isCampaignPlan() && 'のキャンペーン',
            "\u306B\u306A\u308A\u307E\u3059\u3002\u30E1\u30AC\u30ED\u30B9\u30D7\u30E9\u30F3\u306A\u3069\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u306F\u4ED8\u5E2F\u3057\u307E\u305B\u3093")),
        React.createElement(Button, { className: css(styles.button), buttonType: ButtonType.primary, onClick: onSubmit, loading: submitting, disabled: submitting, dataTest: "submitChangePlan" }, "\u30D7\u30E9\u30F3\u3092\u5207\u308A\u66FF\u3048\u308B")));
};
var styles = StyleSheet.create({
    container: {},
    head: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 24,
        textAlign: 'start',
    },
    title: {
        fontWeight: 'bold',
        marginBottom: 16,
    },
    explanation: {
        fontSize: 13,
        marginBottom: 14,
    },
    planLabel: {
        fontSize: 12,
        marginTop: 40,
    },
    planAmount: {
        fontSize: 14,
        fontWeight: 'bold',
        margin: '5px 0 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    perMonth: {
        padding: 10,
        color: Colors.Accent,
        backgroundColor: Colors.BackgroundLightGray,
        marginLeft: 8,
    },
    button: (_a = {
            width: '100%',
            marginTop: 40
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 240,
            margin: '40px auto 0',
        },
        _a),
    noticeText: {
        padding: 16,
        fontSize: 10,
        lineHeight: '15px',
        backgroundColor: Colors.BackgroundLightGray,
        marginTop: 12,
        letterSpacing: '0.05em',
    },
});
