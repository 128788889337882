var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import { FRIEND_INVITATION_CODES } from '../domain';
dayjs.extend(isToday);
var LOCAL_STORAGE_KEY = 'stats';
// const AUTH_TOKEN_KEY = 'authToken'
// const HIDE_APPSTORE = 'hideAppstore'
var LOGIN_STATE_KEY = 'loggedIn';
// const RELEASE_NOTIFICATION_IDS = 'releaseNotificationIds' // 過去に使っていて、命名が被るとバグが出るのでコメントアウトで残す
// const HALLOWEEN_LIVE_EVENT_DIALOG_2021 = 'halloweenLiveEventDialog2021'  // 過去に使っていて、命名が被るとバグが出るのでコメントアウトで残す
var HOME_DIALOG_ID = 'homeDialogId';
// const HOLDING_LIVE_LESSON_ID = 'holdingLiveLessonId' // 過去に使っていて、命名が被るとバグが出るのでコメントアウトで残す
// const SMART_HABIT_DIALOG = 'smartHabitDialog' // 過去に使っていて、命名が被るとバグが出るのでコメントアウトで残す
var NAVIGATION_TABS = 'navigationTabs';
var USER_SEASON_AGGREGATE_RANK = 'userSeasonAggregateRank';
var LOGGED_IN_PLATFORM = 'loggedInPlatform';
var BQ_SNS_SHARE_CLICK_PARAMS = 'BqSnsShareClickParams';
var TWITTER_REDIRECT_DATA = 'TwitterRedirectData';
var SEARCH_HISTORIES = 'SearchHistories';
var COURSE_INSTRUCTOR_CODE = 'courseInstructorCode';
export var SEARCH_HISTORIES_MAX_NUM = 10; // 検索履歴の最大保存数
export var CAMPAIGN_CODE_RESET = 'reset'; // LPからの遷移で、無料期間のプランを確実に反映できるように、localstorageにあるキャンペーンコードを削除する
var CAMPAIGN_CODE_RESET_2024_05_15 = 'reset_2024_05_15'; // 2024年5月15日価格改定対応のためのコード。CAMPAIGN_CODE_RESETと同様
var ANDROID_NOTIFICATION_PERMISSION_REQUEST_UI_DISMISSALS = 'android_notification_permission_request_ui_dismissals';
export var SignInType = {
    email: 'email',
};
export var LoggedInPlatForm = {
    facebook: 'facebook',
    apple: 'apple',
    line: 'line',
};
var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
        Object.defineProperty(this, "storage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: localStorage
        });
    }
    /*-----------------------------------*/
    Object.defineProperty(LocalStorage.prototype, "saveLoginState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (loggedIn) {
            this.storage.setItem(LOGIN_STATE_KEY, JSON.stringify(loggedIn));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadLoginState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.loadObjByKey(LOGIN_STATE_KEY);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "destroy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.storage.removeItem(LOCAL_STORAGE_KEY);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveStats", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (stats) {
            var saveData = Object.assign({}, this.loadStats(), __assign({}, stats));
            if (saveData) {
                this.storage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(saveData));
            }
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadStats", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.loadObjByKey(LOCAL_STORAGE_KEY);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveSignInType", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (type) {
            return this.saveStats({ signInType: type });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadSignInType", {
        get: function () {
            var _a;
            return (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.signInType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHideAppstore", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (hideAppstore) {
            this.saveStats({ hideAppstore: hideAppstore });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHideAppstore", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.hideAppstore) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHideFavoriteMessageDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (hide) {
            this.saveStats({ hideFavoriteMessageDialog: hide });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHideFavoriteMessageDialog", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.hideFavoriteMessageDialog) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveShowMealTaskNotificationPermissionDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (show) {
            this.saveStats({ showMealTaskNotificationPermissionDialog: show });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadShowMealTaskNotificationPermissionDialog", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.showMealTaskNotificationPermissionDialog) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHasRecordedMeal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            this.saveStats({ hasRecordedMeal: value });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHasRecordedMeal", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.hasRecordedMeal) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveInvitationCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (code) {
            // SNSログイン等でリダイレクトされた後にinvitationCodeが空に上書きされないようにcodeがある時だけ保存している
            // 「invitationCodeを保存しつつ、2週間無料プランで登録」という挙動をさせないために、loadInvitationCode()でハンドリングしています
            if (code) {
                this.saveStats({ invitationCode: code });
            }
        }
    });
    Object.defineProperty(LocalStorage.prototype, "removeInvitationCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.saveStats({ invitationCode: null });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadInvitationCode", {
        // お友だち紹介コード
        get: function () {
            var _a;
            // 友達招待のキャンペーンコードがある時のみ、invitationCodeをリクエストに含めることができる
            var isFriendInvitationCampaign = FRIEND_INVITATION_CODES.includes(this.loadCampaingCode);
            return isFriendInvitationCampaign ? (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.invitationCode : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "loadTieup", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.LP) === null || _b === void 0 ? void 0 : _b.tieup;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveParamsFromLP", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            var saveParams = this.loadLpParams || {};
            if (params.campaignCode === CAMPAIGN_CODE_RESET || params.campaignCode === CAMPAIGN_CODE_RESET_2024_05_15) {
                saveParams.campaignCode = '';
            }
            else if (params.campaignCode) {
                saveParams.campaignCode = params.campaignCode;
            }
            if (params.tieup) {
                saveParams.tieup = params.tieup;
            }
            this.saveStats({ LP: saveParams });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "removeLpParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.saveStats({ LP: null });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadLpParams", {
        // LPからのパラメータ
        get: function () {
            var _a;
            return (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.LP;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "loadCampaingCode", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.LP) === null || _b === void 0 ? void 0 : _b.campaignCode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "lpParamsHasMegalosCampaignCode", {
        // メガロスクーポンコードを利用しようとしているか
        get: function () {
            return this.loadCampaingCode === 'megalos_leanbody';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (referrer) {
            this.saveStats({ referrer: referrer });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadStatsReferrer", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.referrer) || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveLpMessageReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (referrer) {
            this.saveStats({ lpMessage: { referrer: referrer } });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadLpMessageReferrer", {
        get: function () {
            var _a, _b;
            return ((_b = (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.lpMessage) === null || _b === void 0 ? void 0 : _b.referrer) || null;
        },
        enumerable: false,
        configurable: true
    });
    // RELOCLUBユーザーIDの取得
    Object.defineProperty(LocalStorage.prototype, "saveReloclubKid", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (kid) {
            this.saveStats({ reloclubKid: kid });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadReloclubKid", {
        // RELOCLUBユーザーIDの取得
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.reloclubKid) || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHomeDialogId", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            this.storage.setItem(HOME_DIALOG_ID, JSON.stringify(id));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHomeDialogId", {
        get: function () {
            return this.loadObjByKey(HOME_DIALOG_ID) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveCourseInstructorCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (code) {
            this.storage.setItem(COURSE_INSTRUCTOR_CODE, JSON.stringify(code));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadCourseInstructorCode", {
        get: function () {
            return this.loadObjByKey(COURSE_INSTRUCTOR_CODE);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "removeCourseInstructorCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.storage.removeItem(COURSE_INSTRUCTOR_CODE);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveLiveScheduleTabNoticed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var navigationTab = Object.assign(this.loadNavigationTabNotice, {
                liveSchedule: new Date(),
            });
            this.storage.setItem(NAVIGATION_TABS, JSON.stringify(navigationTab));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveActivityTabNoticed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var navigationTab = Object.assign(this.loadNavigationTabNotice, { activity: new Date() });
            this.storage.setItem(NAVIGATION_TABS, JSON.stringify(navigationTab));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadNavigationTabNotice", {
        get: function () {
            return this.loadObjByKey(NAVIGATION_TABS) || {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "noticedLiveScheduleTabToday", {
        get: function () {
            if (!this.loadNavigationTabNotice.liveSchedule)
                return false;
            return dayjs(this.loadNavigationTabNotice.liveSchedule).isToday();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "noticedActivityTabToday", {
        get: function () {
            if (!this.loadNavigationTabNotice.activity)
                return false;
            return dayjs(this.loadNavigationTabNotice.activity).isToday();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveUserSeasonAggregateRank", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(USER_SEASON_AGGREGATE_RANK, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadUserSeasonAggregateRank", {
        get: function () {
            return this.loadObjByKey(USER_SEASON_AGGREGATE_RANK);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveLoggedInPlatform", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LOGGED_IN_PLATFORM, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadLoggedInPlatform", {
        get: function () {
            return this.loadObjByKey(LOGGED_IN_PLATFORM);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "resetLoggedInPlatform", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.storage.removeItem(LOGGED_IN_PLATFORM);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveBqSnsShareClickParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(BQ_SNS_SHARE_CLICK_PARAMS, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadBqSnsShareClickParams", {
        get: function () {
            return this.loadObjByKey(BQ_SNS_SHARE_CLICK_PARAMS);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveTwitterRedirectData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(TWITTER_REDIRECT_DATA, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadTwitterRedirectData", {
        get: function () {
            return this.loadObjByKey(TWITTER_REDIRECT_DATA);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "resetTwitterData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.storage.removeItem(BQ_SNS_SHARE_CLICK_PARAMS);
            this.storage.removeItem(TWITTER_REDIRECT_DATA);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadObjByKey", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            var stats = this.storage.getItem(key);
            return JSON.parse(stats);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveSearchHistories", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(SEARCH_HISTORIES, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadSearchHistories", {
        get: function () {
            return this.loadObjByKey(SEARCH_HISTORIES);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "removeAfterCCRegistration", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // 友だち紹介コードは再利用できないように削除
            this.removeInvitationCode();
            // クレカ登録以降はデータ永続化させたくないので、RELOCLUBのユーザIDをリセット
            this.saveReloclubKid(null);
            // tieupやcampaignCodeはクレカ登録後、必要なくなるため削除する
            this.removeLpParams();
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadAndroidNotificationPermissionRequestUIDismissals", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var setting = this.storage.getItem(ANDROID_NOTIFICATION_PERMISSION_REQUEST_UI_DISMISSALS);
            if (setting) {
                return JSON.parse(setting);
            }
            return {
                liveLessonBanner: false,
                mealTaskDialog: false,
            };
        }
    });
    Object.defineProperty(LocalStorage.prototype, "setAndroidNotificationPermissionRequestUIDismissals", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            this.storage.setItem(ANDROID_NOTIFICATION_PERMISSION_REQUEST_UI_DISMISSALS, JSON.stringify(params));
        }
    });
    return LocalStorage;
}());
export { LocalStorage };
