var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { createElement } from 'react';
import { Route, Routes } from 'react-router';
import * as route from '@lean-body/src/route';
// 画面を横向きに変更した際に、Main.tsxの再レンダリングが起きても、各ページのコンポーネントが再度読み込みにならないようにReact.memo()を使用
export var RootMain = React.memo(function (_a) {
    var cf = _a.cf, profile = _a.profile, setProfile = _a.setProfile, me = _a.me, setMe = _a.setMe, homeDay = _a.homeDay, setHomeDay = _a.setHomeDay;
    return (React.createElement(Routes, null,
        React.createElement(Route, __assign({}, route.LESSON, { element: createElement(cf.LessonPage) })),
        React.createElement(Route, __assign({}, route.PROGRAM, { element: createElement(cf.ProgramPage) })),
        React.createElement(Route, __assign({}, route.PROGRAMS, { element: createElement(cf.ProgramsPage) })),
        React.createElement(Route, __assign({}, route.INVITATION, { element: createElement(cf.InvitationPage) })),
        React.createElement(Route, __assign({}, route.PLAN_CHANGE, { element: createElement(cf.PlanChangePage) })),
        React.createElement(Route, __assign({}, route.PLAN_CHANGE_UNSUBSCRIBE_CP, { element: createElement(cf.PlanChangeUnsubscribeCpPage) })),
        React.createElement(Route, __assign({}, route.COACHING_PLAN_UNSUBSCRIBE, { element: createElement(cf.CoachingPlanUnsubscribePage) })),
        React.createElement(Route, __assign({}, route.UNSUBSCRIBE_CAUTION, { element: createElement(cf.UnsubscribeCautionPage) })),
        React.createElement(Route, __assign({}, route.UNSUBSCRIBE_CONFIRM, { element: createElement(cf.UnsubscribeConfirmPage) })),
        React.createElement(Route, __assign({}, route.ACTIVITY, { element: createElement(cf.ActivityPage) })),
        React.createElement(Route, __assign({}, route.SEARCH_RESULT, { element: createElement(cf.SearchResultPage) })),
        React.createElement(Route, __assign({}, route.SEARCH, { element: createElement(cf.MainSearchPage) })),
        React.createElement(Route, __assign({}, route.MERCHANDISE, { element: createElement(cf.MainMerchandisePage) })),
        React.createElement(Route, __assign({}, route.RANKING, { element: createElement(cf.MainRankingPage) })),
        React.createElement(Route, __assign({}, route.TRAINERS, { element: createElement(cf.MainTrainersTopPage) })),
        React.createElement(Route, __assign({}, route.INSTRUCTOR, { element: createElement(cf.MainInstructorPage) })),
        React.createElement(Route, __assign({}, route.MATCHING_ASSESSMENT_INTRO, { element: createElement(cf.MatchingAssessmentIntroPage) })),
        React.createElement(Route, __assign({}, route.MATCHING_ASSESSMENT, { element: createElement(cf.MatchingAssessmentPage) })),
        React.createElement(Route, __assign({}, route.CHECK_EMAIL, { element: createElement(cf.CheckEmailPage) })),
        React.createElement(Route, __assign({}, route.MEGALOS_LIVE_CALENDAR, { element: createElement(cf.MegalosLiveCalendar) })),
        React.createElement(Route, __assign({}, route.LIVE_SCHEDULE, { element: createElement(cf.LiveSchedulePage) })),
        React.createElement(Route, __assign({}, route.LIVE_LESSON_VIMEO, { element: createElement(cf.LiveLessonVimeoPage) })),
        React.createElement(Route, __assign({}, route.LIVE_LESSON, { element: createElement(cf.LivePage) })),
        React.createElement(Route, __assign({}, route.FUTURE_USER, { element: createElement(cf.FutureUserPage) })),
        React.createElement(Route, __assign({}, route.NEW_PLAYLIST, { element: createElement(cf.NewPlaylistPage) })),
        React.createElement(Route, __assign({}, route.ORIGINAL_PLAYLIST_UPDATE, { element: createElement(cf.NewPlaylistPage) })),
        React.createElement(Route, __assign({}, route.PLAYLIST, { element: createElement(cf.PlaylistPage) })),
        React.createElement(Route, __assign({}, route.PLAYLISTS, { element: createElement(cf.PlaylistsPage) })),
        React.createElement(Route, __assign({}, route.ORIGINAL_PLAYLIST, { element: createElement(cf.OriginalPlaylistPage) })),
        React.createElement(Route, __assign({}, route.ANNOUNCEMENT_DETAIL, { element: createElement(cf.AnnouncementDetailPage) })),
        React.createElement(Route, __assign({}, route.ANNOUNCEMENT_LIST, { element: createElement(cf.AnnouncementListPage) })),
        React.createElement(Route, __assign({}, route.USER_SEASON_RANK_HELP, { element: createElement(cf.UserSeassonRankHelpPage) })),
        React.createElement(Route, __assign({}, route.INITIAL_GUIDE, { element: createElement(cf.InitialGuidePage) })),
        React.createElement(Route, __assign({}, route.INITIAL_GUIDE_DETAIL, { element: createElement(cf.InitialGuideDetailPage) })),
        React.createElement(Route, __assign({}, route.DORMANT, { element: createElement(cf.DormantPage) })),
        React.createElement(Route, __assign({}, route.TWITTER_CALLBACK, { element: createElement(cf.TwitterCallbackPage) })),
        React.createElement(Route, __assign({}, route.BILLYS_STUDIO, { element: createElement(cf.BillysStudioPage) })),
        React.createElement(Route, __assign({}, route.SETTING, { element: createElement(cf.SettingPage, { profile: profile, setProfile: setProfile }) })),
        React.createElement(Route, __assign({}, route.GOAL_MATCHING_ASSESSMENT, { element: createElement(cf.GoalMatchingAssessmentPage, { setProfile: setProfile }) })),
        React.createElement(Route, __assign({}, route.MY_LESSON, { element: createElement(cf.MyLessonPage) })),
        React.createElement(Route, __assign({}, route.COURSE_VOLUME, { element: createElement(cf.MainCourseVolumePage) })),
        React.createElement(Route, __assign({}, route.COURSE, { element: createElement(cf.MainCoursePage) })),
        React.createElement(Route, __assign({}, route.CHANGE_COURSE, { element: createElement(cf.MainChangeCoursePage) })),
        React.createElement(Route, __assign({}, route.COURSE_REWARD, { element: createElement(cf.MainCourseRewardPage) })),
        React.createElement(Route, __assign({}, route.DAILY_TASKS, { element: createElement(cf.DailyTasksPage, { profile: profile, setProfile: setProfile, homeDay: homeDay }) })),
        React.createElement(Route, __assign({}, route.DAILY_TASKS_SUBTASK, { element: createElement(cf.DailyTaskSubtaskPage) })),
        React.createElement(Route, __assign({}, route.TOP, { element: createElement(cf.TopPage, { profile: profile, homeDay: homeDay, setHomeDay: setHomeDay }) })),
        React.createElement(Route, __assign({}, route.ANDROID_DELETE_ACCOUNT, { element: createElement(cf.AndroidAccountDeletionPage) })),
        React.createElement(Route, __assign({}, route.COACHING, { element: createElement(cf.CoachingPage, { me: me, setMe: setMe }) })),
        React.createElement(Route, __assign({}, route.NUTRIENTS, { element: createElement(cf.NutrientPage) })),
        React.createElement(Route, __assign({}, route.MEAL_SCORE, { element: createElement(cf.MealScorePage) })),
        React.createElement(Route, { path: "*", element: createElement(cf.NotFoundPage) })));
});
