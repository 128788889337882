var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ArrowDirection } from './icons/arrow_icon';
import { Colors, MediaBreakPointUp } from '../styles';
import { ArrowIconWithMargin } from './icons/arrow_icon_with_margin';
import { useNavigateBack } from '@lean-body/src/util';
export var MealHeader = function (_a) {
    var children = _a.children, onClickBack = _a.onClickBack;
    var back = useNavigateBack();
    var onClickBackHandler = function () {
        if (onClickBack) {
            onClickBack();
        }
        else {
            back();
        }
    };
    return (React.createElement("div", { className: css(styles.header) },
        React.createElement(ArrowIconWithMargin, { direction: ArrowDirection.left, color: Colors.Black, onClick: onClickBackHandler, classObject: styles.arrow }),
        children));
};
var styles = StyleSheet.create({
    header: (_a = {
            position: 'relative',
            width: '100%',
            minHeight: 42,
            fontWeight: 700,
            letterSpacing: 1.28,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        _a[MediaBreakPointUp.MD] = {
            minHeight: 56,
            paddingTop: 14,
        },
        _a[MediaBreakPointUp.LG] = {
            minHeight: 78,
            paddingTop: 20,
            paddingBottom: 16,
        },
        _a),
    arrow: {
        position: 'absolute',
        left: 16,
    },
});
