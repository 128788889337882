var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import * as React from 'react';
import { observer } from 'mobx-react';
import { routes } from '@lean-body/src/route';
import { css, StyleSheet } from 'aphrodite';
import { makeObservable, observable } from 'mobx';
import { FIXED_FOOTER_HEIGHT, PageLoading, UnsubscribeWarning } from '@lean-body/components/organisms';
import { Colors, MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { useNavigate } from 'react-router';
import { SettingItemKeys } from '../organisms/setting_menu';
import { ContractPlan } from '../atoms/contract_plan';
var PLAN_NAME_ID = 'smashLeanBodyPlanNameId'; // 退会チャットボット（株式会社Smash）で使用しているので変更した際は、連絡する
export var UnsubscribeCautionPage = observer(function (_a) {
    var vm = _a.vm;
    if (!vm.payment)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.head) }, "\u9000\u4F1A\u624B\u7D9A\u304D"),
        React.createElement("div", { className: css(styles.content) },
            React.createElement("div", { className: css(styles.title) }, "\u73FE\u5728\u767B\u9332\u4E2D\u306E\u30D7\u30E9\u30F3"),
            React.createElement(ContractPlan, { name: vm.payment.currentPlan.name, price: "\u6599\u91D1: ".concat(vm.payment.currentPlan.amount.toLocaleString(), "\u5186(\u7A0E\u8FBC)"), updateDate: "\u66F4\u65B0\u65E5: ".concat(vm.displayEndDate), id: PLAN_NAME_ID }),
            vm.payment.coachingPayment && (React.createElement("div", null,
                React.createElement(ContractPlan, { name: vm.payment.coachingPayment.planName, price: "\u6599\u91D1: ".concat(vm.payment.coachingPayment.planPrice.toLocaleString(), "\u5186(\u7A0E\u8FBC)"), updateDate: "\u66F4\u65B0\u65E5: ".concat(vm.payment.coachingPayment.curPeriodEndFormattedDate) }),
                vm.payment.isExpirationDateNearThanCoachingPayment(vm.payment.coachingPayment) ? (React.createElement("div", { className: css(styles.coachingPlanWarningText) },
                    "LEAN\u00A0BODY\u3092\u9000\u4F1A\u3057\u305F\u5834\u5408\u306F\u3001",
                    vm.displayEndDate,
                    "\u306B\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u3082\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059\u3002")) : (React.createElement("div", { className: css(styles.coachingPlanWarningText) },
                    "LEAN\u00A0BODY\u3092\u9000\u4F1A\u3057\u305F\u5834\u5408\u3001",
                    vm.payment.coachingPayment.curPeriodEndFormattedDate,
                    "\u307E\u3067\u306F\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002\u9000\u4F1A\u5F8C\u3001\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u306E\u66F4\u65B0\u304A\u3088\u3073\u518D\u958B\u306F\u3067\u304D\u307E\u305B\u3093\u3002")))),
            React.createElement(UnsubscribeWarning, { payment: vm.payment }))));
});
var UnsubscribeCautionPageVM = /** @class */ (function () {
    function UnsubscribeCautionPageVM(apiClient, accountService, alertPresenter, tracker) {
        var _this = this;
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "payment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        makeObservable(this, {
            me: observable,
            payment: observable,
        });
        this.apiClient = apiClient;
        this.accountService = accountService;
        this.navigate = useNavigate();
        this.alertPresenter = alertPresenter;
        this.tracker = tracker;
        this.fetchMe().then(function () {
            // 休眠中は退会できないようにリダイレクト
            if (_this.me.isDormant) {
                _this.navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                return _this.alertPresenter.showMessage('休眠中は退会できません');
            }
        });
    }
    Object.defineProperty(UnsubscribeCautionPageVM.prototype, "fetchMe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, this.accountService.fetchMe()];
                        case 1:
                            _a.me = _b.sent();
                            this.payment = this.me.payment;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(UnsubscribeCautionPageVM.prototype, "displayEndDate", {
        get: function () {
            if (!this.payment)
                return null;
            return this.payment.curPeriodEndDateInJapaneseFormat();
        },
        enumerable: false,
        configurable: true
    });
    return UnsubscribeCautionPageVM;
}());
export { UnsubscribeCautionPageVM };
var styles = StyleSheet.create({
    container: (_a = {
            backgroundColor: Colors.White,
            minHeight: "calc(100vh - ".concat(FIXED_FOOTER_HEIGHT, "px - ").concat(Sizes.MobileHeaderHeight, "px)"),
            padding: '0 30px'
        },
        _a[MediaBreakPointUp.MD] = {
            minHeight: '100vh',
        },
        _a),
    head: {
        fontSize: 18,
        fontWeight: 'bold',
        padding: '40px 0 24px',
        textAlign: 'start',
    },
    content: (_b = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 40
        },
        _b[MediaBreakPointUp.MD] = {
            padding: '0 50px',
        },
        _b),
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    coachingPlanWarningText: {
        color: Colors.Alert,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        marginTop: 12,
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
});
