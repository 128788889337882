var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { css, StyleSheet } from 'aphrodite';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { PaymentChannel } from '@lean-body/src/domain';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
import { RemoveQueuedPlanModal } from './remove_queued_plan_modal';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import bind from 'bind-decorator';
import { routes } from '@lean-body/src/route';
import * as msg from '@lean-body/src/messages';
import { useNavigate } from 'react-router';
import { SettingStyles } from '../pages/main_setting';
import { FooterComponent } from './footer';
import { ContractPlan } from '../atoms/contract_plan';
export var PlanSubsection = observer(function (_a) {
    var _b, _c;
    var classObject = _a.classObject, accountService = _a.accountService, apiClient = _a.apiClient, tracker = _a.tracker, alertPresenter = _a.alertPresenter, me = _a.me, setMe = _a.setMe;
    var navigate = useNavigate();
    var vm = React.useState(new PlanSubsectionVM(accountService, apiClient, tracker, navigate, alertPresenter, me, setMe))[0];
    var collaboration = vm.collaborationServiceName;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        vm.deletedAt ? (React.createElement("div", { className: css(styles.isDeleted) },
            "\u9000\u4F1A\u6E08\u307F\u3002",
            vm.displayEndDate,
            "\u307E\u3067\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002\u305D\u306E\u5F8C\u306F\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059\u3002",
            me.payment.coachingPayment &&
                !me.payment.isExpirationDateNearThanCoachingPayment(me.payment.coachingPayment) && (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                "\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u306F",
                me.payment.coachingPayment.curPeriodEndFormattedDate,
                "\u307E\u3067\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002")))) : vm.isDormant ? (React.createElement("div", null,
            React.createElement("div", { className: css(styles.planTitle) }, "\u73FE\u5728\u4F11\u7720\u4E2D\u3067\u3059"),
            "\u3042\u306A\u305F\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u306F\u73FE\u5728\u4F11\u7720\u30A2\u30AB\u30A6\u30F3\u30C8\u306B\u306A\u3063\u3066\u3044\u307E\u3059\u3002\u9014\u4E2D\u3067\u4E00\u822C\u4F1A\u54E1\u306B\u5FA9\u65E7\u3059\u308B\u4E8B\u3082\u3067\u304D\u307E\u3059\u3002",
            React.createElement("div", { className: css(styles.dormantEndDate) },
                "\u4F11\u7720\u671F\u9593: ",
                vm.payment.dormantPeriodEnd.format('YYYY年M月D日'),
                "\u307E\u3067"),
            React.createElement(Button, { onClick: vm.onClickReactivateDormant, loading: vm.submitting, disabled: vm.submitting, buttonType: ButtonType.primary, className: css(SettingStyles.button) }, "\u4E00\u822C\u4F1A\u54E1\u306B\u5FA9\u65E7\u3059\u308B"))) : vm.payment.isEmployeeWelfareUser() ? (
        ////////////////////////////////
        // 福利厚生プラン
        ///////////////////////////////
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
            React.createElement("div", { className: css(styles.planName) }, vm.subPlan.name),
            React.createElement("div", { className: css(styles.price) },
                "\u6599\u91D1: ",
                vm.subPlan.amount.toLocaleString(),
                "\u5186(\u7A0E\u8FBC)"))) : vm.payment.isPaymentChannelMatched(PaymentChannel.MEGALOS, PaymentChannel.COLLABORATION) ? (
        ////////////////////////////////
        // Megalosプラン or 協業プラン
        ///////////////////////////////
        React.createElement(React.Fragment, null,
            vm.payment.isMegalosOptionUser() && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
                React.createElement("div", { className: css(styles.planName) }, vm.subPlan.name),
                React.createElement("div", { className: css(styles.price) },
                    "\u6599\u91D1: ",
                    vm.subPlan.amount.toLocaleString(),
                    "\u5186(\u7A0E\u8FBC)"))),
            React.createElement("div", { className: css(styles.collaborationText) },
                "\u203B\u6599\u91D1\u306E\u8ACB\u6C42\u306B\u95A2\u3059\u308B\u554F\u3044\u5408\u308F\u305B\u306F\u4E0B\u8A18\u306B\u3066\u304A\u9858\u3044\u3057\u307E\u3059",
                React.createElement("a", { href: collaboration.inquiryLink, target: "_blank", "data-test": "contactMegalos", className: css(styles.inqueryLink) },
                    "\u30FB\u304A\u554F\u3044\u5408\u308F\u305B | ",
                    collaboration.inquiryLinkText)))) : vm.payment.isAppstorePayment() ? (
        ///////////////////////
        // AppStoreプラン
        //////////////////////
        React.createElement("div", null,
            React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
            React.createElement("div", null, "iPhone\u30A2\u30D7\u30EA\u304B\u3089\u8AB2\u91D1\u3057\u305F\u65B9\u306FiPhone\u306E\u8A2D\u5B9A\u304B\u3089\u30D7\u30E9\u30F3\u60C5\u5831\u3092\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044"),
            React.createElement("a", { className: css(styles.inqueryLink), href: "https://wondernuts.zendesk.com/hc/ja/articles/360002354634-LEAN-BODY%E3%82%92%E9%80%80%E4%BC%9A%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%E3%81%A9%E3%81%86%E3%81%99%E3%82%8C%E3%81%B0%E8%89%AF%E3%81%84%E3%81%A7%E3%81%99%E3%81%8B-", target: "_blank" }, "\u9000\u4F1A\u65B9\u6CD5\u306B\u3064\u3044\u3066\u306F\u3053\u3061\u3089"))) : vm.payment.isPaymentChannelMatched(PaymentChannel.None) ? (
        ///////////////////////
        // 課金不要プラン
        //////////////////////
        React.createElement("div", null,
            React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
            React.createElement("div", { className: css(styles.planName) }, vm.subPlan.name),
            React.createElement("div", { className: css(styles.planName) }, "\u203B\u5229\u7528\u671F\u9593\u4EE5\u964D\u7D99\u7D9A\u3055\u308C\u305F\u3044\u5834\u5408\u306F\u3001\u671F\u9593\u7D42\u4E86\u5F8C\u306B\u518D\u5EA6\u767B\u9332\u753B\u9762\u304B\u3089\u304A\u652F\u6255\u3044\u60C5\u5831\u306E\u5165\u529B\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002"))) : (
        /////////////////////////////////////
        // Stripeプラン or AmazonPayプラン
        /////////////////////////////////////
        React.createElement("div", { className: css(styles.stripeAndAmazonPayContainer), "data-test": "planContainer" },
            React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
            React.createElement("div", null,
                React.createElement(ContractPlan, { name: vm.subPlan.name, price: "\u6599\u91D1: ".concat(vm.subPlan.amount.toLocaleString(), "\u5186(\u7A0E\u8FBC)"), updateDate: !vm.hasQueuedPlan && "\u66F4\u65B0\u65E5: ".concat(vm.displayEndDate) }),
                vm.hasQueuedPlan && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css(styles.planTitleNext) }, "\u66F4\u65B0\u4E88\u5B9A\u306E\u30D7\u30E9\u30F3"),
                    React.createElement(ContractPlan, { name: vm.queuedPlan.name, price: "\u6599\u91D1:".concat(vm.queuedPlan.amount.toLocaleString(), "\u5186(\u7A0E\u8FBC)"), updateDate: "\u66F4\u65B0\u65E5: ".concat(vm.displayEndDate), updateDateClassObject: styles.queuedPlanUpdateDate }))),
                me.canChangePlans && (React.createElement(Button, { className: css(SettingStyles.button, styles.planChangeButton), buttonType: ButtonType.whiteWithBlack, onClick: function () { return vm.navigate(routes.PLAN_CHANGE.compile()); }, dataTest: "changePlan" }, "\u30D7\u30E9\u30F3\u3092\u5909\u66F4\u3059\u308B")),
                vm.canRemoveQueuedPlanModal && (React.createElement(RemoveQueuedPlanModal, { className: css(SettingStyles.button, styles.button), onClick: vm.removeQueuedPlan }))),
            ((_b = vm.payment.coachingPayment) === null || _b === void 0 ? void 0 : _b.isValid) && (React.createElement("div", null,
                React.createElement(ContractPlan, { updateDateClassObject: vm.payment.coachingPayment.deleted ? styles.planEndDate : styles.planUpdateDate, name: vm.payment.coachingPayment.planName, price: "\u6599\u91D1: ".concat(vm.payment.coachingPayment.planPrice, "\u5186(\u7A0E\u8FBC)"), updateDate: vm.payment.coachingPayment.deleted
                        ? "".concat(vm.payment.coachingPayment.curPeriodEndFormattedDate, "\u306B\u7D42\u4E86")
                        : "\u66F4\u65B0\u65E5: ".concat(vm.payment.coachingPayment.curPeriodEndFormattedDate) }),
                !vm.payment.coachingPayment.deleted && (React.createElement(Button, { className: css(SettingStyles.button, styles.stopCoachingButton), buttonType: ButtonType.whiteWithBlack, onClick: function () { return vm.navigate(routes.COACHING_PLAN_UNSUBSCRIBE.compile()); }, dataTest: "changePlan" }, "\u30D5\u30A3\u30C3\u30C8\u30E9\u30A4\u30D5\u30B3\u30FC\u30C1\u30D7\u30E9\u30F3\u3092\u505C\u6B62\u3059\u308B")))),
            React.createElement("div", null,
                vm.payment.dormantAvailable(vm.isDormant) && (React.createElement(React.Fragment, null, ((_c = vm.payment.coachingPayment) === null || _c === void 0 ? void 0 : _c.isOngoing) ? (React.createElement(React.Fragment, null,
                    React.createElement(Button, { buttonType: ButtonType.whiteWithBlack, className: css(SettingStyles.button, styles.button, styles.disabledButton), disabled: true }, "\u4F11\u7720\u3059\u308B"),
                    React.createElement("div", { className: css(styles.dormantAnnotation) }, "\u4F11\u7720\u3059\u308B\u306B\u306F\u30D5\u30A3\u30C3\u30C8\u30E9\u30A4\u30D5\u30B3\u30FC\u30C1\u30D7\u30E9\u30F3\u3092\u505C\u6B62\u3057\u3066\u304F\u3060\u3055\u3044"))) : (React.createElement(Button, { buttonType: ButtonType.whiteWithBlack, className: css(SettingStyles.button, styles.button), onClick: function () { return vm.navigate(routes.DORMANT.compile()); } }, "\u4F11\u7720\u3059\u308B")))),
                vm.visibleUnsubscribeBtn && (React.createElement(Button, { className: css(SettingStyles.button), onClick: function () { return vm.navigate(routes.UNSUBSCRIBE_CAUTION.compile()); }, dataTest: "deleteAccount", buttonType: ButtonType.whiteWithBlack }, "\u9000\u4F1A\u624B\u7D9A\u304D"))))),
        !vm.deletedAt && (React.createElement("div", { className: css(styles.footerContainer) },
            React.createElement(FooterComponent, null)))));
});
var PlanSubsectionVM = /** @class */ (function () {
    function PlanSubsectionVM(accountService, apiClient, tracker, navigate, alertPresenter, me, setMe) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "payment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "isDormant", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "deletedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "subPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "queuedPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setMe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "submitting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isQueuedPlanForPricingRevision", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            payment: observable,
            isDormant: observable,
            deletedAt: observable,
            subPlan: observable,
            queuedPlan: observable,
            me: observable,
            submitting: observable,
            isQueuedPlanForPricingRevision: observable,
        });
        this.accountService = accountService;
        this.navigate = navigate;
        this.alertPresenter = alertPresenter;
        this.apiClient = apiClient;
        this.tracker = tracker;
        this.onMeUpdated(me);
        this.setMe = setMe;
    }
    Object.defineProperty(PlanSubsectionVM.prototype, "displayEndDate", {
        get: function () {
            if (!this.payment)
                return null;
            var date = this.payment.curPeriodEndDate;
            return "".concat(date.getFullYear(), "\u5E74").concat(date.getMonth() + 1, "\u6708").concat(date.getDate(), "\u65E5");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PlanSubsectionVM.prototype, "hasQueuedPlan", {
        get: function () {
            var _a;
            return !!((_a = this.payment) === null || _a === void 0 ? void 0 : _a.queuedPlan);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PlanSubsectionVM.prototype, "canRemoveQueuedPlanModal", {
        get: function () {
            if (!this.hasQueuedPlan)
                return false;
            if (!this.subPlan.canCancelPlanChanging())
                return false;
            // 価格改訂のためのqueued_planの場合、プラン変更は取り消せない
            if (this.isQueuedPlanForPricingRevision)
                return false;
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PlanSubsectionVM.prototype, "collaborationServiceName", {
        get: function () {
            var _a;
            return (_a = this.payment.collaborationUserPayment) === null || _a === void 0 ? void 0 : _a.collaborationService;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PlanSubsectionVM.prototype, "visibleUnsubscribeBtn", {
        /**
         * 退会ボタンを表示するかの判定
         */
        get: function () {
            if (this.deletedAt != null)
                return false;
            if (this.isDormant)
                return false;
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PlanSubsectionVM.prototype, "onMeUpdated", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (me) {
            var _this = this;
            if (!me) {
                this.payment = null;
                return;
            }
            this.payment = me.payment;
            this.isDormant = me.isDormant;
            this.deletedAt = me.deletedAt;
            this.payment = me.payment;
            this.me = me;
            this.subPlan = me.payment.currentPlan;
            this.queuedPlan = me.payment.queuedPlan;
            if (this.queuedPlan != null) {
                this.apiClient.getIsQueuedPlanForPricingRevision().then(function (result) {
                    _this.isQueuedPlanForPricingRevision = result;
                });
            }
        }
    });
    Object.defineProperty(PlanSubsectionVM.prototype, "removeQueuedPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.apiClient
                .removeQueuedPlan()
                .then(function () {
                _this.tracker.trackClickRemovePlan(_this.me);
                _this.accountService.fetchMe({ nocache: true }).then(function (me) {
                    _this.setMe(me);
                    _this.onMeUpdated(me);
                    _this.alertPresenter.showMessage(msg.PLAN_RESET_SUCCEEDED);
                });
            })
                .catch(function (res) {
                _this.alertPresenter.showMessage(msg.PLAN_RESET_FAILED);
                throw res;
            });
        }
    });
    Object.defineProperty(PlanSubsectionVM.prototype, "onClickReactivateDormant", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.submitting = true;
            this.accountService
                .reactivateAccount()
                .then(function () {
                _this.alertPresenter.showMessage(msg.ACCOUNT_ACTIVATED);
                _this.navigate(routes.TOP.compile());
            })
                .catch(function (res) {
                _this.alertPresenter.showError(res, msg.ACTIVATE_FAILED, 'activate_failed');
                throw res;
            })
                .finally(function () {
                _this.submitting = false;
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlanSubsectionVM.prototype, "removeQueuedPlan", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], PlanSubsectionVM.prototype, "onClickReactivateDormant", null);
    return PlanSubsectionVM;
}());
export { PlanSubsectionVM };
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        fontSize: 14,
    },
    isDeleted: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            marginTop: 50,
        },
        _a),
    planTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    planTitleNext: {
        fontSize: 16,
        color: Colors.Primary,
        fontWeight: 'bold',
        margin: '24px 0 20px',
        paddingTop: 24,
        borderTop: "1px solid ".concat(Colors.Gray),
    },
    planName: {
        fontSize: 10,
    },
    price: {
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    dormantEndDate: {
        fontWeight: 'bold',
        margin: '40px 0',
    },
    planDate: {
        color: Colors.GrayDarkest,
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '0.5px',
    },
    planUpdateDate: {
        margin: '12px 0',
    },
    planEndDate: {
        marginTop: 12,
    },
    queuedPlanUpdateDate: {
        margin: '40px 0',
    },
    button: (_b = {
            marginBottom: 8
        },
        _b[MediaBreakPointUp.MD] = {
            marginBottom: 8,
        },
        _b),
    planChangeButton: (_c = {
            marginTop: 12
        },
        _c[MediaBreakPointUp.MD] = {
            marginTop: 12,
        },
        _c),
    stopCoachingButton: {
        padding: 16,
    },
    disabledButton: {
        color: Colors.GrayDarkest,
    },
    dormantAnnotation: {
        color: Colors.GrayDarkest,
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '0.5px',
        marginBottom: 8,
    },
    collaborationText: {},
    inqueryLink: {
        display: 'block',
        textDecoration: 'underline',
        color: Colors.Black,
        marginTop: 40,
    },
    stripeAndAmazonPayContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
    },
    coachingPlanText: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '0.5px',
    },
    footerContainer: {
        padding: '60px 0',
    },
});
