import { Trainer } from '.';
import { BASE_ENV_PROD } from '@lean-body/src/config';
import { getDiffDays, convertUnixToDate, useNowUnixtime } from '@lean-body/src/util';
import * as dayjs from 'dayjs';
var LiveLesson = /** @class */ (function () {
    function LiveLesson(LiveLessonData, config) {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "headerThumbnailPcSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "headerThumbnailSpSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bannerPcSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bannerSpSrc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "questionnaireUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isPublished", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playerUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chatUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveStartAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startsAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        }); // lessonStartAtと同義
        Object.defineProperty(this, "lessonEndAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveEndAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "trainer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxCalorie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "minCalorie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "difficulty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "attendanceCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isWatchable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isArchived", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isSubscribed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "label", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "watchableLimitTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = LiveLessonData.id;
        this.description = LiveLessonData.description;
        this.headerThumbnailPcSrc = LiveLessonData.headerThumbnailPcSrc;
        this.headerThumbnailSpSrc = LiveLessonData.headerThumbnailSpSrc;
        this.bannerPcSrc = LiveLessonData.bannerPcSrc;
        this.bannerSpSrc = LiveLessonData.bannerSpSrc;
        this.questionnaireUrl = LiveLessonData.questionnaireUrl;
        this.isPublished = LiveLessonData.isPublished;
        this.playerUrl = LiveLessonData.playerUrl;
        this.chatUrl = LiveLessonData.chatUrl;
        this.title = LiveLessonData.title;
        this.liveStartAt = LiveLessonData.liveStartAt;
        this.startsAt = LiveLessonData.startsAt;
        this.lessonEndAt = LiveLessonData.lessonEndAt;
        this.liveEndAt = LiveLessonData.liveEndAt;
        this.trainer = new Trainer(LiveLessonData.trainer, config);
        this.maxCalorie = LiveLessonData.maxCalorie;
        this.minCalorie = LiveLessonData.minCalorie;
        this.difficulty = LiveLessonData.difficulty;
        this.attendanceCount = LiveLessonData.attendancesCount;
        this.isWatchable = LiveLessonData.isWatchable;
        this.isArchived = LiveLessonData.isArchived;
        this.watchableLimitTime = LiveLessonData.watchableLimitTime;
        this.isSubscribed = LiveLessonData.isSubscribed;
        this.label = LiveLessonData.label && new LiveLessonLabel(LiveLessonData.label);
        this.config = config;
    }
    Object.defineProperty(LiveLesson.prototype, "startsAtDate", {
        get: function () {
            return convertUnixToDate(this.startsAt);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "lessonLength", {
        get: function () {
            return this.lessonEndAt - this.startsAt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "isHeldToday", {
        get: function () {
            return getDiffDays(this.startsAtDate, new Date()) === 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "isStarted", {
        get: function () {
            return this.startsAtDate < new Date();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "isFinished", {
        // ライブ配信が終了しているか
        get: function () {
            return convertUnixToDate(this.liveEndAt) < new Date();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "isArchiveFinished", {
        // ライブ配信のアーカイブ期間が終了しているか
        // アーカイブ期間: 開催日中
        get: function () {
            return getDiffDays(this.startsAtDate, new Date()) < 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "isBeingHeld", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var nowUnix = useNowUnixtime();
            return this.startsAt < nowUnix && nowUnix < this.lessonEndAt;
        }
    });
    Object.defineProperty(LiveLesson.prototype, "isBeingHeldIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nowUnix) {
            return this.startsAt < nowUnix && nowUnix < this.lessonEndAt;
        }
    });
    Object.defineProperty(LiveLesson.prototype, "headerThumbnailPcURL", {
        get: function () {
            return this.config.cdnBaseURL + this.headerThumbnailPcSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "headerThumbnailSpURL", {
        get: function () {
            return this.config.cdnBaseURL + this.headerThumbnailSpSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "bannerPcURL", {
        get: function () {
            return this.config.cdnBaseURL + this.bannerPcSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "bannerSpURL", {
        get: function () {
            return this.config.cdnBaseURL + this.bannerSpSrc;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "thumbnailPcURL", {
        get: function () {
            return this.bannerPcURL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "thumbnailSpURL", {
        get: function () {
            return this.bannerSpURL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "isEvent", {
        // 一回きりのイベントのためidで判定
        get: function () {
            return this.id === liveEventId();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "startsAtDayjs", {
        get: function () {
            return dayjs.unix(this.startsAt);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LiveLesson.prototype, "watchableLimitTimeDayjs", {
        get: function () {
            return dayjs.unix(this.watchableLimitTime);
        },
        enumerable: false,
        configurable: true
    });
    return LiveLesson;
}());
export { LiveLesson };
var LiveLessonLabel = /** @class */ (function () {
    function LiveLessonLabel(data) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "textColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "backgroundColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = data.name;
        this.textColor = data.textColor;
        this.backgroundColor = data.backgroundColor;
    }
    return LiveLessonLabel;
}());
export { LiveLessonLabel };
export var liveEventSections = function (config) {
    return [
        {
            time: '9:00 - 9:55',
            label: 'トレーニング',
            title: 'ぽっこりお腹をぺた腹へ！根本から凹ませるトレーニング',
            desc: '女性が一番気になるぽっこりお腹の原因である姿勢からアプローチしつつ、ストレッチ・ピラティスの動きで腹部深層筋にしっかり効かせる！くびれ作り・お腹引き締めレッスン。',
            trainer: new Trainer({
                name: '関 清香',
                instaUrl: 'https://www.instagram.com/studioes731/?hl=ja',
                thumbnailSrc: '/trainer/thumb_instructor_seki-kiyoka.jpg',
                bio: '指導歴12年。豊富な知識と指導経験を持ちインストラクターの指導育成にも携わるマスタートレーナー。\n姿勢や身体作りを日々研究するスペシャリスト。',
            }, config),
            trainerLabel: 'くびれ作りのスペシャリスト\nピラティスインストラクター',
        },
        {
            time: '10:00 - 10:55',
            label: 'トレーニング',
            title: '滝汗確定！脂肪燃焼・代謝アップを促進！美コアトレーニング',
            desc: '体幹を鍛えながらダイエット効果をもたらす「美コア」メソッドに沿ってリズムに合わせて有酸素運動！ とにかく動き続け、脂肪燃焼・代謝アップを促進し、体温上昇を図るスペシャルレッスン。',
            trainer: new Trainer({
                name: '山口 絵里加',
                instaUrl: 'https://www.instagram.com/erika__yamaguchi/?hl=ja',
                thumbnailSrc: '/trainer/thumb_instructor_erika-yamaguchi.jpg',
                bio: '体温上昇と代謝アップを目指す独自のメソッド「美コア」の考案者。日本全国だけでなく海外各国でもレッスンを開催。東京都内だけでも1カ月に1万3000人以上の受講生を抱え行なっている人気インストラクター。ReebokONEグランドアンバサダーとしても活躍中。',
            }, config),
            trainerLabel: 'ダイエットの専門家\nメディカルフィットトレーナー',
        },
        {
            time: '11:00 - 11:55',
            label: '食事・生活改善',
            title: '食欲コントロールを楽にするマインドと食習慣の秘訣',
            desc: '苦手意識を持つ人が多い食欲コントロール。\nストレスで食べ過ぎてしまうのは意思が弱いからだと誤解していませんか？\n楽にモチベーションを維持して、痩せるライフスタイルを体得する秘訣をライブ配信します！',
            trainer: new Trainer({
                name: '七瀬 葉',
                instaUrl: 'https://www.instagram.com/nanaseyou/?hl=ja',
                thumbnailSrc: '/trainer/thumb_instructor_nanase_yo.jpg',
                bio: '佐賀県出身。２０歳で上京。 東宝芸能エンターテイメントダンサーとして活躍。\n舞台「フラガール」、琉球ロマネスク「テンペスト」、映画「モテキ」等出演。ダンサーとして活動するなか過食症と戦い続け心とカラダのバランスをとることの大切さ痛感しボディメイクの道へ。 その後、ピラティスインストラクター、パーソナルストレッチトレーナーとしての経験を積み独立。のべ１５００名の指導実績を持つ。',
            }, config),
            trainerLabel: '『ダイエットこじらせさんが今度こそやせる本』著者 / ダイエットコーチ',
        },
    ];
};
// 一回きりのイベントのためidで指定
export var liveEventId = function () {
    return process.env.BASE_ENV === BASE_ENV_PROD ? 90 : 88;
};
