import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { Task } from '@lean-body/src/domain';
import { MealMenuList } from './meal_menu_list';
import { TimeSelectForm, TimeSelectFormat } from '../molecules';
import { RecordMealActionType } from './record_meal';
export var RecordMealConfirmForm = function (_a) {
    var selectedMenus = _a.selectedMenus, time = _a.time, onTimeChange = _a.onTimeChange, onClickDeselectMenu = _a.onClickDeselectMenu, onClickMenu = _a.onClickMenu, onClickAddMenu = _a.onClickAddMenu, classObject = _a.classObject;
    var sumSelectedMenuCalories = function () {
        return Array.from(selectedMenus.values()).reduce(function (calories, _a) {
            var menu = _a.menu, quantity = _a.quantity;
            return calories + menu.caloriesForQuantity(quantity);
        }, 0);
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.detailContainer) },
            React.createElement("div", { className: css(styles.detail) },
                React.createElement("div", { className: css(styles.label) }, "\u30AB\u30ED\u30EA\u30FC"),
                React.createElement("div", { className: css(styles.calories) },
                    Task.roundTaskCalories(sumSelectedMenuCalories()).toLocaleString(),
                    "kcal")),
            React.createElement("div", { className: css(styles.detail, styles.confirmInfoTime) },
                React.createElement("div", { className: css(styles.label) }, "\u98DF\u4E8B\u6642\u9593"),
                React.createElement(TimeSelectForm, { selectClassObject: styles.timeSelect, currentValue: time, setCurrentValue: onTimeChange, label: "", format: TimeSelectFormat.hhmm, step: 15, listClassObject: styles.timeSelectList, listItemClassObject: styles.timeSelectListItem }))),
        React.createElement(MealMenuList, { action: RecordMealActionType.confirm, selectedMenus: selectedMenus, onClickMenu: onClickMenu, onClickDeselectMenu: onClickDeselectMenu, onClickAddMenu: onClickAddMenu })));
};
var styles = StyleSheet.create({
    container: {},
    detailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 24,
        marginBottom: 8,
    },
    detail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    label: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginBottom: 2,
    },
    confirmInfoTime: {
        width: 82,
    },
    calories: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    timeSelect: {
        boxSizing: 'border-box',
        fontSize: 12,
        padding: '2px 8px 0 12px',
        height: 30,
        borderRadius: 15,
        borderColor: Colors.BorderLightGray,
    },
    timeSelectList: {
        height: 528,
    },
    timeSelectListItem: {
        fontSize: 12,
        minHeight: 33,
        height: 33,
    },
});
