import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { MealMenuListItem } from '../molecules/meal_menu_list_item';
import { RecordMealActionType } from './record_meal';
import { Colors } from '../styles';
import { MealMenuSelectedListItem } from '../molecules/meal_menu_selected_list_item';
import { PlusIcon } from '../atoms/icons/plus_icon';
export var MealMenuList = function (_a) {
    var action = _a.action, mealMenus = _a.mealMenus, selectedMenus = _a.selectedMenus, onClickMenu = _a.onClickMenu, onClickSelectMenu = _a.onClickSelectMenu, onClickDeselectMenu = _a.onClickDeselectMenu, onClickAddMenu = _a.onClickAddMenu, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject, action === RecordMealActionType.confirm && styles.containerGap) }, action === RecordMealActionType.select ? (mealMenus.map(function (menu) { return (React.createElement(MealMenuListItem, { key: menu.menuId, menu: menu, isSelected: selectedMenus.has(menu.menuId), onClick: onClickMenu, onClickSelect: onClickSelectMenu, onClickDeselect: onClickDeselectMenu, classObject: [styles.borderBottom, styles.menuItem] })); })) : (React.createElement(React.Fragment, null,
        Array.from(selectedMenus.values()).map(function (menuSelection) { return (React.createElement(MealMenuSelectedListItem, { key: menuSelection.menu.menuId, menuSelection: menuSelection, onClick: onClickMenu, onClickDeselect: onClickDeselectMenu, classObject: [styles.borderAround, styles.menuItem] })); }),
        React.createElement("div", { className: css(styles.borderAround, styles.addMenuButton), onClick: onClickAddMenu },
            React.createElement("span", null, "\u30E1\u30CB\u30E5\u30FC\u3092\u8FFD\u52A0"),
            React.createElement(PlusIcon, { color: Colors.Black }))))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    containerGap: {
        gap: 12,
        marginBottom: 12,
    },
    menuItem: {
        cursor: 'pointer',
    },
    borderBottom: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    borderAround: {
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        borderRadius: 10,
    },
    addMenuButton: {
        cursor: 'pointer',
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.56,
    },
});
