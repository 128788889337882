var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import bind from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Lesson } from '@lean-body/src/domain';
import { FavoriteStatus } from '@lean-body/src/interfaces';
import { PlaylistInfo, ReceivedBadgeOrUserSeasonRankDialog, OriginalPlaylistActions, TwitterFormDialog, } from '@lean-body/components/molecules';
import { PageLoading, PlaylistDetail, PlaylistHeader, PlaylistSummary, PlaylistSummaryHeader, } from '@lean-body/components/organisms';
import { LessonType, routes } from '@lean-body/src/route';
import { getDiffFromCurrent, useXlMediaQuery } from '@lean-body/src/util';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles/variables';
// FIXME: 以前はチャレンジプレイリストや通常プレイリストもこのコンポーネントを使っていたが、現在はオリジナルプレイリストのみで、使用しているので、必要ない実装を消してほしい
export var PlaylistPageComponent = function (_a) {
    var vm = _a.vm;
    var isXL = useXlMediaQuery();
    if (!vm.isFirstViewLoaded) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.wrapper) },
        !isXL ? (React.createElement("div", null,
            React.createElement(PlaylistHeader, { thumbnailURL: vm.playlist.thumbnailURL }),
            React.createElement(PlaylistInfo, { playlist: vm.playlist, totalFavoriteCount: vm.totalFavoriteCount, creator: vm.creator, isFavorited: vm.isFavorited, numOfLessons: vm.numOfLessonsWithoutDayOff, programTotalMinCal: vm.programTotalMinCal, programTotalMaxCal: vm.programTotalMaxCal, updatedDiff: null, onClickFavorite: vm.onClickFavProgram, isOriginalPlaylist: true, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed }))) : (React.createElement(React.Fragment, null,
            React.createElement(PlaylistSummary, { playlist: vm.playlist, totalFavoriteCount: vm.totalFavoriteCount, creator: vm.creator, isFavorited: vm.isFavorited, numOfLessons: vm.numOfLessonsWithoutDayOff, programTotalMinCal: vm.programTotalMinCal, programTotalMaxCal: vm.programTotalMaxCal, updatedDiff: vm.updatedDiff, onClickFavorite: vm.onClickFavProgram, isOriginalPlaylist: true, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed }),
            React.createElement(PlaylistSummaryHeader, { playlist: vm.playlist, totalFavoriteCount: vm.totalFavoriteCount, creator: vm.creator, isFavorited: vm.isFavorited, numOfLessons: vm.numOfLessonsWithoutDayOff, programTotalMinCal: vm.programTotalMinCal, programTotalMaxCal: vm.programTotalMaxCal, updatedDiff: vm.updatedDiff, onClickFavorite: vm.onClickFavProgram, isOriginalPlaylist: true, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed }))),
        React.createElement(PlaylistDetail, { className: css(styles.cardList), lessons: vm.lessons, lessonViewCounts: vm.lessonViewCounts, lessonFavoriteStatusArray: vm.lessonFavoriteStatusArray, onClickLesson: vm.onClickLesson, onClickLessonFavoriteButton: vm.onClickLessonFavoriteButton }),
        React.createElement(OriginalPlaylistActions, { id: vm.id, deleteOriginalPlaylist: vm.deleteOriginalPlaylist }),
        vm.isReceivedBadge && (React.createElement(ReceivedBadgeOrUserSeasonRankDialog, { badges: vm.badges, onClickCancel: vm.onCloseReceivedBadgeOrUserSeasonRankDialog, updateDisplayedUserBadges: vm.apiClient.updateDisplayedUserBadges, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed })),
        vm.isShowTwitterFormDialog && (React.createElement(TwitterFormDialog, { imageURL: vm.twitterService.tweetImgSrc, centerImageWidth: vm.twitterService.tweetCenterImageWidth, defaultText: vm.twitterService.tweetDefaultText, onClickCancel: vm.onClickTwitterPostCancel, submit: vm.onClickTwitterPost }))));
};
export var OriginalPlaylistPage = observer(PlaylistPageComponent);
var styles = StyleSheet.create({
    wrapper: {
        backgroundColor: Colors.BackgroundLightGray,
        marginBottom: 40,
    },
    schedule: (_a = {
            fontWeight: 'bold',
            fontSize: 18,
            textAlign: 'center',
            margin: '32px 0 0'
        },
        _a[MediaBreakPointUp.XL] = {
            fontSize: 20,
            margin: '32px 40px 0 84px',
            textAlign: 'left',
        },
        _a),
    cardList: (_b = {
            margin: '32px 20px 0'
        },
        _b[MediaBreakPointUp.MD] = {
            margin: '32px 90px 0',
        },
        _b[MediaBreakPointUp.XL] = {
            margin: '32px 40px 0',
        },
        _b),
    goal: (_c = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
            fontWeight: 'bold',
            maxWidth: 944,
            height: 45,
            marginTop: 32,
            background: Colors.GradationPrimary,
            color: Colors.White
        },
        _c[MediaBreakPointUp.XL] = {
            margin: '32px 40px 0 88px',
        },
        _c),
});
var OriginalPlaylistPageViewModel = /** @class */ (function () {
    function OriginalPlaylistPageViewModel(id, tracker, apiClient, router, twitterSerivce) {
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 表示しているプレイリスト
        Object.defineProperty(this, "playlist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // 初期再生動画のURL
        Object.defineProperty(this, "srcUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // プログラムの最小 kcal
        Object.defineProperty(this, "programTotalMinCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // プログラムの最大 kcal
        Object.defineProperty(this, "programTotalMaxCal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // DayOffを除いたレッスン数
        Object.defineProperty(this, "numOfLessonsWithoutDayOff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        // レッスンのお気に入り状態のリスト
        Object.defineProperty(this, "lessonFavoriteStatusArray", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        // 再生するレッスン
        Object.defineProperty(this, "lesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // レッスン動画のリスト
        Object.defineProperty(this, "lessons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // ユーザが獲得した未表示のバッジ
        Object.defineProperty(this, "badges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "isFavorited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // プレイリストをいいねした人数
        Object.defineProperty(this, "totalFavoriteCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalChallengedCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // レッスンの視聴回数
        Object.defineProperty(this, "lessonViewCounts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "isFirstViewLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // Videoコンポーネントのマウント/アンマウントを制御し初期処理を行うためのフラグ
        Object.defineProperty(this, "showVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "favoriteRequesting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "twitterService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isShowTwitterFormDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            lessons: observable,
            badges: observable,
            isFavorited: observable,
            totalFavoriteCount: observable,
            totalChallengedCount: observable,
            lessonViewCounts: observable.ref,
            isFirstViewLoaded: observable,
            showVideo: observable,
            lessonFavoriteStatusArray: observable,
            isShowTwitterFormDialog: observable,
        });
        this.id = Number(id);
        this.tracker = tracker;
        this.router = router;
        this.apiClient = apiClient;
        this.twitterService = twitterSerivce;
        this.initialize();
    }
    /*---------- 初期処理  ----------*/
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.load()];
                        case 1:
                            _a.sent();
                            if (this.twitterService.showFormDialog) {
                                this.isShowTwitterFormDialog = true;
                                this.twitterService.showFormDialog = false;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // プレイリストの表示に必要なデータの取得
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, playlist, isFavorited, badges, lessonViewCounts, lessonFavoriteStatusArray;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Promise.all([
                                this.fetchPlaylist(),
                                this.isFavoritedPlaylist(),
                                this.apiClient.fetchUndisplayedBadges(),
                                this.apiClient.fetchLessonViewCounts(),
                                this.apiClient.fetchLessonsFavoriteStatus(this.id),
                            ])];
                        case 1:
                            _a = _b.sent(), playlist = _a[0], isFavorited = _a[1], badges = _a[2], lessonViewCounts = _a[3], lessonFavoriteStatusArray = _a[4];
                            this.playlist = playlist;
                            this.lessons = playlist.lessons;
                            this.isFavorited = isFavorited;
                            this.badges = badges;
                            this.lessonViewCounts = lessonViewCounts;
                            this.lessonFavoriteStatusArray = lessonFavoriteStatusArray;
                            this.programTotalMinCal = playlist.totalMinCal;
                            this.programTotalMaxCal = playlist.totalMaxCal;
                            this.totalFavoriteCount = this.playlist.playlistAggregate.totalFavoriteCount;
                            this.totalChallengedCount = this.playlist.playlistAggregate.totalChallengedCount;
                            this.srcUrl = !this.lessons ? this.lessons[0].video.hlsURL : '';
                            this.numOfLessonsWithoutDayOff = this.lessons.filter(function (o) { return !o.isDayOff; }).length;
                            // ファーストビューに必要なコンテンツの初期処理が完了したタイミングでページをレンダリングを開始
                            this.isFirstViewLoaded = true;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /*---------- APIリクエスト ----------*/
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "fetchPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var playlist;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.fetchOriginalPlaylist(this.id)];
                        case 1:
                            playlist = _a.sent();
                            return [2 /*return*/, playlist];
                    }
                });
            });
        }
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "isFavoritedPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.router.matchRouteGroup(routes.ORIGINAL_PLAYLIST_GROUP))
                        return [2 /*return*/]; // オリジナルプレイリストが公開されたら、お気に入りかどうかのリクエストをここに書く
                    return [2 /*return*/, this.apiClient.isFavoritedProgram(this.id)];
                });
            });
        }
    });
    // レッスンのお気に入り状態を取得する
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "fetchLessonsFavoriteStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (playlistId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.fetchLessonsFavoriteStatus(playlistId)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "isReceivedBadge", {
        /*---------- Getter  ----------*/
        get: function () {
            return this.badges.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "updatedDiff", {
        get: function () {
            return getDiffFromCurrent(this.playlist.updatedAtDate.getTime());
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "creator", {
        get: function () {
            return this.playlist.creator;
        },
        enumerable: false,
        configurable: true
    });
    /*---------- イベントハンドラー ----------*/
    // プレイリスト動画をクリック
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "onClickLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.router.push(routes.LESSON, { slug: lesson.slug, type: LessonType.normal });
                    return [2 /*return*/];
                });
            });
        }
    });
    // プレイリストをお気に入りに追加または削除する
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "onClickFavProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var playlist;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.favoriteRequesting)
                                return [2 /*return*/];
                            this.favoriteRequesting = true;
                            if (!this.isFavorited) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.unFavoriteProgram(this.id)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.apiClient.addFavoriteProgram(this.id)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            this.isFavorited = !this.isFavorited;
                            this.favoriteRequesting = false;
                            this.tracker.trackFavoriteProgram(this.id, this.isFavorited ? FavoriteStatus.Add : FavoriteStatus.Remove);
                            return [4 /*yield*/, this.fetchPlaylist()];
                        case 5:
                            playlist = _a.sent();
                            this.totalFavoriteCount = playlist.playlistAggregate.totalFavoriteCount;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // プレイリストのレッスンをお気に入り追加または削除する
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "onClickLessonFavoriteButton", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, isFavorited) {
            return __awaiter(this, void 0, void 0, function () {
                var status;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            status = FavoriteStatus.Add;
                            if (!isFavorited) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.apiClient.unFavoriteLesson(lesson.id)];
                        case 1:
                            _a.sent();
                            status = FavoriteStatus.Remove;
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.apiClient.addFavoriteLesson(lesson.id)];
                        case 3:
                            _a.sent();
                            status = FavoriteStatus.Add;
                            _a.label = 4;
                        case 4:
                            this.tracker.trackFavoriteLesson(lesson, status);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "onClickTwitterShareIfAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = true;
        }
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "onClickTwitterPost", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (text, file) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.twitterService.postTweet(text, file).finally(function () {
                        _this.isShowTwitterFormDialog = false;
                    });
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "onClickTwitterPostCancel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = false;
        }
    });
    // バッジの取得情報をリセットする
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.badges = [];
        }
    });
    Object.defineProperty(OriginalPlaylistPageViewModel.prototype, "deleteOriginalPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.apiClient.deleteOriginalPlaylist(this.id).then(function () { return _this.router.push(routes.MY_LESSON); });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], OriginalPlaylistPageViewModel.prototype, "fetchLessonsFavoriteStatus", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", Promise)
    ], OriginalPlaylistPageViewModel.prototype, "onClickLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], OriginalPlaylistPageViewModel.prototype, "onClickFavProgram", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson, Boolean]),
        __metadata("design:returntype", Promise)
    ], OriginalPlaylistPageViewModel.prototype, "onClickLessonFavoriteButton", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], OriginalPlaylistPageViewModel.prototype, "onClickTwitterShareIfAuthed", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, File]),
        __metadata("design:returntype", Promise)
    ], OriginalPlaylistPageViewModel.prototype, "onClickTwitterPost", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], OriginalPlaylistPageViewModel.prototype, "onClickTwitterPostCancel", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], OriginalPlaylistPageViewModel.prototype, "onCloseReceivedBadgeOrUserSeasonRankDialog", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], OriginalPlaylistPageViewModel.prototype, "deleteOriginalPlaylist", null);
    return OriginalPlaylistPageViewModel;
}());
export { OriginalPlaylistPageViewModel };
