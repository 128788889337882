var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { NowRedirecting } from '@lean-body/components/organisms/now_redirecting';
import { useNavigate } from 'react-router-dom';
import { LocalStorage, SessionStorage, SignInType } from '@lean-body/src/infra';
import { routes } from '@lean-body/src/route';
import { SIGNUP_SUCCEEDED } from '@lean-body/src/messages';
import { AMAZON_PAY_CALLBACK_QUERY_PARAMS } from './amazon_pay_callback';
export var AmazonPayForSignupCallback = function (_a) {
    var accountService = _a.accountService, tracker = _a.tracker, alertPresenter = _a.alertPresenter;
    var navigate = useNavigate();
    var localStorage = new LocalStorage();
    var sessionStorage = new SessionStorage();
    React.useEffect(function () {
        // 新規登録時にあったクエリパラメータを現在のクエリパラメータとmergeする
        var urlSearchParams = new URLSearchParams(__assign(__assign({}, Object.fromEntries(new URLSearchParams(location.search))), Object.fromEntries(new URLSearchParams(sessionStorage.loadAmazonPayParams().queryParams))));
        accountService
            .amazonPaySignup(urlSearchParams.get(AMAZON_PAY_CALLBACK_QUERY_PARAMS), tracker)
            .then(function () {
            localStorage.saveSignInType(SignInType.email);
            navigate("".concat(routes.CC_REGISTRATION.compile()).concat(location.search));
            alertPresenter.showMessage(SIGNUP_SUCCEEDED, null);
        })
            .catch(function (e) {
            alertPresenter.showError(e, null);
            navigate(routes.SIGNUP.compile());
        });
    }, []);
    return React.createElement(NowRedirecting, null);
};
