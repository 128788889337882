var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { PageLoading } from '@lean-body/components/organisms';
import * as msg from '@lean-body/src/messages';
import { Link } from 'react-router-dom';
export var UnsubscribeConfirmPage = observer(function (_a) {
    var vm = _a.vm;
    if (!vm.me)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "\u9000\u4F1A\u624B\u7D9A\u304D"),
        React.createElement("div", { className: css(styles.descBold) }, vm.deleteSuccess ? '退会処理が完了しました' : '退会してよろしいですか？'),
        React.createElement("div", { className: css(styles.desc) }, vm.deleteSuccess
            ? "".concat(vm.displayEndDate, "\u307E\u3067\u306F\u5F15\u304D\u7D9A\u304DLEAN BODY\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002")
            : '問題がなければ「退会する」を押してください。'),
        !vm.deleteSuccess && (React.createElement(Button, { className: css(styles.button), disabled: vm.submitting, loading: vm.submitting, onClick: vm.deleteAccount, dataTest: "deleteAccount", buttonType: ButtonType.primary }, "\u9000\u4F1A\u3059\u308B")),
        React.createElement(Link, { to: routes.TOP.compile() },
            React.createElement(Button, { className: css(styles.button), buttonType: vm.deleteSuccess ? ButtonType.primary : ButtonType.whiteWithBlack, disabled: vm.submitting, loading: vm.submitting }, "HOME\u306B\u623B\u308B"))));
});
var UnsubscribeConfirmPageVM = /** @class */ (function () {
    function UnsubscribeConfirmPageVM(accountService, alertPresenter, tracker) {
        var _this = this;
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "submitting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "deleteSuccess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            submitting: observable,
            deleteSuccess: observable,
            me: observable,
        });
        this.alertPresenter = alertPresenter;
        this.accountService = accountService;
        this.tracker = tracker;
        this.accountService.fetchMe().then(function (v) {
            _this.me = v;
        });
    }
    Object.defineProperty(UnsubscribeConfirmPageVM.prototype, "displayEndDate", {
        get: function () {
            return this.me.payment.curPeriodEndDayjs.format('YYYY年MM月DD日');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnsubscribeConfirmPageVM.prototype, "deleteAccount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.submitting = true;
            this.accountService
                .deleteAccount()
                .then(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.deleteSuccess = true;
                    this.alertPresenter.showMessage(msg.CANCEL_MEMBERSHIP_SUCCEEDED, 'cancel_membership');
                    this.tracker.trackUnsubscribe();
                    return [2 /*return*/];
                });
            }); })
                .catch(function (err) {
                _this.alertPresenter.showError(err, msg.CANCEL_MEMBERSHIP_FAILED, 'cancel_membership');
            })
                .finally(function () {
                _this.submitting = false;
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], UnsubscribeConfirmPageVM.prototype, "deleteAccount", null);
    return UnsubscribeConfirmPageVM;
}());
export { UnsubscribeConfirmPageVM };
var styles = StyleSheet.create({
    container: {
        minHeight: '100vh',
        backgroundColor: Colors.White,
        padding: '30px',
        textAlign: 'center',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 24,
        textAlign: 'start',
    },
    descBold: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 40,
    },
    desc: {
        fontSize: 14,
        marginBottom: 40,
    },
    button: (_a = {
            width: '100%',
            marginBottom: 10,
            fontSize: 14,
            display: 'block'
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 240,
            margin: '0 auto 10px',
        },
        _a),
});
