var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { bind } from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import CopyText from 'react-copy-text';
import { EXISTING_USER_PRICING_REVISION_DATE_MS, } from '@lean-body/src/domain';
import { Card } from '@lean-body/components/atoms/card';
import { SectionHead } from '@lean-body/components/atoms/section_head';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
import { isNativeApp, isNowAfter } from '@lean-body/src/util';
var HASHTAG = 'リーンボディお友達招待';
var InvitationPageComponent = function (_a) {
    var _b, _c, _d;
    var vm = _a.vm;
    var isXL = useXlMediaQuery();
    var snsShareText = ((_b = vm.friendInvitationCampaign) === null || _b === void 0 ? void 0 : _b.isHolding)
        ? "\u3010".concat((_c = vm.friendInvitationCampaign) === null || _c === void 0 ? void 0 : _c.endAtStr, "\u307E\u3067\u3011\u304A\u3046\u3061\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9LEAN BODY\u3092\u59CB\u3081\u3066\u3001Amazon\u30AE\u30D5\u30C8\u5238").concat(vm.amazonGiftPriceStr, "\u5186\u5206GET\uFF01\u304A\u53CB\u9054\u7D39\u4ECB\u3067\u3055\u3089\u306B ").concat(vm.amazonGiftPriceStr, "\u5186\uFF01")
        : "\u304A\u3046\u3061\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9LEAN BODY\u3092\u59CB\u3081\u3066\u3001Amazon\u30AE\u30D5\u30C8\u5238".concat(vm.amazonGiftPriceStr, "\u5186\u5206GET\uFF01\u304A\u53CB\u9054\u7D39\u4ECB\u3067\u3055\u3089\u306B+").concat(vm.amazonGiftPriceStr, "\u5186\uFF01");
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.contents) },
            React.createElement("div", { className: css(styles.header) }, "\u304A\u53CB\u3060\u3061\u7D39\u4ECB\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3"),
            React.createElement(Card, { className: css(styles.card) },
                React.createElement(SectionHead, { classObject: styles.sectionTitle }, "\u304A\u53CB\u3060\u3061\u7D39\u4ECB\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u3068\u306F\uFF1F"),
                React.createElement("div", { className: css(styles.description) }, "\u304A\u53CB\u3060\u3061\u306BLEAN BODY\u3092\u3054\u7D39\u4ECB\u3044\u305F\u3060\u304F\u3068\u3001\u3054\u7D39\u4ECB\u8005\u3068\u304A\u53CB\u3060\u3061\u306B\u304A\u30C8\u30AF\u306A\u30D7\u30EC\u30BC\u30F3\u30C8\uFF01"),
                React.createElement("div", { className: css(styles.benefitContainer) },
                    ((_d = vm.friendInvitationCampaign) === null || _d === void 0 ? void 0 : _d.isHolding) && (React.createElement("div", { className: css(styles.benefit, styles.benefitFlex) },
                        React.createElement("div", { className: css(styles.benefitFirstTextLabel) }, "\u671F\u9593\u9650\u5B9A\uFF01"),
                        React.createElement("div", { className: css(styles.benefitFirstText) }, vm.friendInvitationCampaign.periodStr))),
                    React.createElement("div", { className: css(styles.benefit) },
                        React.createElement("div", { className: css(styles.benefitFirstText) },
                            React.createElement("div", { className: css(styles.benefitFirstTextMin) }, "\u304A\u624B\u7D9A\u304D\u4E0D\u8981 / \u4F55\u56DE\u3067\u3082\u62DB\u5F85OK"),
                            React.createElement("img", { src: "assets/images/invitation/amazon_gift.png", className: css(styles.amazonImg) }),
                            "\u3054\u7D39\u4ECB\u8005\u3068\u304A\u53CB\u3060\u3061",
                            isXL ? ('にもれなく') : (React.createElement(React.Fragment, null,
                                "\u3001",
                                React.createElement("br", null))),
                            React.createElement("span", { className: css(styles.benefitFirstTextBold) },
                                vm.amazonGiftPriceStr,
                                "\u5186\u5206"),
                            "\u305A\u3064\u30D7\u30EC\u30BC\u30F3\u30C8\uFF01"))),
                React.createElement("div", { className: css(styles.benefitAttention) },
                    "\u30AE\u30D5\u30C8\u5238\u306F\u3001\u3054\u7D39\u4ECB\u6708\u306E\u7FCC\u670810\u65E5\u307E\u3067\u306B\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3078\u9001\u4ED8\u3044\u305F\u3057\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "LINE\u3001facebook\u7B49\u306E\u30BD\u30FC\u30B7\u30E3\u30B7\u30E3\u30EB\u30ED\u30B0\u30A4\u30F3\u3092\u5229\u7528\u3057\u3066\u3001\u4F1A\u54E1\u767B\u9332\u3055\u308C\u305F\u65B9\u306F\u30E1\u30FC\u30EB\u3092\u304A\u9001\u308A\u3067\u304D\u306A\u3044\u5834\u5408\u304C\u3054\u3056\u3044\u307E\u3059\u3002\u30DE\u30A4\u30A2\u30AB\u30A6\u30F3\u30C8\u304B\u3089\u3054\u767B\u9332\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u3054\u78BA\u8A8D\u306E\u4E0A\u3001\u53D7\u4FE1\u53EF\u80FD\u306A\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3078\u5909\u66F4\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002"),
                React.createElement("div", { className: css(styles.sarani) },
                    React.createElement("div", { className: css(styles.cross) }),
                    "\u3055\u3089\u306B"),
                React.createElement("div", { className: css(styles.benefitContainer) },
                    React.createElement("div", { className: css(styles.benefit, isXL && styles.benefitFlexAlignTop) },
                        React.createElement("div", { className: css(styles.benefitSecondTextLabel) }, "\u3054\u7D39\u4ECB\u8005"),
                        React.createElement("div", { className: css(styles.benefitSecondTextContainer) },
                            React.createElement("div", { className: css(styles.benefitSecondText) },
                                React.createElement("div", { className: css(styles.benefitSecondTitle) },
                                    React.createElement("span", { className: css(styles.benefitSecondTitleBackground) }, "\u6708\u984D\u30D7\u30E9\u30F3\u3054\u5229\u7528\u306E\u65B9"),
                                    "\uFF1A"),
                                React.createElement("div", { className: css(styles.benefitSecondDesc) }, "\u7FCC\u6708\u306E\u30B5\u30FC\u30D3\u30B9\u5229\u7528\u6599\u3092\uFFE50\u3068\u3055\u305B\u3066\u3044\u305F\u3060\u304D\u307E\u3059\uFF01")),
                            React.createElement("div", { className: css(styles.benefitSecondText) },
                                React.createElement("div", { className: css(styles.benefitSecondTitle) },
                                    React.createElement("span", { className: css(styles.benefitSecondTitleBackground) }, "\u5E74\u9593\u30D7\u30E9\u30F3\u3054\u5229\u7528\u306E\u65B9"),
                                    "\uFF1A"),
                                React.createElement("div", { className: css(styles.benefitSecondDesc) }, vm.isShowNewFriendInvitationPlan
                                    ? '次回更新から12ヶ月プランを50％OFF（月額1,078円相当）でご利用いただけます！'
                                    : '次回更新から12ヶ月プランを75％OFF（月額¥480相当）でご利用いただけます！'),
                                !vm.isShowNewFriendInvitationPlan && (React.createElement("div", { className: css(styles.benefitCaution) }, "\u203B \u6B21\u56DE\u66F4\u65B0\u65E5\u304C2024/8/1\u4EE5\u964D\u306E\u5834\u5408\u306F\u65B0\u6599\u91D1\u30D7\u30E9\u30F3\u306E50\uFF05OFF\uFF08\u6708\u984D\u7A0E\u629C980\u5186\u76F8\u5F53\uFF09\u3067\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059"))))),
                    React.createElement("div", { className: css(styles.benefit, isXL && styles.benefitFlex) },
                        React.createElement("div", { className: css(styles.benefitSecondTextLabel) }, "\u304A\u53CB\u3060\u3061"),
                        React.createElement("div", { className: css(styles.benefitSecondTextContainer) },
                            React.createElement("div", { className: css(styles.benefitSecondDesc) }, vm.isShowNewFriendInvitationPlan
                                ? '紹介リンクからの入会で、12ヶ月プランを50％OFF（月額1,078円相当）でご利用いただけます！'
                                : '紹介リンクからの入会で、12ヶ月プランを75％OFFでご利用いただけます！'),
                            !vm.isShowNewFriendInvitationPlan && (React.createElement("div", { className: css(styles.benefitCaution) }, "\u203B \u7D39\u4ECB\u30EA\u30F3\u30AF\uFF0875%OFF\uFF09\u306E\u5229\u7528\u671F\u9650\u306F2024/7/31\u307E\u3067\u3068\u306A\u308A\u307E\u3059"))))),
                React.createElement("div", { className: css(styles.benefitAttention) },
                    "\u203B iOS\u30A2\u30D7\u30EA\u304B\u3089\u4F1A\u54E1\u767B\u9332\u3055\u308C\u305F\u65B9 / \u9000\u4F1A\u624B\u7D9A\u304D\u3092\u3055\u308C\u305F\u65B9\u306F\u30D7\u30E9\u30F3\u306E\u5272\u5F15\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u3054\u4E86\u627F\u304F\u3060\u3055\u3044",
                    React.createElement("br", null),
                    vm.isShowNewFriendInvitationPlan && (React.createElement(React.Fragment, null,
                        "\u203B \u7279\u5225\u30AD\u30E3\u30F3\u30DA\u30FC\u30F312\u30F6\u6708\u30D7\u30E9\u30F3\u3067\u4F1A\u54E1\u767B\u9332\u3055\u308C\u305F\u65B9\u306F\u30D7\u30E9\u30F3\u306E\u5272\u5F15\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u3054\u4E86\u627F\u304F\u3060\u3055\u3044",
                        React.createElement("br", null))),
                    "\u203B \u62DB\u5F85\u7279\u5178\u306F\u62DB\u5F85\u3055\u308C\u305F\u65B9\u304C\u652F\u6255\u3044\u60C5\u5831\u3092\u767B\u9332\u3057\u305F\u6642\u70B9\u3067\u9069\u7528\u3055\u308C\u307E\u3059",
                    React.createElement("br", null),
                    "\u203B \u30E1\u30AC\u30ED\u30B9 LEAN BODY\u3001\u798F\u5229\u539A\u751F\u30D7\u30E9\u30F3\u3001WECLE\u4F1A\u54E1\u30D7\u30E9\u30F3\u3001\u7279\u5B9A\u4FDD\u5065\u6307\u5C0E\u30D7\u30E9\u30F3\u3092\u3054\u5229\u7528\u306E\u65B9\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059",
                    React.createElement("br", null),
                    "\u203B \u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u306F\u544A\u77E5\u306A\u304F\u7D42\u4E86\u3055\u305B\u3066\u3044\u305F\u3060\u304F\u53EF\u80FD\u6027\u304C\u3054\u3056\u3044\u307E\u3059")),
            React.createElement(Card, { isCentered: true, className: css(styles.card) },
                React.createElement(SectionHead, { classObject: styles.sectionTitle }, "\u3042\u306A\u305F\u306E\u62DB\u5F85\u30EA\u30F3\u30AF"),
                React.createElement("div", { className: css(styles.copyLinkDescription) }, "\u3053\u3061\u3089\u306E\u30EA\u30F3\u30AF\u3092\u304A\u53CB\u3060\u3061\u306B\u5171\u6709\u3057\u3066\u304F\u3060\u3055\u3044"),
                React.createElement("div", { className: css(styles.copyContainer) },
                    React.createElement(CopyText, { text: vm.copyText }),
                    React.createElement("div", { className: css(styles.copyLink), onClick: vm.onClickCopy }, vm.invitationLink),
                    React.createElement("div", { onClick: vm.onClickCopy, className: css(styles.tapToCopy, vm.linkCopied && styles.copied) }, vm.linkCopied ? 'コピー済' : 'リンクをコピーする')),
                !isNativeApp() && (React.createElement("div", { className: css(styles.btnGroup) },
                    React.createElement("a", { href: "https://social-plugins.line.me/lineit/share?url=".concat(vm.encodedInvitaionLink, "&text=").concat(snsShareText), target: "_blank", className: css(styles.link, styles.linkLine) }, "LINE\u3067\u5171\u6709"),
                    React.createElement("a", { href: "https://www.facebook.com/sharer/sharer.php?u=".concat(vm.encodedInvitaionLink, "&hashtag=%23").concat(HASHTAG), target: "_blank", className: css(styles.link, styles.linkFacebook) }, "Facebook\u3067\u5171\u6709"),
                    React.createElement("a", { href: "http://twitter.com/share?url=".concat(vm.encodedInvitaionLink, "&text=").concat(snsShareText, "%0A%23").concat(HASHTAG, "%0A"), target: "_blank", className: css(styles.link, styles.linkTwitter) }, "X\u3067\u5171\u6709")))))));
};
export var InvitationPage = observer(InvitationPageComponent);
var InvitationPageVM = /** @class */ (function () {
    function InvitationPageVM(apiClient, tracker, config, accountService, userInvitationService) {
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "invitation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "invitationLink", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "linkCopied", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "copyText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "campaignCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userInvitationService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "friendInvitationCampaign", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "plan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            invitation: observable,
            invitationLink: observable,
            linkCopied: observable,
            copyText: observable,
            campaignCode: observable,
            friendInvitationCampaign: observable,
        });
        this.apiClient = apiClient;
        this.tracker = tracker;
        this.config = config;
        this.accountService = accountService;
        this.userInvitationService = userInvitationService;
        this.navigate = useNavigate();
        this.initialize();
    }
    Object.defineProperty(InvitationPageVM.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var me, queryString, invitationCampaignPath;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.accountService.fetchMe()];
                        case 1:
                            me = _a.sent();
                            if (!this.userInvitationService.invitationAvailable(me.payment)) {
                                this.navigate(routes.TOP.compile());
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, Promise.all([
                                    this.apiClient.fetchInvitationCode(),
                                    this.apiClient.fetchInvitationCampaignCode(),
                                    this.apiClient.getFriendInvitationCampaign(),
                                ]).then(function (_a) {
                                    var invitationCode = _a[0], campaignCode = _a[1], friendInvitationCampaign = _a[2];
                                    _this.invitation = invitationCode;
                                    _this.campaignCode = campaignCode;
                                    _this.friendInvitationCampaign = friendInvitationCampaign;
                                })
                                // 紹介側がプラン特典受けられるようにするための招待コード
                            ];
                        case 2:
                            _a.sent();
                            // 紹介側がプラン特典受けられるようにするための招待コード
                            this.plan = me.payment.currentPlan;
                            queryString = "?invitation_code=".concat(this.invitation.invitationCode, "&utm_souce=friend-invitation");
                            invitationCampaignPath = this.isShowNewFriendInvitationPlan ? 'friend_invitation_new' : 'friend_invitation';
                            this.invitationLink = "".concat(this.config.lpUrl, "/campaign/").concat(invitationCampaignPath, "/index.html").concat(queryString);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(InvitationPageVM.prototype, "isShowNewFriendInvitationPlan", {
        get: function () {
            var _a;
            return !!((_a = this.plan) === null || _a === void 0 ? void 0 : _a.isPlanAfter2024May15PriceChange) || isNowAfter(EXISTING_USER_PRICING_REVISION_DATE_MS);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvitationPageVM.prototype, "encodedInvitaionLink", {
        get: function () {
            return encodeURIComponent(this.invitationLink);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvitationPageVM.prototype, "amazonGiftPriceStr", {
        get: function () {
            return this.friendInvitationCampaign ? this.friendInvitationCampaign.amazonGiftPrice.toLocaleString() : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvitationPageVM.prototype, "onClickCopy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.copyText = this.invitationLink;
            this.linkCopied = true;
            this.tracker.trackInvitaionLinkCopy();
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], InvitationPageVM.prototype, "onClickCopy", null);
    return InvitationPageVM;
}());
export { InvitationPageVM };
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.BackgroundLightGray,
    },
    contents: (_a = {
            padding: '24px 12px 48px',
            position: 'relative',
            margin: '0 auto',
            maxWidth: '850px'
        },
        _a[MediaBreakPointUp.MD] = {
            width: '70%',
            padding: '32px 0 48px',
        },
        _a),
    card: (_b = {
            marginBottom: 24,
            padding: '24px 16px'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: 32,
        },
        _b),
    description: (_c = {
            fontWeight: 'bold',
            margin: '16px 0',
            lineHeight: '25px'
        },
        _c[MediaBreakPointUp.XL] = {
            textAlign: 'center',
        },
        _c),
    benefitContainer: {
        backgroundColor: Colors.BackgroundLightGray,
        padding: 24,
        maxWidth: 666,
        margin: '0 auto',
    },
    benefit: {
        ':not(:last-of-type)': {
            position: 'relative',
            marginBottom: 33,
            ':after': {
                position: 'absolute',
                content: '""',
                height: 1,
                width: '100%',
                bottom: -16,
                backgroundColor: Colors.Gray,
            },
        },
    },
    benefitFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    benefitFlexAlignTop: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    benefitFirstTextLabel: {
        color: Colors.White,
        fontSize: 14,
        fontWeight: 'bold',
        padding: '6px 20px',
        marginRight: 10,
        textAlign: 'center',
        backgroundColor: Colors.Accent,
        flex: 1,
        maxWidth: 120,
    },
    benefitFirstText: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        letterSpacing: '0.04em',
    },
    benefitFirstTextBold: (_d = {
            fontSize: 24,
            lineHeight: '33px'
        },
        _d[MediaBreakPointUp.XL] = {
            fontSize: 32,
            lineHeight: '44px',
        },
        _d),
    benefitFirstTextMin: {
        fontSize: 13,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':before': {
            display: 'block',
            content: '""',
            width: 2,
            height: 14,
            transform: 'rotate(-22deg)',
            marginRight: 12,
            backgroundColor: Colors.Black,
        },
        ':after': {
            display: 'block',
            content: '""',
            width: 2,
            height: 14,
            transform: 'rotate(22deg)',
            marginLeft: 12,
            backgroundColor: Colors.Black,
        },
    },
    benefitSecondTextLabel: (_e = {
            display: 'inline-block',
            padding: 10,
            fontWeight: 'bold',
            color: Colors.White,
            borderRadius: 8,
            margin: '0 0 16px 0',
            backgroundColor: Colors.PrimaryDark
        },
        _e[MediaBreakPointUp.XL] = {
            margin: '0 16px 0 0',
        },
        _e),
    benefitSecondTextContainer: {
        flex: 1,
    },
    benefitSecondText: {
        ':not(:last-of-type)': {
            marginBottom: 16,
        },
    },
    benefitSecondTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        position: 'relative',
        marginBottom: 4,
        display: 'inline-block',
    },
    benefitSecondTitleBackground: {
        background: "linear-gradient(transparent 60%, ".concat(Colors.BlueLighterB, " 40%)"),
    },
    benefitSecondDesc: {
        fontSize: 14,
    },
    benefitAttention: {
        fontSize: 12,
        lineHeight: '160%',
        margin: '16px auto 0',
        color: Colors.Black,
        maxWidth: 666,
    },
    benefitCaution: {
        fontSize: 12,
        marginTop: 8,
    },
    sarani: {
        margin: '28px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 'bold',
    },
    cross: {
        position: 'relative',
        width: 5,
        height: 20,
        background: Colors.Black,
        marginRight: 18,
        lineHeight: 1,
        ':after': {
            content: '""',
            position: 'absolute',
            top: 7.5,
            left: -7.5,
            width: 20,
            height: 5,
            background: Colors.Black,
        },
    },
    btnGroup: (_f = {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center'
        },
        _f[MediaBreakPointUp.XL] = {
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
        },
        _f),
    link: (_g = {
            color: Colors.White,
            display: 'block',
            width: '100%',
            transition: 'all .6s ease',
            borderRadius: '4px',
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 1,
            textAlign: 'center',
            padding: '10px 0'
        },
        _g[MediaBreakPointUp.XL] = {
            fontSize: 16,
            maxWidth: '250px',
            width: '32%',
        },
        _g[':not(:last-of-type)'] = (_h = {
                marginBottom: 16
            },
            _h[MediaBreakPointUp.XL] = {
                marginBottom: 0,
            },
            _h),
        _g[':hover'] = {
            opacity: 0.8,
        },
        _g),
    linkLine: {
        backgroundColor: Colors.Line,
    },
    linkFacebook: {
        backgroundColor: Colors.Facebook,
    },
    linkTwitter: {
        backgroundColor: Colors.StrictBlack,
    },
    copyLinkDescription: (_j = {
            fontSize: 14,
            margin: '16px 0'
        },
        _j[MediaBreakPointUp.MD] = {
            margin: '18px 0',
        },
        _j),
    copyContainer: (_k = {
            marginBottom: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        _k[MediaBreakPointUp.XL] = {
            flexDirection: 'row',
        },
        _k),
    copyLink: (_l = {
            fontSize: 12,
            fontWeight: 'bold',
            padding: 16,
            maxWidth: 454,
            backgroundColor: Colors.BackgroundLightGray,
            margin: '0 0 16px 0',
            cursor: 'pointer',
            textAlign: 'left'
        },
        _l[MediaBreakPointUp.XL] = {
            fontSize: 14,
            margin: '0 16px 0 0',
        },
        _l),
    tapToCopy: {
        color: Colors.GrayDarkest,
        cursor: 'pointer',
        fontSize: 14,
        padding: 12,
        width: 155,
        border: "1px solid ".concat(Colors.GrayDarkest),
        borderRadius: 4,
    },
    copied: {
        backgroundColor: Colors.GrayDarkest,
        color: Colors.White,
    },
    header: (_m = {
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: '1.25',
            marginBottom: 9
        },
        _m[MediaBreakPointUp.MD] = {
            margin: '16px 0 24px',
        },
        _m[MediaBreakPointUp.XL] = {
            fontSize: 28,
        },
        _m),
    sectionTitle: {
        fontSize: 18,
        textAlign: 'center',
    },
    amazonImg: {
        display: 'block',
        width: 170,
        margin: '0 auto 16px',
    },
});
