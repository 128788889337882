var _a;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { Sheet } from '../atoms/sheet';
import { Colors, MediaBreakPointUp } from '../styles';
import { RecordMealActionType } from '../organisms/record_meal';
import { PulseAnimation } from '../atoms/pulse_animation';
export var MealRecordSheet = function (_a) {
    var action = _a.action, selectedMenus = _a.selectedMenus, onClickSkipMeal = _a.onClickSkipMeal, onClickConfirm = _a.onClickConfirm, classObject = _a.classObject;
    return (React.createElement(Sheet, { classObject: [styles.sheet, classObject] },
        action === RecordMealActionType.select &&
            (selectedMenus.size ? (React.createElement("div", { className: css(styles.countContainer) },
                React.createElement(PulseAnimation, { key: selectedMenus.size },
                    React.createElement("span", { className: css(styles.count) }, selectedMenus.size)),
                "\u30E1\u30CB\u30E5\u30FC\u9078\u629E\u4E2D")) : (React.createElement(Button, { buttonType: ButtonType.secondary, isRadius: true, onClick: onClickSkipMeal, className: css(styles.skipButton) }, "\u98DF\u3079\u306A\u304B\u3063\u305F"))),
        React.createElement(Button, { buttonType: selectedMenus.size ? ButtonType.primary : ButtonType.transparentPrimary, isRadius: true, className: css(styles.confirmButton), onClick: onClickConfirm }, action === RecordMealActionType.select ? '登録を確認' : '登録を完了')));
};
var styles = StyleSheet.create({
    sheet: {
        position: 'sticky',
        bottom: 0,
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
        display: 'flex',
        gap: 12,
        padding: 16,
        height: 80,
    },
    skipButton: (_a = {
            width: 135,
            padding: 0,
            fontSize: 16
        },
        _a[MediaBreakPointUp.MD] = {
            width: 200,
        },
        _a),
    confirmButton: {
        fontSize: 16,
        flex: 1,
    },
    countContainer: {
        color: Colors.PrimaryDarkB,
        fontSize: 10,
        fontWeight: 700,
        letterSpacing: 0.8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    count: {
        fontSize: 32,
        letterSpacing: 2.56,
    },
});
