var CoachingPayment = /** @class */ (function () {
    function CoachingPayment(coachingPayment) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userPaymentId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "curPeriodStart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "curPeriodEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "deletedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "coachingStripePayment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "coachingAmazonPayPayment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = coachingPayment.id;
        this.userPaymentId = coachingPayment.userPaymentId;
        this.curPeriodStart = coachingPayment.curPeriodStart;
        this.curPeriodEnd = coachingPayment.curPeriodEnd;
        this.paymentStatus = coachingPayment.paymentStatus;
        this.isValid = coachingPayment.isValid;
        this.deletedAt = coachingPayment.deletedAt;
        this.coachingStripePayment = coachingPayment.coachingStripePayment;
        this.coachingAmazonPayPayment = coachingPayment.coachingAmazonPayPayment;
    }
    Object.defineProperty(CoachingPayment.prototype, "curPeriodEndFormattedDate", {
        get: function () {
            var endDate = new Date(this.curPeriodEnd * 1000);
            return "".concat(endDate.getFullYear(), "\u5E74").concat(endDate.getMonth() + 1, "\u6708").concat(endDate.getDate(), "\u65E5");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CoachingPayment.prototype, "isOngoing", {
        get: function () {
            return this.isValid && !this.deleted;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CoachingPayment.prototype, "deleted", {
        get: function () {
            return this.deletedAt !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CoachingPayment.prototype, "planName", {
        get: function () {
            var _a, _b, _c, _d;
            return (_d = ((_b = (_a = this.coachingStripePayment) === null || _a === void 0 ? void 0 : _a.coachingStripePlan) !== null && _b !== void 0 ? _b : (_c = this.coachingAmazonPayPayment) === null || _c === void 0 ? void 0 : _c.coachingAmazonPayPlan)) === null || _d === void 0 ? void 0 : _d.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CoachingPayment.prototype, "planPrice", {
        get: function () {
            var _a, _b, _c, _d;
            return (_d = ((_b = (_a = this.coachingStripePayment) === null || _a === void 0 ? void 0 : _a.coachingStripePlan) !== null && _b !== void 0 ? _b : (_c = this.coachingAmazonPayPayment) === null || _c === void 0 ? void 0 : _c.coachingAmazonPayPlan)) === null || _d === void 0 ? void 0 : _d.amount;
        },
        enumerable: false,
        configurable: true
    });
    return CoachingPayment;
}());
export { CoachingPayment };
