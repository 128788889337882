import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '@lean-body/components/styles/variables';
export var CloseRoundIcon = function (_a) {
    var color = _a.color, containerClassObject = _a.containerClassObject, classObject = _a.classObject, onClick = _a.onClick, size = _a.size;
    var styles = StyleSheet.create({
        container: {
            width: size || 12,
            height: size || 12,
            position: 'relative',
        },
        icon: {
            width: '100%',
            height: '100%',
            top: 0,
            cursor: 'pointer',
        },
    });
    return (React.createElement("span", { onClick: onClick, className: css(styles.container, containerClassObject) },
        React.createElement("svg", { className: css(styles.icon, classObject), viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fill: color || Colors.Gray, d: "M8 0.5C3.8525 0.5 0.5 3.8525 0.5 8C0.5 12.1475 3.8525 15.5 8 15.5C12.1475 15.5 15.5 12.1475 15.5 8C15.5 3.8525 12.1475 0.5 8 0.5ZM11.75 10.6925L10.6925 11.75L8 9.0575L5.3075 11.75L4.25 10.6925L6.9425 8L4.25 5.3075L5.3075 4.25L8 6.9425L10.6925 4.25L11.75 5.3075L9.0575 8L11.75 10.6925Z" }))));
};
