var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable no-case-declarations */
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Subscription } from '@lean-body/src/util/index';
import { take } from 'rxjs/operators';
import { VideoPlayButton } from '@lean-body/components/atoms/video_play_button';
import { LessonResultSectionVM } from '@lean-body/components/organisms/lesson_result_section';
import { Lesson, Program, VideoElementEventHandler, VideoPlayAnalyzer, VideoPlayerContext, VideoPlayerStatus, VideoState, } from '@lean-body/src/domain';
import { LBPlayer } from '../lb_player';
import { VideoType } from '@lean-body/src/domain/video';
export var Video = observer(function (_a) {
    var vm = _a.vm, src = _a.src, playButtonVisible = _a.playButtonVisible, onClickPlayButton = _a.onClickPlayButton;
    var _b = React.useState(null), videoEl = _b[0], setVideoEl = _b[1];
    var _c = React.useState(null), lbPlayerCtx = _c[0], setLbPlayerCtx = _c[1];
    React.useEffect(function () {
        if (videoEl && lbPlayerCtx) {
            vm.initializeVideoElement(videoEl, lbPlayerCtx);
        }
    }, [vm, videoEl, lbPlayerCtx]);
    if (vm === null) {
        return;
    }
    var onClick = function () {
        vm.setIsPaused(false);
        onClickPlayButton();
    };
    var videoOperationProps = {
        apiClient: vm.apiClient,
        onClickExitInnerFullScreen: vm.onClickExitInnerFullScreen,
        onClickPrev: vm.onClickPrev,
        onClickNext: vm.onClickNext,
        isInnerFullScreen: false,
        stepBackTime: 10,
        isFirstLesson: vm.isFirstLesson,
        isLastLesson: vm.isLastLesson,
    };
    var resultOptions = {
        isVisible: vm.resultSectionVisible,
        vm: vm.resultVM,
        addFavoriteLesson: vm.apiClient.addFavoriteLesson,
        unFavoriteLesson: vm.apiClient.unFavoriteLesson,
    };
    var lbVideoOptions = function (src) {
        return {
            sources: [
                {
                    src: src,
                    type: 'application/x-mpegURL',
                },
            ],
            preload: 'auto',
            playsinline: true, // iPhoneで自動的にiPhoneの動画再生プレイヤーが立ち上がってしまうのを回避するため
            controls: true,
            controlBar: {
                playToggle: false,
                volumePanel: false,
                muteToggle: false,
                fullscreenToggle: false,
                pictureInPictureToggle: false,
            },
        };
    };
    var styles = StyleSheet.create({
        container: {
            position: 'relative',
            height: '100%',
            width: '100%',
        },
        innerFullScreen: {
            left: 0,
            position: 'fixed',
            top: 0,
            zIndex: 10000,
        },
        playButton: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        playerWrapper: {
            width: '100%',
            height: '100%',
            display: vm.playerVisible ? 'inherit' : 'none',
        },
    });
    return (React.createElement("div", { className: css(styles.container, vm.isInnerFullScreen && styles.innerFullScreen) },
        React.createElement(VideoPlayButton, { visible: playButtonVisible, onClick: onClick, className: css(styles.playButton) }),
        React.createElement("div", { className: css(styles.playerWrapper), "data-test": "videoPlayer" },
            React.createElement(LBPlayer, { videoRef: function (ref) { return setVideoEl(ref); }, getPlayer: function (player) { return setLbPlayerCtx(player); }, videoOptions: lbVideoOptions(src), resultOptions: resultOptions, operationOptions: videoOperationProps }))));
});
/*=============================================
= ViewModel =
=============================================*/
var VideoVM = /** @class */ (function () {
    function VideoVM(router, navigate, apiClient, lessonService, signedCookieService, isAutoPlay, onClose, lesson, program, playPrevVideo, playNextVideo, courseLessonID, beforeCompleteLesson, afterCompleteLesson) {
        var _this = this;
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playStarted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "playButtonVisible", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isInnerFullScreen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // 現在再生されているプログラムを保持
        Object.defineProperty(this, "playingProgram", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // 現在再生されているレッスンを保持
        Object.defineProperty(this, "playingLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // 動画視聴完了後のUIのVM
        Object.defineProperty(this, "resultVM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // 動画の再生状態のステータス
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: VideoState.Loading
        });
        Object.defineProperty(this, "isAutoPlayNextVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isFirstLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isLastLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prevLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "courseLessonID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signedCookieService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ctx", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new VideoPlayerContext()
        });
        Object.defineProperty(this, "videoElResolved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "lbCtxResolved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "lbPlayerCtx", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Promise(function (resolve) { return (_this.lbCtxResolved = resolve); })
        });
        Object.defineProperty(this, "videoEl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Promise(function (resolve) { return (_this.videoElResolved = resolve); })
        });
        Object.defineProperty(this, "elEventHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "collector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Subscription()
        });
        Object.defineProperty(this, "time", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "session", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "analyzer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new VideoPlayAnalyzer()
        });
        Object.defineProperty(this, "videoType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // 動画終了時にページに戻った場合のコールバック
        Object.defineProperty(this, "onClose", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "afterCompleteLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "beforeCompleteLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playPrevVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playNextVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            playStarted: observable,
            isInnerFullScreen: observable,
            isAutoPlayNextVideo: observable,
            playingProgram: observable.ref,
            playingLesson: observable.ref,
            resultVM: observable,
            state: observable,
            playButtonVisible: observable,
            playerStatus: computed,
            resultSectionVisible: computed,
            playerVisible: computed,
        });
        this.router = router;
        this.navigate = navigate;
        this.apiClient = apiClient;
        this.lessonService = lessonService;
        this.signedCookieService = signedCookieService;
        this.elEventHandler = new VideoElementEventHandler(this, this.ctx, this.analyzer);
        this.setIsAutoPlayNextVideo();
        this.playPrevVideo = playPrevVideo;
        this.playNextVideo = playNextVideo;
        this.onClose = onClose;
        this.beforeCompleteLesson = beforeCompleteLesson;
        this.afterCompleteLesson = afterCompleteLesson;
        // 動画視聴完了後のUIのVMの初期設定
        this.initializeResultVM();
        // イベントの監視設定
        this.bindEvents();
        // VideoVMの初期処理完了
        this.onLoadComplete();
        if (isAutoPlay) {
            this.playLessonVideo(program, lesson, courseLessonID);
        }
    }
    Object.defineProperty(VideoVM.prototype, "playerStatus", {
        get: function () {
            return this.ctx.status;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoVM.prototype, "resultSectionVisible", {
        get: function () {
            return this.playerStatus === VideoPlayerStatus.Finished;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoVM.prototype, "playerVisible", {
        get: function () {
            return this.playStarted;
        },
        enumerable: false,
        configurable: true
    });
    // video要素の初期化処理（video要素のrefを取得してから実行される必要がある）
    Object.defineProperty(VideoVM.prototype, "initializeVideoElement", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (videoEl, lbPlayerCtx) {
            this.videoElResolved(videoEl);
            this.lbCtxResolved(lbPlayerCtx);
            this.elEventHandler.initialize(videoEl);
        }
    });
    // レッスン動画を動画プレイヤーに設定する
    Object.defineProperty(VideoVM.prototype, "setLessonVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (program, lesson, courseLessonID) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: 
                        // 再生する動画を設定
                        return [4 /*yield*/, this.setVideo(lesson.video)];
                        case 1:
                            // 再生する動画を設定
                            _a.sent();
                            this.setPlayingProgram(program);
                            this.setPlayingLesson(lesson);
                            this.setCourseLessonID(courseLessonID);
                            this.videoType = VideoType.Lesson;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // レッスン動画を再生する
    Object.defineProperty(VideoVM.prototype, "playLessonVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (program, lesson, courseLessonID) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.signedCookieService.fetchSignedCookies()]; // 動画を閲覧するための署名付きCookie取得
                        case 1:
                            _a.sent(); // 動画を閲覧するための署名付きCookie取得
                            return [4 /*yield*/, this.setLessonVideo(program, lesson, courseLessonID).then(function () { return _this.playWithTimeout(); })];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 前のレッスン動画を設定する
    Object.defineProperty(VideoVM.prototype, "setPrevLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson) {
            this.prevLesson = lesson;
        }
    });
    // 次のレッスン動画を設定する
    Object.defineProperty(VideoVM.prototype, "setNextLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson) {
            this.nextLesson = lesson;
        }
    });
    // 自動再生を設定する
    Object.defineProperty(VideoVM.prototype, "setIsAutoPlayNextVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var profile;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.fetchMypageProfile()];
                        case 1:
                            profile = _a.sent();
                            this.isAutoPlayNextVideo = profile.isAutoPlayNextVideo;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // プログラムの紹介動画を再生する
    Object.defineProperty(VideoVM.prototype, "playProgramIntroduceVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (program) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: 
                        // 再生する動画を設定
                        return [4 /*yield*/, this.setVideo(program.video)];
                        case 1:
                            // 再生する動画を設定
                            _a.sent();
                            this.videoType = VideoType.ProgramIntroduce;
                            this.playWithTimeout();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 再生ボタン押下時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onClickPlayButton", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.signedCookieService.fetchSignedCookies()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画プレイヤー内の戻るボタン押下時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onClickExitInnerFullScreen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.pause();
                            this.exitFullScreen();
                            this.playStarted = false;
                            return [4 /*yield*/, this.onLessonQuit()
                                // resultVMの後処理
                            ];
                        case 1:
                            _a.sent();
                            // resultVMの後処理
                            this.resultVM.finishLessonResultSection();
                            this.unbindEvents();
                            if (this.onClose) {
                                this.onClose();
                            }
                            this.navigate(-1);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画プレイヤー内の前へボタン押下時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onClickPrev", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.pause();
                            this.exitFullScreen();
                            this.playStarted = false;
                            return [4 /*yield*/, this.onLessonQuit()
                                // resultVMの後処理
                            ];
                        case 1:
                            _a.sent();
                            // resultVMの後処理
                            this.resultVM.finishLessonResultSection();
                            this.unbindEvents();
                            if (this.onClose) {
                                this.onClose();
                            }
                            this.playPrevVideo();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画プレイヤー内の次へボタン押下時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onClickNext", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                var _a, _b;
                return __generator(this, function (_c) {
                    this.pause();
                    this.exitFullScreen();
                    this.playStarted = false;
                    // resultVMの後処理
                    this.resultVM.finishLessonResultSection();
                    this.unbindEvents();
                    if (this.onClose) {
                        this.onClose();
                    }
                    // コースレッスンの場合はスキップも視完了済み扱いにする
                    if (this.courseLessonID) {
                        if (this.beforeCompleteLesson)
                            this.beforeCompleteLesson();
                        if (this.session) {
                            this.session
                                .complete(false, (_a = this.playingLesson) === null || _a === void 0 ? void 0 : _a.orderIndex, (_b = this.playingProgram) === null || _b === void 0 ? void 0 : _b.isChallenge, this.playingProgram)
                                .then(function () {
                                _this.resetAnalyzer();
                                if (_this.afterCompleteLesson)
                                    _this.afterCompleteLesson();
                            });
                        }
                    }
                    this.playNextVideo();
                    return [2 /*return*/];
                });
            });
        }
    });
    // 動画の視聴完了時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onLessonComplete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var _a, _b;
            if (this.isProgramIntroduceVideo()) {
                return;
            }
            var isChallengeProgram = (_a = this.playingProgram) === null || _a === void 0 ? void 0 : _a.isChallenge;
            var orderIndex = (_b = this.playingLesson) === null || _b === void 0 ? void 0 : _b.orderIndex;
            if (this.beforeCompleteLesson)
                this.beforeCompleteLesson();
            if (this.session) {
                this.session.complete(false, orderIndex, isChallengeProgram, this.playingProgram).then(function () {
                    _this.resetAnalyzer();
                    _this.state = VideoState.Result;
                    _this.hideController();
                    _this.startResult();
                    if (_this.afterCompleteLesson)
                        _this.afterCompleteLesson();
                });
            }
        }
    });
    // レッスン動画の一時停止時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onLessonSuspend", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.isProgramIntroduceVideo()) {
                return;
            }
            if (this.state !== VideoState.Playing) {
                return;
            }
            if (this.session) {
                this.session.susppend(this.time, this.playingProgram);
            }
        }
    });
    // フルスクリーン解除時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onFullscreenCancellation", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var player, VjsVideoController;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.isProgramIntroduceVideo()) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            if (player.isDisposed())
                                return [2 /*return*/, null];
                            VjsVideoController = player.getChild('VjsVideoController');
                            VjsVideoController.setIsPaused(true);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // レッスン動画のシーク時のハンドリング
    Object.defineProperty(VideoVM.prototype, "onLessonSeeked", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var player, VjsVideoController;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.isProgramIntroduceVideo()) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            if (player.isDisposed())
                                return [2 /*return*/, null];
                            VjsVideoController = player.getChild('VjsVideoController');
                            VjsVideoController.setHideTimer();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 再生中のプログラムを設定する
    Object.defineProperty(VideoVM.prototype, "setPlayingProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (playingProgram) {
            return __awaiter(this, void 0, void 0, function () {
                var player, VjsVideoSideSuper;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.playingProgram = playingProgram;
                            return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            VjsVideoSideSuper = player.getChild('VjsVideoSideSuper');
                            VjsVideoSideSuper.vm.setSeriesTitle(playingProgram.name);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 再生中のレッスンを設定する
    Object.defineProperty(VideoVM.prototype, "setPlayingLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (playingLesson) {
            return __awaiter(this, void 0, void 0, function () {
                var player, VjsVideoSideSuper;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.playingLesson = playingLesson;
                            return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            VjsVideoSideSuper = player.getChild('VjsVideoSideSuper');
                            VjsVideoSideSuper.vm.setTitle(playingLesson.name);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // コース用のレッスンIDを設定する
    Object.defineProperty(VideoVM.prototype, "setCourseLessonID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseLessonID) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.courseLessonID = courseLessonID;
                    return [2 /*return*/];
                });
            });
        }
    });
    // day の最初のレッスンかどうかを設定する
    Object.defineProperty(VideoVM.prototype, "setIsFirstLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isFirstLesson) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.isFirstLesson = isFirstLesson;
                    return [2 /*return*/];
                });
            });
        }
    });
    // day の最後のレッスンかどうかを設定する
    Object.defineProperty(VideoVM.prototype, "setIsLastLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isLastLesson) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.isLastLesson = isLastLesson;
                    return [2 /*return*/];
                });
            });
        }
    });
    // 動画プレイヤーにクラスを追加する
    Object.defineProperty(VideoVM.prototype, "addClass", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (className) {
            return __awaiter(this, void 0, void 0, function () {
                var videoEl;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.videoEl];
                        case 1:
                            videoEl = _a.sent();
                            videoEl.classList.add(className);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画プレイヤーのクラスを削除する
    Object.defineProperty(VideoVM.prototype, "removeClass", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (className) {
            return __awaiter(this, void 0, void 0, function () {
                var videoEl;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.videoEl];
                        case 1:
                            videoEl = _a.sent();
                            videoEl.classList.remove(className);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(VideoVM.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            // window の unload イベントを変更を監視
            this.router.outbound$
                .subscribe(function () {
                _this.onLessonQuit();
            })
                .addTo(this.collector);
            // 画面遷移を監視
            this.router.locationChange$
                .pipe(take(1))
                .subscribe(function () {
                _this.onLessonQuit();
                _this.unbindEvents();
            })
                .addTo(this.collector);
        }
    });
    // 動画視聴完了後のUIのVMを初期設定する
    Object.defineProperty(VideoVM.prototype, "initializeResultVM", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.resultVM = new LessonResultSectionVM(this.nextLesson, this.playNextVideo);
                            return [4 /*yield*/, this.resultVM.initialize(this)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画のコントローラーを表示する
    Object.defineProperty(VideoVM.prototype, "showController", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var player, VjsVideoController;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            VjsVideoController = player.getChild('VjsVideoController');
                            VjsVideoController.show();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画のコントローラーを非表示にする
    Object.defineProperty(VideoVM.prototype, "hideController", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var player, VjsVideoController;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            VjsVideoController = player.getChild('VjsVideoController');
                            VjsVideoController.hide();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(VideoVM.prototype, "onLoadComplete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.state = VideoState.Ready;
        }
    });
    Object.defineProperty(VideoVM.prototype, "onProgramIntroduceStart", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.playingProgram) {
                return;
            }
            this.state = VideoState.Playing;
        }
    });
    Object.defineProperty(VideoVM.prototype, "isProgramIntroduceVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.videoType === VideoType.ProgramIntroduce;
        }
    });
    Object.defineProperty(VideoVM.prototype, "isLessonVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.videoType === VideoType.Lesson;
        }
    });
    Object.defineProperty(VideoVM.prototype, "setIsPaused", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isPaused) {
            return __awaiter(this, void 0, void 0, function () {
                var player, VjsVideoController;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            VjsVideoController = player.getChild('VjsVideoController');
                            VjsVideoController.setIsPaused(isPaused);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(VideoVM.prototype, "onLessonStart", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.playingLesson) {
                                return [2 /*return*/];
                            }
                            _a = this;
                            return [4 /*yield*/, this.lessonService.startLesson(this.playingLesson, this.playingProgram, this.courseLessonID)];
                        case 1:
                            _a.session = _b.sent();
                            this.session.setVideoPlayAnalalyzor(this.analyzer);
                            this.state = VideoState.Playing;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 動画が途中で止められた場合のハンドリング
    Object.defineProperty(VideoVM.prototype, "onLessonQuit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            if (this.state !== VideoState.Playing) {
                return Promise.resolve();
            }
            if (this.session) {
                // TODO: isAutoPlayフラグのBQへのパラメータ設定
                // this.session.quit(this.time, this.isAutoPlay, this.playingLesson.orderIndex).then(result => {
                return this.session.quit(this.time, false, this.playingLesson.orderIndex, this.playingProgram, (_a = this.playingProgram) === null || _a === void 0 ? void 0 : _a.isChallenge);
            }
            this.resultVM = null;
            this.state = VideoState.Quit;
            return Promise.resolve();
        }
    });
    Object.defineProperty(VideoVM.prototype, "onReady", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.ctx.setReady();
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(VideoVM.prototype, "startResult", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.resultVM) {
                return;
            }
            if (this.state !== VideoState.Result) {
                return;
            }
            this.resultVM.start();
        }
    });
    Object.defineProperty(VideoVM.prototype, "unbindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.collector.unsubscribe();
        }
    });
    Object.defineProperty(VideoVM.prototype, "setVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (video) {
            return __awaiter(this, void 0, void 0, function () {
                var player;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.ctx.reset();
                            return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            player.src({
                                src: video.hlsURL,
                                withCredentials: true,
                            });
                            this.onReady();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // iPhoneでの再生の際、Timeoutを設定しないと再生されないため、setTimeoutを設定。
    //（300millisecに特に意味はないため再生できれば、どのような値でも問題ない）
    Object.defineProperty(VideoVM.prototype, "playWithTimeout", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            setTimeout(function () { return _this.play(); }, 300);
        }
    });
    // 動画を再生する
    Object.defineProperty(VideoVM.prototype, "play", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var player, onLoadedMetadata;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            player
                                .play()
                                .then(function () {
                                _this.playStarted = true;
                                _this.playButtonVisible = false;
                            })
                                .catch(function (e) {
                                // LBでは自動再生を実行しているので、再生がエラーになった場合、動画のサムネイルを表示する
                                _this.playStarted = false;
                                _this.playButtonVisible = true;
                                throw e;
                            });
                            // TODO: いずれの条件にも一致しない場合は例外を投げて上位でハンドリングする設計にする
                            if (this.isProgramIntroduceVideo()) {
                                this.onProgramIntroduceStart();
                            }
                            else if (this.isLessonVideo()) {
                                this.onLessonStart().then(function () {
                                    // 1分おきにサーバへ視聴アクティビティの同期を行う
                                    // clearInterval処理は動画終了したタイミングで自動で行われる
                                    player.setInterval(function () {
                                        if (!player.paused())
                                            _this.session.sync();
                                    }, 60000);
                                });
                            }
                            onLoadedMetadata = function () {
                                _this.analyzer.setDuration(player.duration());
                            };
                            // Videojsでloadedmetadataイベントが発行されるまで動画の長さを取得できないので、loadedmetadataイベントが発行されるのを待つ
                            if (player.readyState() < 1) {
                                // wait for loadedmetdata event
                                player.one('loadedmetadata', onLoadedMetadata);
                            }
                            else {
                                onLoadedMetadata();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(VideoVM.prototype, "pause", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var player;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.lbPlayerCtx];
                        case 1:
                            player = _a.sent();
                            player.pause();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // フルスクリーンを解除する
    Object.defineProperty(VideoVM.prototype, "exitFullScreen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var e_1, castedValue;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!document.exitFullscreen) return [3 /*break*/, 5];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, document.exitFullscreen()];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            return [3 /*break*/, 4];
                        case 4: return [3 /*break*/, 8];
                        case 5:
                            if (!document.webkitCancelFullScreen) return [3 /*break*/, 7];
                            return [4 /*yield*/, document.webkitCancelFullScreen()];
                        case 6:
                            _a.sent();
                            return [3 /*break*/, 8];
                        case 7:
                            if (this.videoEl.msExitFullscreen) {
                                castedValue = this.videoEl // 自動補完でセミコロンが挿入される不具合？回避のための代入
                                ;
                                castedValue.msExitFullscreen();
                            }
                            _a.label = 8;
                        case 8: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(VideoVM.prototype, "resetAnalyzer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.analyzer = new VideoPlayAnalyzer();
            this.elEventHandler.setAnalyzer(this.analyzer);
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Lesson, Number]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setLessonVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Lesson, Number]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "playLessonVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", void 0)
    ], VideoVM.prototype, "setPrevLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", void 0)
    ], VideoVM.prototype, "setNextLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setIsAutoPlayNextVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Program]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "playProgramIntroduceVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "onClickPlayButton", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "onClickExitInnerFullScreen", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "onClickPrev", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "onClickNext", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoVM.prototype, "onLessonComplete", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoVM.prototype, "onLessonSuspend", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "onFullscreenCancellation", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "onLessonSeeked", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setPlayingProgram", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Lesson]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setPlayingLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setCourseLessonID", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setIsFirstLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setIsLastLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "addClass", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "removeClass", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setIsPaused", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "setVideo", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoVM.prototype, "playWithTimeout", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], VideoVM.prototype, "play", null);
    return VideoVM;
}());
export { VideoVM };
