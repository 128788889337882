import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Input } from '../atoms';
import { Colors } from '../styles';
import { SearchIcon } from '../atoms/icons/search_icon';
import { MealSelectButtons } from '../molecules/meal_select_buttons';
import { MealMenuList } from './meal_menu_list';
import { MealHistoryEmptyState } from '../molecules/meal_history_empty_state';
import { MealMenuSearchEmptyState } from '../molecules/meal_menu_search_empty_state';
import { RecordMealActionType } from './record_meal';
import { APISequences } from '@lean-body/src/interfaces';
var SEARCH_DEBOUNCE_MS = 300;
export var RecordMealSelectForm = function (_a) {
    var selectedMenus = _a.selectedMenus, mealType = _a.mealType, searchValue = _a.searchValue, setSearchValue = _a.setSearchValue, onMealTypeChange = _a.onMealTypeChange, onClickSelectMenu = _a.onClickSelectMenu, onClickDeselectMenu = _a.onClickDeselectMenu, onClickMenu = _a.onClickMenu, classObject = _a.classObject, apiClient = _a.apiClient, config = _a.config;
    var _b = React.useState([]), mealMenus = _b[0], setMealMenus = _b[1];
    var _c = React.useState(''), calomealErrMessage = _c[0], setCalomealErrMessage = _c[1];
    React.useEffect(function () {
        onSearch(searchValue);
    }, []);
    React.useEffect(function () {
        if (!searchValue) {
            apiClient.appendToSequence(APISequences.MEAL_MENUS).then(function (next) {
                apiClient.fetchMealHistories(mealType).then(setMealMenus).finally(next);
            });
        }
    }, [searchValue, mealType]);
    var onSearchInputChange = function (event) {
        setSearchValue(event.target.value);
    };
    var onSearchInputChangeDebounce = function (event) {
        var inputValue = event.target.value;
        onSearch(inputValue);
    };
    var onSearch = function (value) {
        if (value) {
            apiClient.appendToSequence(APISequences.MEAL_MENUS).then(function (next) {
                apiClient
                    .fetchMealMenus(value)
                    .then(function (v) {
                    setMealMenus(v.mealMenu);
                    setCalomealErrMessage(v.error);
                })
                    .finally(next);
            });
        }
    };
    var onSearchInputClear = function () {
        setSearchValue('');
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        !config.isProduction && calomealErrMessage != '' && (React.createElement("div", { className: css(styles.calomealErr) }, calomealErrMessage)),
        React.createElement("div", { className: css(styles.searchInputGroup) },
            React.createElement(Input, { value: searchValue, placeholder: "\u4F8B\uFF09\u3054\u306F\u3093", hideSpinner: true, classObject: styles.searchInput, closeIconContainerClassObject: styles.searchClearBtn, onChange: onSearchInputChange, onClickClearBtn: onSearchInputClear, debounceMs: SEARCH_DEBOUNCE_MS, onChangeDebounce: onSearchInputChangeDebounce }),
            React.createElement(SearchIcon, { classObject: styles.searchIcon, color: Colors.Black })),
        !searchValue && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u5C65\u6B74"),
            React.createElement(MealSelectButtons, { selected: mealType, onClick: onMealTypeChange, classObject: styles.mealSelectButtons }))),
        mealMenus.length > 0 ? (React.createElement(MealMenuList, { action: RecordMealActionType.select, mealMenus: mealMenus, selectedMenus: selectedMenus, onClickMenu: onClickMenu, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu })) : searchValue ? (React.createElement(MealMenuSearchEmptyState, { classObject: styles.mealMenuEmptyState })) : (React.createElement(MealHistoryEmptyState, { classObject: styles.mealMenuEmptyState }))));
};
var styles = StyleSheet.create({
    container: {},
    calomealErr: {
        color: Colors.Red,
        fontSize: 14,
        padding: '0 10px 10px',
    },
    searchInputGroup: {
        position: 'relative',
    },
    searchIcon: {
        width: 20,
        height: 20,
        position: 'absolute',
        top: 15,
        left: 12,
    },
    searchInput: {
        paddingLeft: 32,
    },
    searchClearBtn: {
        width: 16,
        height: 16,
        top: 17,
        right: 17,
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
        marginTop: 24,
        marginBottom: 8,
    },
    mealSelectButtons: {
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    mealMenuEmptyState: {
        marginTop: 70,
    },
});
