import * as React from 'react';
import { Vimeo } from '../organisms/vimeo';
import { isiOSApp } from '@lean-body/src/util';
import { useNavigate } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { LiveLessonThumbnail } from '@lean-body/components/molecules';
export var LiveLessonVimeo = function (_a) {
    var vm = _a.vm;
    var tracker = vm.tracker, accountService = vm.accountService, id = vm.id, apiClient = vm.apiClient;
    var navigate = useNavigate();
    var _b = React.useState(null), me = _b[0], setMe = _b[1];
    var _c = React.useState(), liveLesson = _c[0], setLiveLesson = _c[1];
    React.useEffect(function () {
        var isMounted = true;
        // ios専用ページなので、iosじゃない場合リダイレクト
        if (!isiOSApp()) {
            navigate(routes.LIVE_LESSON.compile({ id: id.toString() }));
        }
        else {
            Promise.all([apiClient.fetchLiveLesson(id), accountService.fetchMe()]).then(function (_a) {
                var resLiveLesson = _a[0], resMe = _a[1];
                if (isMounted) {
                    setMe(resMe);
                    setLiveLesson(resLiveLesson);
                }
            });
        }
        return function () {
            isMounted = false;
        };
    }, []);
    if (!liveLesson)
        return null;
    return (React.createElement(React.Fragment, null, liveLesson.isWatchable ? (React.createElement(Vimeo, { liveLesson: liveLesson, finishLiveLessonWatchingRecord: apiClient.finishLiveLessonWatchingRecord, startLiveLessonWatchingRecord: apiClient.startLiveLessonWatchingRecord, syncLiveLessonWatchingRecord: apiClient.syncLiveLessonWatchingRecord, appendToSequence: apiClient.appendToSequence, tracker: tracker, me: me })) : (React.createElement(LiveLessonThumbnail, { src: liveLesson.bannerSpURL, isWatchable: liveLesson.isWatchable }))));
};
var LiveLessonVimeoVM = /** @class */ (function () {
    function LiveLessonVimeoVM(id, accountService, apiClient, tracker) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = id;
        this.accountService = accountService;
        this.apiClient = apiClient;
        this.tracker = tracker;
    }
    return LiveLessonVimeoVM;
}());
export { LiveLessonVimeoVM };
