var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MediaBreakPointDown, MediaBreakPointUp } from '@lean-body/components/styles/variables';
import Player from '@vimeo/player';
import { VIMEO_URL } from '../constants';
import { VIMEO_EVENT_PLAY, VIMEO_EVENT_TIMEUPDATE, } from '../../infra/vimeo';
import { APISequences } from '@lean-body/src/interfaces';
var SYNC_INTERVAL = 60;
export var Vimeo = function (props) {
    var liveLesson = props.liveLesson, tracker = props.tracker, startLiveLessonWatchingRecord = props.startLiveLessonWatchingRecord, syncLiveLessonWatchingRecord = props.syncLiveLessonWatchingRecord, me = props.me;
    var isBeingHeld = liveLesson.isBeingHeld();
    var vimeoPlayerUrl = liveLesson.playerUrl;
    var videoId = vimeoPlayerUrl != null ? vimeoPlayerUrl.replace("".concat(VIMEO_URL, "/"), '') : '';
    var liveLessonWatchingTime = React.useRef(0); // 視聴時間のカウントアップ
    var _a = React.useState(0), previousPlaybackPosition = _a[0], setPreviousPlaybackPosition = _a[1];
    var _b = React.useState(new Map()), liveLessonWatchingRecordMap = _b[0], setLiveLessonWatchingRecordMap = _b[1]; // 重複排除視聴時間計測用map
    var useLessonSessionId = React.useRef(0);
    var checkDuringLesson = function (currentPlaybackPosition) {
        if (liveLesson.isFinished) {
            // アーカイブ再生中は現在の再生位置(currentPlaybackPosition)を計算に使用して、レッスンが行われてない時間はアクティビティに反映させない仕様
            var lessonStartSecond = liveLesson.startsAt - liveLesson.liveStartAt; // レッスンがライブ配信の何秒目に始まるか
            var lessonEndSecond = liveLesson.lessonEndAt - liveLesson.liveStartAt; // レッスンがライブ配信の何秒目に終わるか
            return lessonStartSecond < currentPlaybackPosition && currentPlaybackPosition < lessonEndSecond;
        }
        else {
            // ライブ中は現在時刻から、レッスンが行われてない時間はアクティビティに反映させない仕様
            return isBeingHeld;
        }
    };
    var logLessonRecord = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (liveLessonWatchingRecordMap.size !== 0) {
                return [2 /*return*/, props
                        .finishLiveLessonWatchingRecord(liveLesson.id, liveLessonWatchingTime.current, liveLessonWatchingRecordMap.size, useLessonSessionId.current)
                        .then(function (session) {
                        tracker.trackQuitLiveLesson(me.id, liveLesson.id, vimeoPlayerUrl, session);
                    })];
            }
            return [2 /*return*/];
        });
    }); };
    var finishLiveLessonWatchingRecord = function () {
        logLessonRecord();
    };
    var cleanup = function () {
        props.appendToSequence(APISequences.LESSON_FINISH_AND_PATCH_PROFILE).then(function (next) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, logLessonRecord()];
                    case 1:
                        _a.sent();
                        next();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    var onTimeupdate = function (data) {
        if (!data)
            return;
        var currentPlaybackPosition = Math.floor(data.seconds); // 現在再生している位置（ex: 5秒目）
        var isDuringLesson = checkDuringLesson(currentPlaybackPosition);
        if (isDuringLesson) {
            var session = currentPlaybackPosition - previousPlaybackPosition;
            // 250ms毎に実行されるので、視聴時間のカウントアップを1秒ごとに行いたいため、前回の再生位置から1秒経過した時のみカウントアップする
            if (session === 1) {
                liveLessonWatchingTime.current += session;
                liveLessonWatchingRecordMap.set(currentPlaybackPosition, true); // 再生された箇所を記録したいだけなので、mapのvalueはtrueでなくても構わない
                setLiveLessonWatchingRecordMap(liveLessonWatchingRecordMap);
                if (liveLessonWatchingTime.current % SYNC_INTERVAL === 0) {
                    syncLiveLessonWatchingRecord(liveLesson.id, liveLessonWatchingTime.current, liveLessonWatchingRecordMap.size, useLessonSessionId.current);
                }
            }
        }
        setPreviousPlaybackPosition(currentPlaybackPosition);
    };
    // ブラウザ閉じるのを監視
    window.onbeforeunload = finishLiveLessonWatchingRecord;
    // iOSがonbeforeunloadやReactのunmountを検知できないので、globalにmethodを定義しています
    window.finishLiveLessonWatchingRecord = finishLiveLessonWatchingRecord;
    useEffect(function () {
        // vimeoの動画プレイヤーの仕様は https://developer.vimeo.com/player/sdk/reference を参照
        // iframeがレンダリングされてからDOMを取得したいためuseEffect内で実行
        var iframe = document.getElementById(videoId);
        var player = new Player(iframe);
        // iframeの再生ボタン押下時に発火
        player.on(VIMEO_EVENT_PLAY, function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var title;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, player.getVideoTitle()];
                    case 1:
                        title = _a.sent();
                        if (data) {
                            tracker.trackPlayLiveVideo(vimeoPlayerUrl, title, data.seconds);
                            tracker.trackStartLiveLesson(me.id, liveLesson.id, vimeoPlayerUrl);
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        startLiveLessonWatchingRecord(liveLesson.id).then(function (v) {
            useLessonSessionId.current = v.id;
        });
        return cleanup;
    }, []);
    useEffect(function () {
        var iframe = document.getElementById(videoId);
        var player = new Player(iframe);
        player.off(VIMEO_EVENT_TIMEUPDATE);
        player.on(VIMEO_EVENT_TIMEUPDATE, onTimeupdate); // 動画が再生されている時のハンドリング(250msずつ実行される)
    }, [previousPlaybackPosition]);
    return (React.createElement("div", { className: css(styles.embedContainer) },
        React.createElement("iframe", { id: videoId, className: css(styles.iframe), src: vimeoPlayerUrl, frameBorder: "0", allow: "autoplay; fullscreen", allowFullScreen: true })));
};
var styles = StyleSheet.create({
    embedContainer: (_a = {},
        _a[MediaBreakPointDown.SM] = {
            width: '100%',
            position: 'relative',
            top: '-2px',
            margin: '0 auto',
        },
        _a[MediaBreakPointUp.XL] = {
            marginBottom: 24,
        },
        _a.padding = '56.25% 0 0 0',
        _a.position = 'relative',
        _a),
    iframe: {
        height: '100%',
        left: '0',
        position: 'absolute',
        top: '0',
        width: '100%',
    },
});
