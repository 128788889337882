import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useScript } from '@lean-body/src/util';
import { SessionStorage } from '@lean-body/src/infra';
var BUTTON_COLOR = 'Gold';
var PRODUCT_TYPE = 'PayOnly';
var PLACEMENT = 'Checkout';
var LEDGER_CURRENCY = 'JPY';
var CHECKOUT_LANGUAGE = 'ja_JP';
// propsのplanが変更された時だけ実行したいので、React.memoを使用
export var AmazonPayButton = React.memo(function (_a) {
    var classObject = _a.classObject, plan = _a.plan, config = _a.config, onClick = _a.onClick, disabled = _a.disabled;
    var sessionStorage = new SessionStorage();
    var elementId = "AmazonPayButton-PlanId-".concat(plan.id);
    var buttonPayload = {
        createCheckoutSessionConfig: {
            payloadJSON: plan.payload,
            signature: plan.signature,
            publicKeyId: config.amazonPayPublicKeyId,
        },
    };
    var _b = React.useState(null), amazonPayButton = _b[0], setAmazonPayButton = _b[1];
    React.useEffect(function () {
        if (amazonPayButton) {
            amazonPayButton.onClick(function () { return amazonPayButton.initCheckout(buttonPayload); });
        }
    }, [plan]);
    useScript('https://static-fe.payments-amazon.com/checkout.js', function () {
        var button = window.amazon.Pay.renderButton("#".concat(elementId), {
            merchantId: config.amazonPayMerchantId,
            ledgerCurrency: LEDGER_CURRENCY, // JPY
            sandbox: !config.isProduction,
            checkoutLanguage: CHECKOUT_LANGUAGE,
            productType: PRODUCT_TYPE, // PayOnly: デジタルサービス(物理なし)のタイプ
            placement: PLACEMENT, // AmazonPayボタンの配置場所(厳密な意図は不明)
            buttonColor: BUTTON_COLOR,
        });
        setAmazonPayButton(button);
        button.onClick(function () { return button.initCheckout(buttonPayload); });
    });
    var onClickHandler = function () {
        sessionStorage.setAmazonPayParams(plan.id);
        onClick && onClick();
    };
    var onClickDisableContainer = function (e) {
        e.stopPropagation();
        onClick && onClick();
    };
    return (React.createElement("div", { className: css(styles.container, classObject), onClick: onClickHandler },
        React.createElement("div", { id: elementId, className: css(styles.button) }),
        disabled && React.createElement("div", { className: css(styles.disableContainer), onClick: onClickDisableContainer })));
});
var styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    button: {
        height: 65,
        width: '100%',
    },
    disableContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
});
