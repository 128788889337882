export var MealType = {
    all: 'all',
    breakfast: 'breakfast',
    lunch: 'lunch',
    dinner: 'dinner',
    snacking: 'snacking',
};
export var MealLabel = {
    all: '全て',
    breakfast: '朝食',
    lunch: '昼食',
    dinner: '夕食',
    snacking: '間食',
};
var MealMenu = /** @class */ (function () {
    function MealMenu(data) {
        Object.defineProperty(this, "menuId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "menuName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "storeName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "imageUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calorie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "protein", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lipid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "carbohydrate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "saltAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fiber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calcium", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "iron", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminA", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminB1", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminB2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminD", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitaminE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cholesterol", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.menuId = data.menuId;
        this.menuName = data.menuName;
        this.storeName = data.storeName;
        this.description = data.description;
        this.imageUrl = data.imageUrl;
        this.amount = data.amount;
        this.calorie = data.calorie;
        this.protein = data.protein;
        this.lipid = data.lipid;
        this.carbohydrate = data.carbohydrate;
        this.saltAmount = data.saltAmount;
        this.fiber = data.fiber;
        this.calcium = data.calcium;
        this.iron = data.iron;
        this.vitaminA = data.vitaminA;
        this.vitaminB1 = data.vitaminB1;
        this.vitaminB2 = data.vitaminB2;
        this.vitaminC = data.vitaminC;
        this.vitaminD = data.vitaminD;
        this.vitaminE = data.vitaminE;
        this.cholesterol = data.cholesterol;
    }
    Object.defineProperty(MealMenu.prototype, "nutrientSummary", {
        get: function () {
            return [
                { key: 'protein', name: 'たんぱく質', value: this.protein },
                { key: 'lipid', name: '脂質', value: this.lipid },
                { key: 'carbohydrate', name: '炭水化物', value: this.carbohydrate },
                { key: 'saltAmount', name: '塩分', value: this.saltAmount },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MealMenu.prototype, "caloriesForQuantity", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (quantityPercentage) {
            return (this.calorie * quantityPercentage) / 100;
        }
    });
    return MealMenu;
}());
export { MealMenu };
var UserMeal = /** @class */ (function () {
    function UserMeal(data) {
        var _a;
        Object.defineProperty(this, "mealType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "eatenAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isNotEating", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userMealMenus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.mealType = data.mealType;
        this.eatenAt = new Date(data.eatenAt);
        this.isNotEating = data.isNotEating;
        this.userMealMenus = ((_a = data.userMealMenus) === null || _a === void 0 ? void 0 : _a.map(function (mealMenuData) { return new MealMenu(mealMenuData); })) || [];
    }
    return UserMeal;
}());
export { UserMeal };
