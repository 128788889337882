import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Button } from '@lean-body/components/atoms';
import { Colors } from '@lean-body/components/styles';
import { AmazonPayButton } from '../atoms/amazon_pay_button';
import { CCFormSections } from '@lean-body/components/molecules';
export var CCFormButton = function (_a) {
    var buttonType = _a.buttonType, disabled = _a.disabled, loading = _a.loading, submitLabel = _a.submitLabel, basePlan = _a.basePlan, onClick = _a.onClick, campaign = _a.campaign, dataTest = _a.dataTest, config = _a.config, selectedSection = _a.selectedSection, signedInAmazonPay = _a.signedInAmazonPay;
    var plan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.plan;
    var amazonPayPlan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.amazonPayPlan;
    var planName = (campaign === null || campaign === void 0 ? void 0 : campaign.isFriendInvitation) ? campaign.name : plan === null || plan === void 0 ? void 0 : plan.name;
    return (React.createElement("div", { className: css(styles.container) },
        selectedSection === CCFormSections.stripe || signedInAmazonPay ? (React.createElement(Button, { buttonType: buttonType, disabled: disabled, loading: loading, dataTest: dataTest || 'submitCCForm', fullWidth: true, className: css(styles.button), onClick: onClick }, submitLabel)) : (selectedSection === CCFormSections.amazonPay &&
            amazonPayPlan && React.createElement(AmazonPayButton, { plan: amazonPayPlan, config: config, classObject: styles.amazonPayButton })),
        (plan === null || plan === void 0 ? void 0 : plan.hasFreeTrial()) && (React.createElement("div", { className: css(styles.amount) },
            "\u7121\u6599\u4F53\u9A13\u7D42\u4E86\u5F8C ",
            planName,
            " ",
            plan.amountWithoutTax.toLocaleString(),
            "\u5186\uFF08\u7A0E\u8FBC",
            plan.amount.toLocaleString(),
            "\u5186\uFF09\u306E\u3054\u767B\u9332",
            React.createElement("br", null),
            plan.isAnnualPlan() && (React.createElement(React.Fragment, null,
                "1\u30F6\u6708\u3042\u305F\u308A",
                plan.amountPerMonthWithoutTax.toLocaleString(),
                "\u5186/\u6708\uFF08\u7A0E\u8FBC",
                plan.amountPerMonth.toLocaleString(),
                "\u5186/\u6708\uFF09\u76F8\u5F53"))))));
};
var styles = StyleSheet.create({
    container: {
        marginTop: 12,
    },
    button: {
        fontSize: 16,
    },
    amount: {
        fontSize: 12,
        fontWeight: 'normal',
        color: Colors.Black,
        margin: '12px auto 0',
        textAlign: 'center',
    },
    amazonPayButton: {
        marginTop: 14,
    },
});
