var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { observer } from 'mobx-react';
import { Live } from '@lean-body/components/organisms';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
export var LivePage = observer(/** @class */ (function (_super) {
    __extends(LivePage, _super);
    function LivePage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(LivePage.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return (React.createElement("div", { className: css(styles.container) },
                React.createElement(Live, { liveLessonId: this.props.vm.id, accountService: this.props.vm.accountService, apiClient: this.props.vm.apiClient, tracker: this.props.vm.tracker, config: this.props.vm.config })));
        }
    });
    return LivePage;
}(React.Component)));
var LivePageVM = /** @class */ (function () {
    function LivePageVM(id, accountService, apiClient, tracker, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = id;
        this.accountService = accountService;
        this.apiClient = apiClient;
        this.tracker = tracker;
        this.config = config;
    }
    return LivePageVM;
}());
export { LivePageVM };
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.BackgroundLightGray,
    },
});
