var _a, _b, _c, _d, _e, _f;
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { CourseEmptyState, DailyTasksEmptyState } from '@lean-body/components/molecules';
import { HomeCalendarHeader, PageLoading, TodaysCourseLessons } from '@lean-body/components/organisms';
import { Course, CourseDay, DailyTasks, TASK_DATE_QUERY, } from '@lean-body/src/domain';
import { SessionStorage } from '@lean-body/src/infra';
import { DailyTasksPageTaskName, routes } from '@lean-body/src/route';
import { useQuery } from '@lean-body/src/util';
import { css, StyleSheet } from 'aphrodite/no-important';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import { LocalStorage } from '../../infra/local_storage';
import { HOME_SECTION_MARGIN_BOTTOM, ZIndex } from '../constants';
import { Colors, MediaBreakPointUp } from '../styles';
import { DailyTasksList } from '../organisms/daily_tasks_list';
import { DailyTasksProgress } from '../organisms/daily_tasks_progress';
import { WebViewCallbackHandlers } from '@lean-body/src/interfaces';
import { dateIsToday, getFormattedToFormat, getFormattedToMonthDate, sameDay } from '@lean-body/src/util/date';
import { HomeDialogGroup } from '../organisms/home_dialogs';
export var LIST_ITEM_MARGIN_RIGHT = 10;
dayjs.extend(isBetween);
export var HomePage = function (_a) {
    var apiClient = _a.apiClient, twitterService = _a.twitterService, tracker = _a.tracker, config = _a.config, profile = _a.profile, homeDay = _a.homeDay, setHomeDay = _a.setHomeDay;
    var navigate = useNavigate();
    var now = new Date();
    var query = useQuery();
    var queryDate = dayjs(query.get(TASK_DATE_QUERY) || now).toDate();
    var localStorage = new LocalStorage();
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(queryDate), selectedDate = _c[0], setSelectedDate = _c[1];
    var _d = useState(), course = _d[0], setCourse = _d[1];
    var _e = useState(), courseDay = _e[0], setCourseDay = _e[1];
    var _f = useState(), dailyTasks = _f[0], setDailyTasks = _f[1];
    var sessionStorage = new SessionStorage();
    var hasAndroidStepccountPermission = sessionStorage.loadAndroidStepcountPermission();
    var hasRecordedMeal = localStorage.loadHasRecordedMeal;
    var _g = useState(), mealTaskAboutDialogRoute = _g[0], setMealTaskAboutDialogRoute = _g[1];
    useEffect(function () {
        if (!sameDay(homeDay, queryDate))
            setHomeDay(queryDate);
        Promise.all([apiClient.fetchMyCourse(), apiClient.fetchDailyTasks(selectedDate)])
            .then(function (_a) {
            var fetchCourse = _a[0], fetchDailyTasks = _a[1];
            setCourse(fetchCourse);
            setDailyTasks(fetchDailyTasks);
            if (fetchCourse) {
                return apiClient.fetchCourseDay(now).then(function (v) { return setCourseDay(v); });
            }
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, []);
    var onClickCalendarDay = function (date) {
        setHomeDay(date);
        setSelectedDate(date);
        apiClient.fetchDailyTasks(date).then(function (v) { return setDailyTasks(v); });
        navigate("".concat(routes.TOP.compile(), "?date=").concat(getFormattedToFormat(date)));
    };
    var onClickTaskItemAndroid = function (taskName) {
        if (hasAndroidStepccountPermission) {
            navigate(routes.DAILY_TASKS.compile({ taskName: taskName }));
        }
        else {
            window.flutter_inappwebview.callHandler(WebViewCallbackHandlers.requestHealthConnectPermission);
        }
    };
    var onClickCourseEmptyState = function () {
        navigate(routes.MATCHING_ASSESSMENT_INTRO.compile());
    };
    var onClickTaskItem = function (taskName) {
        var route = "".concat(routes.DAILY_TASKS.compile({ taskName: taskName }), "?").concat(TASK_DATE_QUERY, "=").concat(getFormattedToFormat(selectedDate));
        if (taskName === DailyTasksPageTaskName.meal && !hasRecordedMeal) {
            setMealTaskAboutDialogRoute(route);
        }
        else {
            navigate(route);
        }
    };
    var onClickSubtask = function (taskName, subtaskName) {
        var route = "".concat(routes.DAILY_TASKS_SUBTASK.compile({ taskName: taskName, subtaskName: subtaskName }), "?").concat(TASK_DATE_QUERY, "=").concat(getFormattedToFormat(selectedDate));
        if (taskName === DailyTasksPageTaskName.meal && !hasRecordedMeal) {
            setMealTaskAboutDialogRoute(route);
        }
        else {
            navigate(route);
        }
    };
    var onClickDailyTasksEmptyState = function () {
        navigate(routes.GOAL_MATCHING_ASSESSMENT.compile());
    };
    if (isLoading) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(HomeDialogGroup, { mealTaskAboutDialogRoute: mealTaskAboutDialogRoute, profile: profile, twitterService: twitterService, apiClient: apiClient, config: config }),
        React.createElement(HomeCalendarHeader, { apiClient: apiClient, classObject: styles.courseCalendar, tracker: tracker, selectedDate: selectedDate, onClickDay: onClickCalendarDay }),
        React.createElement("div", { className: css(styles.content) },
            React.createElement("div", { className: css(styles.contentInnerSection, styles.todaysCourseLessonsContentInnerSection) },
                React.createElement(TodaysCourseLessons, { course: course ? course : Course.sampleCourse(config), courseDay: course ? courseDay : CourseDay.sampleCourseDay(config), isSampleCourse: !course, apiClient: apiClient, classObject: styles.courseContainer, tracker: tracker }),
                !course && React.createElement(CourseEmptyState, { onClick: onClickCourseEmptyState, classObject: styles.courseEmptyState })),
            dailyTasks && (React.createElement("div", { className: css(styles.contentInnerSection) },
                React.createElement("div", { className: css(styles.sectionTitle) },
                    dateIsToday(selectedDate) ? '今日' : getFormattedToMonthDate(selectedDate),
                    "\u306E\u30BF\u30B9\u30AF",
                    dailyTasks.isGoalsSet && React.createElement(DailyTasksProgress, { dailyTasks: dailyTasks })),
                React.createElement(DailyTasksList, { showMealTaskBadge: !hasRecordedMeal, dailyTasks: dailyTasks.isGoalsSet ? dailyTasks : DailyTasks.sampleDailyTasks(), onClickAndroidTaskItem: onClickTaskItemAndroid, onClickTaskItem: onClickTaskItem, onClickSubtask: onClickSubtask, classObject: styles.tasksContainer, hasAndroidStepccountPermission: hasAndroidStepccountPermission }),
                !dailyTasks.isGoalsSet && React.createElement(DailyTasksEmptyState, { onClick: onClickDailyTasksEmptyState }))))));
};
var styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
    },
    content: (_a = {
            minHeight: '100vh'
        },
        _a[MediaBreakPointUp.MD] = {
            marginRight: 0,
        },
        _a),
    courseCalendar: {},
    sectionTitle: (_b = {
            fontSize: 16,
            fontWeight: 'bold',
            margin: '0 20px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        _b[MediaBreakPointUp.MD] = {
            fontSize: 18,
            margin: '0 48px 10px',
        },
        _b),
    contentInnerSection: {
        position: 'relative',
        marginBottom: HOME_SECTION_MARGIN_BOTTOM,
    },
    todaysCourseLessonsContentInnerSection: (_c = {
            paddingTop: 20
        },
        _c[MediaBreakPointUp.MD] = {
            paddingTop: 30,
        },
        _c),
    tasksContainer: (_d = {
            marginLeft: 20,
            marginRight: 20
        },
        _d[MediaBreakPointUp.MD] = {
            marginLeft: 48,
            marginRight: 48,
        },
        _d),
    courseContainer: (_e = {
            marginLeft: 20,
            marginRight: 20
        },
        _e[MediaBreakPointUp.MD] = {
            marginLeft: 48,
            marginRight: 48,
        },
        _e),
    fixedBox: {
        borderRadius: 10,
        border: "1px solid ".concat(Colors.Gray),
        boxShadow: '0px 15px 30px rgba(0, 180, 199, 0.08)',
        width: 458,
    },
    homeFixedContainer: {
        position: 'fixed',
        right: 20,
        bottom: 30,
        zIndex: ZIndex.HomePositionFixed,
    },
    copyRight: (_f = {
            fontSize: 12,
            color: Colors.GrayDarkest,
            textAlign: 'center',
            padding: '20px 0'
        },
        _f[MediaBreakPointUp.XL] = {
            fontSize: 14,
            padding: '40px 0',
        },
        _f),
    courseEmptyState: {
        position: 'absolute',
        overflow: 'hidden',
        top: 1,
        left: 1,
        background: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(5px)',
    },
});
