/*------ フォーム入力系 ------*/
export var INVALID_INPUT = '入力内容が間違っています';
export var EMAIL_EMPTY = 'メールアドレスを入力してください';
export var EMAIL_INVALID = '不正なメールアドレスです';
export var EMAIL_CHANGE_SUCCEEDED = '新しいメールアドレスに確認メールをお送りしました';
export var EMAIL_CHANGE_FAILED = 'メールアドレスの変更に失敗しました';
export var EMAIL_VERIFICATION_SUCCEEDED = 'メールアドレスを確認しました';
export var EMAIL_VERIFICATION_FAILED = 'メールアドレスを確認できませんでした';
export var FAILD_TO_GET_ME = 'ユーザー情報取得に失敗しました';
export var PASSWORD_EMPTY = 'パスワードを入力してください';
export var NEW_PASSWORD_EMPTY = '新しいパスワードを入力してください';
export var NEW_PASSWORD_CONFIRM_EMPTY = '新しいパスワード(確認)を入力してください';
export var NEW_PASSWORD_DOESNT_MATCH = '新しいパスワードと新しいパスワード(確認)が一致しません';
export var CUR_PASSWORD_EMPTY = '現在のパスワード入力してください';
export var PASSWORD_CHANGE_SUCCEEDED = 'パスワードを変更しました';
export var PASSWORD_CHANGE_FAILED = 'パスワードの変更に失敗しました';
export var PASSWORD_LENGTH = 'パスワードは6文字以上の英数字で入力してください';
export var PASSWORD_CONFIRM_ERR = 'パスワードが一致しません';
export var BODY_EMPTY = '本文を入力してください';
export var INQUIRY_POST_SUCCEEDED = 'お問い合わせを送信しました';
export var INQUIRY_POST_FAILED = 'お問い合わせの送信に失敗しました';
export var MATCHING_CHOICE_UNSELECTED = 'どれか一つを選択してください';
export var MATCHING_FAILED = '失敗しました、最初からやり直してください';
export var DATE_INVALID = '存在しない日付が入力されています。';
/*------ メアド登録 ------*/
export var SIGNUP_SUCCEEDED = '登録メールアドレスに確認メールをお送りしました';
export var SIGNUP_FAILED = '会員登録に失敗しました';
/*------ クレカ登録系 ------*/
export var CC_REGISTER_FAILED = 'クレジットカードの登録に失敗しました';
export var CC_REGISTER_PROMPT = 'まだクレジットカードが登録されていません';
export var CC_CHANGE_SUCCEEDED = 'クレジットカードを変更しました';
export var CC_CHANGE_FAILED = 'クレジットカードの変更に失敗しました';
export var CC_NUMBER_EMPTY = 'カード番号を入力してください';
export var CC_EXP_MONTH_EMPTY = '有効期限(月)を入力してください';
export var CC_EXP_YEAR_EMPTY = '有効期限(年)を入力してください';
export var CC_CVC_EMPTY = 'セキュリティコードを入力してください';
export var POLICY_NOT_CHECKED = '利用規約に同意してください';
export var COACHING_PLAN_PAYMENT_FAILED = 'コーチングプランの決済に失敗しました';
export var COACHING_PLAN_PAYMENT_FAILED_BECAUSE_USER_QUIT = '退会手続き済みのためフィットライフコーチサービスを開始することができません。';
export var UNSUBSCRIBE_COACHING_PLAN_FAILED = 'コーチングプランの停止に失敗しました';
/*------ プラン系 ------*/
export var PLAN_INDEX_FAILED = 'プラン一覧取得に失敗しました';
export var CAMPAIGN_CODE_CHECK_FAILED = 'クーポンコードの判定処理に失敗しました';
export var CAMPAIGN_CODE_NOT_FOUND = 'クーポンコードが不正です';
export var PLAN_RESET_SUCCEEDED = 'プラン変更を取り消しました';
export var PLAN_RESET_FAILED = 'プラン変更の取り消しに失敗しました';
export var PLAN_UNSELECTED = 'プランを選択してください';
export var PLAN_UNREGISTERED = 'プランがまだ登録されてないので選択してください';
/*------ プログラム選択 ------*/
export var PROGRAM_SELECTION_SUCCEEDED = 'プログラムを選択しました';
export var PROGRAM_SELECTION_FAILED = 'プログラムの選択に失敗しました';
/*------ ログイン系 ------*/
export var SIGNIN_FAILED = 'ログインに失敗しました';
export var SIGNIN_SUCCEEDED = 'ログインしました';
export var FB_SIGNIN_SUCCEEDED = 'Facebookアカウントでログインしました';
export var FB_SIGNUP_SUCCEEDED = 'Facebookアカウントで登録しました';
export var FB_SIGNIN_FAILED = 'Facebookアカウントでログインに失敗しました';
export var SIGNOUT_SUCCEEDED = 'ログアウトしました';
export var LINE_CONNECT_SUCCEEDED = 'LINEアカウントを連携しました';
export var LINE_CONNECT_FAILED = 'LINEアカウントの連携に失敗しました';
export var LINE_DISCONNECT_SUCCEEDED = 'LINEアカウントの連携を解除しました';
export var LINE_DISCONNECT_FAILED = 'LINEアカウントの連携解除に失敗しました';
export var APPLE_SIGNIN_SUCCEEDED = 'Appleアカウントでログインしました';
export var APPLE_SIGNIN_FAILED = 'Appleアカウントでのログインに失敗しました';
/*------ 退会処理系 ------*/
export var CANCEL_MEMBERSHIP_SUCCEEDED = '退会処理が完了しました。';
export var CANCEL_MEMBERSHIP_FAILED = '退会処理中にエラーが発生しました。\nお手数ですがサポートにお問い合わせください。';
export var DORMANT_MEMBERSHIP_SUCCEEDED = '休眠会員処理が完了しました。';
export var DORMANT_MEMBERSHIP_FAILED = '休眠会員処理中にエラーが発生しました。\nお手数ですがサポートにお問い合わせください。';
export var ALREADY_DORMANT = 'あなたのアカウントは休眠アカウントになっています。';
export var ACCOUNT_ACTIVATED = 'おかえりなさい！一般会員に復旧しました。';
export var ACTIVATE_FAILED = '一般会員への復旧に失敗しました。';
export var ALREADY_UNSUBSCRIBED = 'すでに退会済みです。今回支払い分の期間まで、引き続きサービスをご利用いただけます。';
export var DESTROY_ACCOUNT_SUCCEEDED = 'アカウト削除が完了しました。';
export var DESTROY_ACCOUNT_FAILED = 'アカウント削除中に。\nお手数ですがサポートにお問い合わせください。';
/*------ その他 ------*/
export var ACCOUNT_EXPIRED = 'ご登録アカウントの有効期限が切れています';
