var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { LocalStorage } from '@lean-body/src/infra';
import { getFormattedToFormat, getFormattedToMonthDateDay, useNavigateBack, useSmMediaQuery } from '@lean-body/src/util';
import { Button, ButtonType, CloseIcon } from '../atoms';
import { Colors, MediaBreakPointUp, Sizes } from '../styles';
import { MealLabel } from '@lean-body/src/domain';
import { Dialog } from '../molecules';
import { MealHeader } from '../atoms/meal_header';
import { RecordMealMenuDetail } from './record_meal_menu_detail';
import { ExitConfirmationDialog } from '../molecules/exit_confirmation_dialog';
import { MealRecordSheet } from '../molecules/meal_record_sheet';
import { RecordMealSelectForm } from './record_meal_select_form';
import { RecordMealConfirmForm } from './record_meal_confirm_form';
export var RecordMealActionType = {
    select: 'select',
    confirm: 'confirm',
};
export var MENU_QUANTITY_PERCENT_FULL = 100;
var MealDefaultTime = {
    breakfast: '07:00',
    lunch: '12:00',
    dinner: '19:00',
    snacking: '15:00',
};
export var RecordMeal = function (_a) {
    var date = _a.date, name = _a.name, mealTask = _a.mealTask, classObject = _a.classObject, apiClient = _a.apiClient, config = _a.config;
    var navigateBack = useNavigateBack();
    var localStorage = new LocalStorage();
    var isSM = useSmMediaQuery();
    var _b = React.useState(RecordMealActionType.select), action = _b[0], setAction = _b[1];
    var _c = React.useState(name), mealType = _c[0], setMealType = _c[1];
    var _d = React.useState(false), isChangedMenu = _d[0], setIsChangedMenu = _d[1];
    var _e = React.useState(new Map()), selectedMenus = _e[0], setSelectedMenus = _e[1];
    var _f = React.useState(null), menuSelection = _f[0], setMenuSelection = _f[1];
    var _g = React.useState(), menuQuantity = _g[0], setMenuQuantity = _g[1];
    var _h = React.useState(false), showExitDialog = _h[0], setShowExitDialog = _h[1];
    var _j = React.useState(MealDefaultTime[name]), time = _j[0], setTime = _j[1];
    var _k = React.useState(false), showErrorDialog = _k[0], setShowErrorDialog = _k[1];
    var _l = React.useState(''), searchValue = _l[0], setSearchValue = _l[1];
    React.useEffect(function () {
        // 以前保存した内容をselectedMenusに保管する
        apiClient.getMeal(getFormattedToFormat(date), name).then(function (userMeal) {
            var menuSelectionMap = new Map(userMeal === null || userMeal === void 0 ? void 0 : userMeal.userMealMenus.map(function (menu) { return [menu.menuId, { menu: menu, quantity: menu.amount * 100 }]; }));
            setSelectedMenus(menuSelectionMap);
            if (userMeal && !userMeal.isNotEating) {
                setAction(RecordMealActionType.confirm);
            }
        });
    }, []);
    var onClickBack = function () {
        if (menuSelection) {
            setMenuSelection(null);
        }
        else {
            if (isChangedMenu) {
                setShowExitDialog(true);
            }
            else {
                navigateBack();
            }
        }
    };
    var onClickAddMenu = function () {
        setAction(RecordMealActionType.select);
    };
    var onCloseExitDialog = function () {
        setShowExitDialog(false);
    };
    var onClickMenu = function (menu) {
        var _a;
        var detailMenu = (_a = selectedMenus.get(menu.menuId)) !== null && _a !== void 0 ? _a : { menu: menu, quantity: MENU_QUANTITY_PERCENT_FULL };
        setMenuSelection(detailMenu);
        setMenuQuantity(detailMenu.quantity.toString());
    };
    var onClickSelectMenu = function (menu) {
        setSelectedMenus(new Map(selectedMenus.set(menu.menuId, { menu: menu, quantity: MENU_QUANTITY_PERCENT_FULL })));
        setIsChangedMenu(true);
    };
    var onClickDeselectMenu = function (menuId) {
        selectedMenus.delete(menuId);
        setSelectedMenus(new Map(selectedMenus));
        setIsChangedMenu(true);
        if (selectedMenus.size === 0 && action === RecordMealActionType.confirm) {
            setAction(RecordMealActionType.select);
        }
    };
    var onClickSkipMeal = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isFirstMeal;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.skipMeal(mealType, getFormattedToFormat(date))];
                case 1:
                    _a.sent();
                    isFirstMeal = mealTask.eatenMealsCount === 0;
                    if (isFirstMeal) {
                        localStorage.saveShowMealTaskNotificationPermissionDialog(true);
                    }
                    navigateBack();
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        var menus, isFirstMeal;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedMenus.size) {
                        return [2 /*return*/];
                    }
                    if (!(action === RecordMealActionType.select)) return [3 /*break*/, 1];
                    setAction(RecordMealActionType.confirm);
                    return [3 /*break*/, 3];
                case 1:
                    menus = Array.from(selectedMenus.entries()).map(function (_a) {
                        var menuId = _a[0], quantity = _a[1].quantity;
                        return ({
                            id: menuId,
                            amount: quantity / 100,
                        });
                    });
                    return [4 /*yield*/, apiClient.saveMeal(getFormattedToFormat(date), time, name, menus)];
                case 2:
                    _a.sent();
                    isFirstMeal = mealTask.eatenMealsCount === 0;
                    if (isFirstMeal) {
                        localStorage.saveShowMealTaskNotificationPermissionDialog(true);
                    }
                    // 食事を記録したことあるか保存する
                    localStorage.saveHasRecordedMeal(true);
                    navigateBack();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onClickSaveQuantity = function () {
        var quantity = menuQuantity ? parseFloat(menuQuantity) : MENU_QUANTITY_PERCENT_FULL;
        if (quantity <= 0) {
            setShowErrorDialog(true);
            return;
        }
        setSelectedMenus(new Map(selectedMenus.set(menuSelection.menu.menuId, { menu: menuSelection.menu, quantity: quantity })));
        setMenuSelection(null);
        setIsChangedMenu(true);
    };
    var onCloseErrorDialog = function () {
        setShowErrorDialog(false);
    };
    return (React.createElement(React.Fragment, null,
        menuSelection && isSM ? (React.createElement("div", { className: css(classObject, styles.container) },
            React.createElement(MealHeader, { onClickBack: onClickBack },
                React.createElement("div", { className: css(styles.saveButton), onClick: onClickSaveQuantity }, "\u8A18\u9332")),
            React.createElement("div", { className: css(styles.contents) },
                React.createElement(RecordMealMenuDetail, { menu: menuSelection.menu, quantity: menuQuantity, onQuantityInputChange: setMenuQuantity })))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(classObject, styles.container) },
                React.createElement(MealHeader, { onClickBack: onClickBack },
                    React.createElement("div", null, "".concat(getFormattedToMonthDateDay(date), " ").concat(MealLabel[name]))),
                React.createElement("div", { className: css(styles.contents) }, action === RecordMealActionType.select ? (React.createElement(RecordMealSelectForm, { mealType: mealType, selectedMenus: selectedMenus, searchValue: searchValue, setSearchValue: setSearchValue, onMealTypeChange: setMealType, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu, onClickMenu: onClickMenu, apiClient: apiClient, config: config })) : (React.createElement(RecordMealConfirmForm, { selectedMenus: selectedMenus, time: time, onTimeChange: setTime, onClickDeselectMenu: onClickDeselectMenu, onClickMenu: onClickMenu, onClickAddMenu: onClickAddMenu }))),
                React.createElement(MealRecordSheet, { action: action, selectedMenus: selectedMenus, onClickSkipMeal: onClickSkipMeal, onClickConfirm: onClickConfirm }),
                showExitDialog && (React.createElement(ExitConfirmationDialog, { description: "\u7DE8\u96C6\u4E2D\u306E\u767B\u9332\u5185\u5BB9\u306F\u7834\u68C4\u3055\u308C\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F", exitButtonMessage: "\u7834\u68C4\u3057\u3066\u7D42\u4E86", cancelButtonMessage: "\u767B\u9332\u3092\u7D9A\u3051\u308B", onClickExit: navigateBack, onClickCancel: onCloseExitDialog }))),
            menuSelection && (React.createElement(Dialog, { classObject: styles.detailDialog, onClickCancel: onClickBack, content: React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css(styles.detailDialogHeader) },
                        React.createElement(CloseIcon, { classObject: styles.detailCloseButton, color: Colors.Black, onClick: onClickBack }),
                        React.createElement("div", { className: css(styles.saveButton), onClick: onClickSaveQuantity }, "\u8A18\u9332")),
                    React.createElement(RecordMealMenuDetail, { menu: menuSelection.menu, quantity: menuQuantity, onQuantityInputChange: setMenuQuantity, classObject: styles.recordMealDetail })) })))),
        showErrorDialog && (React.createElement(Dialog, { classObject: styles.errorDialog, onClickCancel: onCloseErrorDialog, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.errorMessage) }, "0\u3088\u308A\u5927\u304D\u3044\u6570\u5024\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
                React.createElement(Button, { className: css(styles.errorDialogCloseButton), buttonType: ButtonType.primary, onClick: onCloseErrorDialog }, "OK")) }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            height: "calc(100vh - ".concat(Sizes.MobileHeaderHeight, "px)"),
            display: 'flex',
            flexDirection: 'column'
        },
        _a[MediaBreakPointUp.MD] = {
            height: '100vh',
        },
        _a),
    contents: (_b = {
            flex: 1,
            marginTop: 8,
            padding: '0 24px'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: 0,
            width: 600,
            margin: '0 auto',
        },
        _b),
    saveButton: {
        color: Colors.PrimaryDarkB,
        cursor: 'pointer',
        position: 'absolute',
        right: 16,
    },
    recordMealDetail: {
        width: 375,
        padding: '0 24px',
        margin: '0 auto',
    },
    detailDialog: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            width: 582,
            height: 556,
        },
        _c.backgroundColor = Colors.White,
        _c),
    detailDialogHeader: {
        width: '100%',
        height: 44,
        display: 'flex',
        alignItems: 'center',
    },
    detailCloseButton: {
        width: 16,
        height: 16,
        position: 'absolute',
        left: 16,
    },
    errorDialog: {
        width: 327,
        padding: '25px 24px 20px',
        borderRadius: 14,
    },
    errorMessage: {
        fontSize: 14,
        letterSpacing: 0.56,
        marginBottom: 20,
        textAlign: 'center',
    },
    errorDialogCloseButton: {
        width: '100%',
        height: 48,
        borderRadius: 24,
        padding: 0,
    },
});
