import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../styles';
import { MealMenuInfo } from '../molecules/meal_menu_info';
import { MenuNutrientsSummary } from '../molecules/menu_nutrients_summary';
import { MENU_QUANTITY_PERCENT_FULL } from './record_meal';
import { Input } from '../atoms';
export var RecordMealMenuDetail = function (_a) {
    var menu = _a.menu, quantity = _a.quantity, classObject = _a.classObject, onQuantityInputChange = _a.onQuantityInputChange;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(MealMenuInfo, { menu: menu, classObject: styles.mealMenuInfo }),
        React.createElement("div", { className: css(styles.sectionTitle) }, "\u98DF\u3079\u305F\u91CF\u3092\u5909\u66F4"),
        React.createElement(Input, { type: "number", value: quantity, placeholder: MENU_QUANTITY_PERCENT_FULL.toString(), hideSpinner: true, unitLabel: "%", onChange: function (e) { return onQuantityInputChange(e.target.value); } }),
        React.createElement("div", { className: css(styles.sectionTitle) }, "\u30E1\u30CB\u30E5\u30FC\u8A73\u7D30"),
        React.createElement("div", { className: css(styles.menuDescription) }, menu.description),
        React.createElement(MenuNutrientsSummary, { menu: menu })));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
        letterSpacing: 1.28,
        marginTop: 24,
        marginBottom: 8,
    },
    mealMenuInfo: {
        padding: '12px 0',
    },
    menuDescription: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginBottom: 8,
        whiteSpace: 'pre-line',
    },
    saveButton: {
        color: Colors.GrayDarkest,
        padding: '10px 16px 10px 6px',
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
    },
    clickableSaveButton: {
        color: Colors.PrimaryDarkB,
    },
});
