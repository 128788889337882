var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AuthStatus } from '@lean-body/src/interfaces';
import { Subject } from 'rxjs';
import * as routes from './routes';
import { FBPixelEventName, LocalStorage } from '@lean-body/src/infra';
import * as msg from '@lean-body/src/messages';
import * as alertGroup from '@lean-body/src/constants/alert_groups';
import { SettingItemKeys } from '../view/organisms/setting_menu';
var RouteController = /** @class */ (function () {
    function RouteController(domainFactory, alertPresenter, config) {
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "curLocation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signedCookieService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "outboundSbj", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Subject()
        });
        Object.defineProperty(this, "locationChangeSbj", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Subject()
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "redirectHandled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.signedCookieService = domainFactory.SignedCookieService();
        this.alertPresenter = alertPresenter;
        this.config = config;
        this.localStorage = new LocalStorage();
        this.bindEvents();
    }
    Object.defineProperty(RouteController.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (domainFactory, location) {
            this.navigate = domainFactory.navigate;
            this.curLocation = location;
            this.accountService = domainFactory.AccountService();
            this.setParamsFromLPToLocalStorage();
        }
    });
    /**
     * ページが切り替わった後の処理
     * リダイレクト判定をしてリダイレクト後にトラッキング・タグ埋めなどを行う。
     * @param location
     */
    Object.defineProperty(RouteController.prototype, "onLocationChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (location, tracker) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            window.scrollTo(0, 0);
                            this.curLocation = location;
                            this.handleSetParams(location.search);
                            return [4 /*yield*/, this.handleRedirect()
                                // 強制redirect対象だったらフラグだけ変えて何もしない
                            ];
                        case 1:
                            _a.sent();
                            // 強制redirect対象だったらフラグだけ変えて何もしない
                            if (this.redirectHandled) {
                                this.redirectHandled = false;
                                return [2 /*return*/, null];
                            }
                            tracker.trackPixelEvent(FBPixelEventName.PAGE_VIEW);
                            this.locationChangeSbj.next(location.pathname);
                            tracker.trackView();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 意図してないページ遷移した場合、リダイレクト判定を行う
     */
    Object.defineProperty(RouteController.prototype, "handleRedirect", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.matchRouteGroup(routes.NOT_HANDLE_REDIRECT_GROUP))
                        return [2 /*return*/];
                    switch (this.accountService.authStatus) {
                        case AuthStatus.Unauth:
                            // 末認証の場合
                            /**
                             * 認証必要ルートにいた場合、ログインページに飛ばす。eg) 未認証でTOPページにアクセス -> signinページに飛ばされる
                             */
                            if (this.isAtRestrictedLoaction) {
                                return [2 /*return*/, this.redirect(routes.SIGNIN)];
                            }
                            break;
                        case AuthStatus.Authed:
                            // 認証していた場合
                            if (this.isAtAuthLocation ||
                                this.matchRouteGroup(routes.FUTURE_USER_PAGE) ||
                                this.matchRouteGroup(routes.SELECT_PAGE)) {
                                this.redirect(routes.TOP);
                            }
                            break;
                        case AuthStatus.Dormant:
                            // 休眠会員状態かつ、休眠会員じゃないとアクセスできないルート以外のページにいたら、アカウントページに飛ばす
                            if (!this.matchRouteGroup(routes.DORMANT_ALLOWED_ROUTE_GROUP)) {
                                this.redirect(routes.SETTING, new URLSearchParams("key=".concat(SettingItemKeys.plan)));
                            }
                            break;
                        case AuthStatus.Future:
                            if (!this.matchRouteGroup(routes.FUTURE_USER_PAGE)) {
                                this.redirect(routes.FUTURE_USER);
                            }
                            break;
                        case AuthStatus.NoSubscription:
                            if (!this.matchRouteGroup(routes.SELECT_PAGE)) {
                                this.redirectToCCRegistration();
                            }
                    }
                    return [2 /*return*/];
                });
            });
        }
    });
    /**
     * 各種ASPタグ埋め込み、またはお友だち紹介成約の時に使うパラメータをURLから取得
     * @param searchParams
     */
    Object.defineProperty(RouteController.prototype, "handleSetParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (searchParams) {
            var _this = this;
            var params = searchParams.substring(1).split('&');
            params.map(function (param) {
                var kv = param.split('=');
                switch (kv[0]) {
                    case 'clid':
                        _this.localStorage.saveStats({ insightClid: kv[1] });
                        break;
                    case 'rtsp':
                        _this.localStorage.saveStats({ rentracksVisitId: kv[1] });
                        break;
                }
            });
        }
    });
    /**
     * LBで定義したrouteにpush
     * @param route
     * @param params
     */
    Object.defineProperty(RouteController.prototype, "push", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (route, params) {
            this.navigate(route.compile(params));
        }
    });
    /**
     * 任意の自由パスにpush
     * @param path
     */
    Object.defineProperty(RouteController.prototype, "historyPush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (path) {
            this.navigate(path);
        }
    });
    Object.defineProperty(RouteController.prototype, "redirectToCCRegistration", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // SignUpしたあとにブラウザを変えたりするとキャンペーンコード等がリダイレクト時に消えてしまうので、クレカ登録画面へのリダイレクトURLにはキャンペーンコードのパラメータを付与するようにしています。
            var urlSearchParams = new URLSearchParams();
            var campaignCode = this.getQueryParams().get('campaign_code');
            if (campaignCode) {
                urlSearchParams.append('campaign_code', campaignCode);
            }
            var invitationCode = this.getQueryParams().get('invitation_code');
            if (invitationCode) {
                urlSearchParams.append('invitation_code', invitationCode);
            }
            this.redirect(routes.CC_REGISTRATION, urlSearchParams);
        }
    });
    /**
     * urlクエリパラメータの取得
     */
    Object.defineProperty(RouteController.prototype, "getQueryParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new URLSearchParams(this.curLocation.search);
        }
    });
    Object.defineProperty(RouteController.prototype, "locationChange$", {
        get: function () {
            return this.locationChangeSbj;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RouteController.prototype, "outbound$", {
        get: function () {
            return this.outboundSbj;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RouteController.prototype, "isAtRestrictedLoaction", {
        // 要認証locationにいるか
        get: function () {
            return !this.isAtUnRestrictedLocation;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RouteController.prototype, "isAtUnRestrictedLocation", {
        // 認証不要のlocationにいるか
        get: function () {
            return this.matchRouteGroup(routes.UNRESTRICTED_ROUTE_GROUP);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RouteController.prototype, "isAtAuthLocation", {
        // 認証画面locationにいるか
        get: function () {
            return this.matchRouteGroup(routes.AUTH_ROUTE_GROUP);
        },
        enumerable: false,
        configurable: true
    });
    // authorizationでエラーの場合のみリダイレクト
    Object.defineProperty(RouteController.prototype, "authorizationWithForbiddenRedirect", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.accountService
                            .authorization()
                            .then(function (data) {
                            _this.signedCookieService.fetchSignedCookies(); // 動画を閲覧するための署名付きCookie取得
                            return data;
                        })
                            .catch(function (e) {
                            // ログイン後にFutureユーザーやDormantユーザーはForbiddenErrorになるはokなので、他を例外にする
                            if (e.isForbiddenError) {
                                var forbiddenError = e;
                                if (forbiddenError.isPaymentNotFound) {
                                    if (!_this.matchRouteGroup(routes.SELECT_PAGE)) {
                                        _this.alertPresenter.showMessage(msg.PLAN_UNREGISTERED);
                                        _this.redirectToCCRegistration();
                                    }
                                }
                                else if (forbiddenError.isPaymentExpired) {
                                    if (!_this.matchRouteGroup(routes.SELECT_PAGE)) {
                                        _this.alertPresenter.showMessage(msg.ACCOUNT_EXPIRED, alertGroup.ACCOUNT_EXPIRED);
                                        _this.redirectToCCRegistration();
                                    }
                                }
                                else if (forbiddenError.isDormant) {
                                    _this.alertPresenter.showMessage(msg.ALREADY_DORMANT, 'account_already_activated');
                                    _this.historyPush("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                                }
                                else if (forbiddenError.isFuture) {
                                    _this.push(routes.FUTURE_USER);
                                }
                            }
                            throw e;
                        })];
                });
            });
        }
    });
    /**
     * private methods
     */
    Object.defineProperty(RouteController.prototype, "redirect", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (route, params) {
            this.redirectHandled = true;
            if (params) {
                this.historyPush("".concat(route.compile(), "?").concat(params.toString()));
            }
            else {
                this.push(route);
            }
        }
    });
    Object.defineProperty(RouteController.prototype, "isAtRoute", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (route) {
            return route.matchPath(this.curLocation.pathname);
        }
    });
    Object.defineProperty(RouteController.prototype, "matchRouteGroup", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (group) {
            return group.matchPath(this.curLocation.pathname);
        }
    });
    Object.defineProperty(RouteController.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            window.onbeforeunload = function () {
                _this.outboundSbj.next();
            };
        }
    });
    Object.defineProperty(RouteController.prototype, "setParamsFromLPToLocalStorage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.isAtRoute(routes.SIGNIN) && !this.isAtRoute(routes.SIGNUP) && !this.isAtRoute(routes.CC_REGISTRATION))
                return;
            var campaignCode = this.getQueryParams().get('campaign_code');
            var tieup = this.getQueryParams().get('tieup');
            this.localStorage.saveParamsFromLP({
                campaignCode: campaignCode,
                tieup: tieup,
            });
            var invitationCode = this.getQueryParams().get('invitation_code');
            if (!invitationCode) {
                // 古い友達招待のリンクで code=xxx のパターンがあるので一応対応している(アフィリエイター等いるので...)
                // 2024/08/01以降は古い友達招待のリンクは使えなくなるので、この分岐は消してokです
                invitationCode = this.getQueryParams().get('code');
            }
            this.localStorage.saveInvitationCode(invitationCode);
            var courseInstructorCode = this.getQueryParams().get('course_instructor_code');
            this.localStorage.saveCourseInstructorCode(courseInstructorCode);
        }
    });
    return RouteController;
}());
export { RouteController };
