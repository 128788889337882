import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
export var ContractPlan = function (_a) {
    var nameClassObject = _a.nameClassObject, updateDateClassObject = _a.updateDateClassObject, name = _a.name, price = _a.price, updateDate = _a.updateDate, id = _a.id;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.name, nameClassObject), id: id }, name),
        React.createElement("div", { className: css(styles.price) }, price),
        updateDate && React.createElement("div", { className: css(styles.updateDate, updateDateClassObject) }, updateDate)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    name: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '0.5px',
    },
    price: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    updateDate: {
        color: Colors.GrayDarkest,
        fontSize: 10,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.5px',
        marginTop: 12,
    },
});
