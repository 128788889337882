var _a, _b, _c, _d, _e, _f, _g, _h;
import * as React from 'react';
import { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { LiveEventPresent } from './live_event_present';
import { LiveEventSchedules } from './live_event_schedules';
import { LiveLessonDescription } from './live_lesson_description';
import { TrainerSection } from './trainer_section';
import { Vimeo } from './vimeo';
import { LoadingIndicator, Button, InstaAndSiteLink } from '@lean-body/components/atoms';
import { LiveLessonThumbnail } from '@lean-body/components/molecules';
import { Colors, icon, MediaBreakPointDown, MediaBreakPointUp } from '@lean-body/components/styles';
import { LiveEventShareDialog } from '@lean-body/components/atoms/live_event_share_dialog';
import { LiveChat } from '@lean-body/components/molecules/liveChat';
export var Live = function (props) {
    var _a, _b, _c, _d;
    var liveLessonId = props.liveLessonId, accountService = props.accountService, apiClient = props.apiClient, tracker = props.tracker, config = props.config;
    var _e = useState(null), me = _e[0], setMe = _e[1];
    var _f = useState(false), isLoaded = _f[0], setLoaded = _f[1];
    var _g = useState(), liveLesson = _g[0], setLiveLesson = _g[1];
    var _h = React.useState(false), isAttendance = _h[0], setIsAttendance = _h[1];
    var _j = React.useState(false), showLiveEventShareDialog = _j[0], setShowLiveEventShareDialog = _j[1];
    var isXL = useXlMediaQuery();
    useEffect(function () {
        var isMounted = true;
        Promise.all([
            apiClient.fetchLiveLesson(liveLessonId),
            apiClient.fetchLiveLessonAttendanceStatus(liveLessonId),
            accountService.fetchMe(),
        ]).then(function (_a) {
            var resLiveLesson = _a[0], attendanceStatus = _a[1], resMe = _a[2];
            if (isMounted) {
                setIsAttendance(attendanceStatus.isSubscribed);
                setMe(resMe);
                setLiveLesson(resLiveLesson);
                setLoaded(true);
            }
        });
        return function () {
            isMounted = false;
        };
    }, []);
    var changeAttendStatus = function () {
        if (!isAttendance) {
            apiClient.attendLiveLesson(liveLessonId).then(function () {
                var ll = liveLesson;
                ll.attendanceCount += 1;
                setLiveLesson(ll);
                if (liveLesson.isEvent)
                    setShowLiveEventShareDialog(true);
            });
        }
        else {
            apiClient.absentLiveLesson(liveLessonId).then(function () {
                var ll = liveLesson;
                ll.attendanceCount -= 1;
                setLiveLesson(ll);
            });
        }
        setIsAttendance(!isAttendance);
    };
    // ライブ配信をサーバから取得中
    if (!isLoaded) {
        return React.createElement(LoadingIndicator, { size: 50 });
        // ライブ配信がサーバから取得できなかった場合
    }
    else if (!liveLesson) {
        return (React.createElement("div", { className: css(styles.notFoundTextWrapper) },
            React.createElement("span", { className: css(styles.notFoundText) },
                "\u73FE\u5728\u306F\u958B\u50AC\u3057\u3066\u3044\u306A\u3044",
                React.createElement("br", null),
                "\u30E9\u30A4\u30D6\u914D\u4FE1\u3067\u3059")));
    }
    else {
        if (!isXL) {
            return (React.createElement(React.Fragment, null,
                liveLesson.isWatchable ? (React.createElement(Vimeo, { liveLesson: liveLesson, finishLiveLessonWatchingRecord: apiClient.finishLiveLessonWatchingRecord, startLiveLessonWatchingRecord: apiClient.startLiveLessonWatchingRecord, syncLiveLessonWatchingRecord: apiClient.syncLiveLessonWatchingRecord, appendToSequence: apiClient.appendToSequence, tracker: tracker, me: me })) : (React.createElement(LiveLessonThumbnail, { src: liveLesson.bannerSpURL, isWatchable: liveLesson.isWatchable })),
                !liveLesson.isStarted && (React.createElement("div", { className: css(styles.attendButtonContainer) }, isAttendance ? (React.createElement(Button, { className: css(styles.buttons), onClick: changeAttendStatus }, "LIVE\u958B\u59CB\u307E\u3067\u304A\u5F85\u3061\u4E0B\u3055\u3044")) : (React.createElement(Button, { className: css(styles.attendButton), onClick: changeAttendStatus },
                    React.createElement("i", { className: iconClassName }),
                    "\u53C2\u52A0\u3057\u305F\u3044\uFF01")))),
                !liveLesson.isStarted && (React.createElement("div", { className: css(styles.participantsNumber) },
                    liveLesson.attendanceCount,
                    "\u4EBA\u304C\u53C2\u52A0\u3057\u305F\u3044\u3068\u8A00\u3063\u3066\u3044\u307E\u3059")),
                liveLesson.isHeldToday && !liveLesson.isArchiveFinished && React.createElement(LiveChat, { src: liveLesson.chatUrl }),
                React.createElement("div", { className: css(styles.layout) },
                    React.createElement(LiveLessonDescription, { liveLesson: liveLesson }),
                    liveLesson.isStarted && (React.createElement("div", { className: css(styles.questionnaireButtonContainer) },
                        React.createElement("a", { href: liveLesson.questionnaireUrl, className: css(styles.questionnaireButton), target: "_blank", rel: "noopener noreferrer" }, "\u5148\u751F\u306B\u30E9\u30A4\u30D6\u306E\u611F\u60F3\u3092\u9001\u308D\u3046"))),
                    !liveLesson.isEvent && (React.createElement(React.Fragment, null,
                        React.createElement(TrainerSection, { trainers: [liveLesson.trainer], disableBorder: true, iconClassName: css(styles.trainerIcon), className: css(styles.trainerSection) }),
                        React.createElement(InstaAndSiteLink, { containerClassName: css(styles.snsLinksContainer), linkContentClassName: css(styles.snsLinkContent), trainer: liveLesson.trainer }),
                        React.createElement(Link, { to: routes.INSTRUCTOR.compile({ id: "".concat(liveLesson.trainer.id) }), className: [css(styles.buttonWrapper), css(styles.appearanceButtonWrapper)].join(' ') }, ((_b = (_a = liveLesson.trainer) === null || _a === void 0 ? void 0 : _a.programs) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement("button", { className: css(styles.buttons) }, "\u51FA\u6F14\u30D7\u30ED\u30B0\u30E9\u30E0\u3092\u898B\u308B")))))),
                liveLesson.isEvent && React.createElement(LiveEventPresent, null),
                liveLesson.isEvent && React.createElement(LiveEventSchedules, { config: config }),
                liveLesson.isEvent && showLiveEventShareDialog && (React.createElement(LiveEventShareDialog, { close: function () { return setShowLiveEventShareDialog(false); } }))));
        }
        else {
            // pcの時
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.liveContainerForPC) },
                    React.createElement("div", { className: css(styles.leftSide) },
                        liveLesson.isWatchable ? (React.createElement(Vimeo, { liveLesson: liveLesson, startLiveLessonWatchingRecord: apiClient.startLiveLessonWatchingRecord, finishLiveLessonWatchingRecord: apiClient.finishLiveLessonWatchingRecord, syncLiveLessonWatchingRecord: apiClient.syncLiveLessonWatchingRecord, appendToSequence: apiClient.appendToSequence, tracker: tracker, me: me })) : (React.createElement(LiveLessonThumbnail, { src: liveLesson.bannerPcURL, isWatchable: liveLesson.isWatchable })),
                        React.createElement(LiveLessonDescription, { liveLesson: liveLesson }),
                        liveLesson.isEvent && React.createElement(LiveEventPresent, null),
                        liveLesson.isEvent && React.createElement(LiveEventSchedules, { config: config }),
                        liveLesson.isEvent && showLiveEventShareDialog && (React.createElement(LiveEventShareDialog, { close: function () { return setShowLiveEventShareDialog(false); } }))),
                    React.createElement("div", { className: css(styles.rightSide) },
                        liveLesson.isHeldToday && !liveLesson.isArchiveFinished && React.createElement(LiveChat, { src: liveLesson.chatUrl }),
                        !liveLesson.isEvent && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: css(styles.trainerSection) },
                                React.createElement("div", { className: css(styles.trainerIconWrapper) },
                                    React.createElement("img", { src: liveLesson.trainer.thumbnailURL, className: css(styles.trainerImage) })),
                                React.createElement("div", { className: css(styles.trainerName) }, liveLesson.trainer.name),
                                React.createElement("div", { className: css(styles.trainerDescription) }, liveLesson.trainer.bio)),
                            React.createElement(InstaAndSiteLink, { containerClassName: css(styles.snsLinksContainer), linkContentClassName: css(styles.snsLinkContent), trainer: liveLesson.trainer }),
                            React.createElement(Link, { to: routes.INSTRUCTOR.compile({ id: "".concat(liveLesson.trainer.id) }), className: [css(styles.buttonWrapper), css(styles.appearanceButtonWrapper)].join(' ') }, ((_d = (_c = liveLesson.trainer) === null || _c === void 0 ? void 0 : _c.programs) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement("button", { className: css(styles.buttons) }, "\u51FA\u6F14\u30D7\u30ED\u30B0\u30E9\u30E0\u3092\u898B\u308B"))))))),
                React.createElement("div", { className: css(styles.footer) },
                    !liveLesson.isStarted && (React.createElement("div", { className: css(styles.footerContainer) },
                        isAttendance ? (React.createElement("div", { onClick: changeAttendStatus, className: css(styles.liveWaitingButton) }, "LIVE\u958B\u59CB\u307E\u3067\u304A\u5F85\u3061\u4E0B\u3055\u3044")) : (React.createElement("div", { onClick: changeAttendStatus, className: css(styles.liveAttendButton) },
                            React.createElement("i", { className: iconClassName }),
                            "\u53C2\u52A0\u3057\u305F\u3044\uFF01")),
                        React.createElement("div", { className: css(styles.footerButtonSideSentence) },
                            liveLesson.attendanceCount,
                            "\u4EBA\u304C\u53C2\u52A0\u3057\u305F\u3044\u3068\u8A00\u3063\u3066\u3044\u307E\u3059"))),
                    liveLesson.isStarted && (React.createElement("div", { className: css(styles.footerContainer) },
                        React.createElement("a", { href: liveLesson.questionnaireUrl, className: css(styles.questionnaireButton), target: "_blank", rel: "noopener noreferrer" }, "\u5148\u751F\u306B\u30E9\u30A4\u30D6\u306E\u611F\u60F3\u3092\u9001\u308D\u3046"),
                        !liveLesson.isEvent && liveLesson.isFinished && (React.createElement("div", { className: css(styles.footerButtonSideSentence) },
                            "\u3053\u306ELIVE\u30EC\u30C3\u30B9\u30F3\u306F\u7D42\u4E86\u3057\u307E\u3057\u305F\u3002",
                            React.createElement("br", null),
                            liveLesson.watchableLimitTimeDayjs.format('M/DのHH:mm'),
                            "\u307E\u3067\u306F\u898B\u9003\u3057\u914D\u4FE1\u3092\u3054\u89A7\u3044\u305F\u3060\u3051\u307E\u3059\u3002")))))));
        }
    }
};
var LIVE_CONTAINER_MARGIN_TOP = 40;
var LIVE_FOOTER_HEIGHT = 94;
var styles = StyleSheet.create({
    liveContainerForPC: {
        marginLeft: 40,
        marginRight: 60,
        marginTop: LIVE_CONTAINER_MARGIN_TOP,
        marginBottom: 0,
        display: 'flex',
        height: "calc(100vh - ".concat(LIVE_CONTAINER_MARGIN_TOP, "px - ").concat(LIVE_FOOTER_HEIGHT, "px)"),
        overflow: 'scroll',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    leftSide: {
        width: '73%',
        marginRight: 40,
    },
    rightSide: {
        width: '27%',
    },
    footer: {
        height: LIVE_FOOTER_HEIGHT,
        width: '100%',
        background: Colors.Black,
        opacity: 0.9,
        display: 'flex',
    },
    layout: (_a = {
            postion: 'relative',
            margin: 'auto 8rem',
            marginTop: '.5rem',
            marginBottom: '4rem'
        },
        _a[MediaBreakPointDown.MD] = {
            margin: '40px 1.5rem',
        },
        _a),
    icon: {
        margin: '0 .5rem',
    },
    liveWaitingButton: (_b = {
            backgroundColor: 'white',
            padding: '13px 80px',
            borderRadius: 24,
            fontWeight: 'bold',
            color: Colors.GrayDarkest,
            border: 'solid 1px',
            cursor: 'pointer'
        },
        _b[MediaBreakPointUp.XL] = {
            marginRight: 20,
        },
        _b),
    liveAttendButton: (_c = {
            padding: '13px 80px',
            borderRadius: 24,
            fontWeight: 'bold',
            color: Colors.White,
            backgroundColor: Colors.PrimaryDark,
            cursor: 'pointer'
        },
        _c[MediaBreakPointUp.XL] = {
            marginRight: 20,
        },
        _c),
    attendButtonContainer: {
        margin: '32px 24px 6px 24px',
        textAlign: 'center',
    },
    attendButton: (_d = {
            width: '100%',
            fontWeight: 600,
            fontSize: '1rem',
            borderRadius: '30px',
            padding: '1rem .5rem',
            border: '1px solid transparent',
            color: Colors.White,
            backgroundColor: Colors.PrimaryDark
        },
        _d[MediaBreakPointUp.SM] = {
            width: '80%',
            fontSize: '1.2rem',
        },
        _d),
    participantsNumber: (_e = {
            textAlign: 'center',
            color: Colors.GrayLightText,
            fontWeight: 300
        },
        _e[MediaBreakPointDown.SM] = {
            fontSize: '.7rem',
        },
        _e),
    footerContainer: {
        margin: '23px auto',
        display: 'flex',
        alignItems: 'center',
    },
    footerButtonSideSentence: {
        color: 'white',
        fontSize: 14,
        fontWeight: 200,
        textAlign: 'center',
    },
    questionnaireButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    questionnaireButton: (_f = {
            backgroundColor: Colors.PrimaryDark,
            padding: '13px 0',
            margin: '0 auto',
            width: '100%',
            maxWidth: 327,
            textAlign: 'center',
            borderRadius: 24,
            fontWeight: 'bold',
            color: Colors.White
        },
        _f[MediaBreakPointUp.XL] = {
            marginRight: 12,
            padding: '13px 80px',
            maxWidth: 'none',
            width: 'unset',
        },
        _f),
    buttonWrapper: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttons: (_g = {
            width: '100%',
            fontWeight: 600,
            fontSize: '1rem',
            borderRadius: '30px',
            padding: '1rem .5rem',
            color: Colors.GrayLightText,
            border: "1px solid ".concat(Colors.GrayLightText),
            background: Colors.White,
            cursor: 'pointer'
        },
        _g[MediaBreakPointUp.SM] = {
            fontSize: '1.2rem',
        },
        _g),
    trainerSection: {
        marginTop: 12,
    },
    trainerIconWrapper: {
        marginBottom: 25,
        textAlign: 'center',
    },
    trainerImage: {
        width: 90,
        height: 'auto',
        borderRadius: '50%',
        border: "1px solid ".concat(Colors.Gray),
    },
    trainerName: {
        textAlign: 'center',
        marginBottom: 16,
        fontWeight: 'bold',
    },
    trainerDescription: {
        fontSize: 13,
        color: Colors.Black,
        fontWeight: 300,
    },
    trainerIcon: {
        margin: '1rem auto',
    },
    appearanceButtonWrapper: (_h = {
            marginTop: '.5rem'
        },
        _h[MediaBreakPointUp.XL] = {
            paddingBottom: 40,
        },
        _h),
    snsLinksContainer: {},
    snsLinkContent: {
        justifyContent: 'center',
        display: 'flex',
    },
    notFoundTextWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30vh',
    },
    notFoundText: {
        fontSize: '24px',
        color: Colors.GrayLightText,
        textAlign: 'center',
    },
});
var iconClassName = [css(icon.anticon), css(icon.anticonHeart), css(styles.icon)].join(' ');
